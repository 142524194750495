import {flowState} from '../../pages/CreateCampaign/CreateCampaignPage';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material'
import { CopyInput } from '../../common/CopyInput/CopyInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import  './SaveCampaign.css';

function SaveCampaign({formik}: flowState) :any {
    return (
        <Box>
            <CheckCircleIcon className='create-campaign-success-icon'/>
            <Typography className='create-campaign-success'>Campaign is successfully created</Typography>
            <Typography>you can find it as <b>a draft</b> in the <b>Campaigns</b> section.</Typography>
            <Typography className='font-weight-bold'>Next Steps</Typography>
            <Typography>You can continue and assign a promo mechanic to your campaign or review it and pick it up later</Typography>
            <Box sx={{ mt: 3 }}>
                <Button variant="contained" color="primary" className='save-campaign-btn' component={Link} to={`/assignPromotion/${formik.values.promotion_id}`}>Assign a promotion</Button>
                <Button variant="outlined" color="primary" className='view-draft-btn save-campaign-btn' component={Link} to={`/editCampaign/${formik.values.promotion_id}`}>View draft campaign</Button>
            </Box>

            <Typography className='font-weight-bold'>Alternative Option</Typography>
            <Typography>You can also copy this code and contact an Ops Engineer to finish the setup for you!</Typography>
            <Box className='copy-input-box'>
                <CopyInput inputLabel="Campaign Id" inputName="campaignID" inputValue={formik.values.promotion_id} align="center"/>
            </Box>
        </Box>
    )
}

export { SaveCampaign }
