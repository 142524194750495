export const prefixZeroIfNeeded = (value: string) => (value.startsWith('.') ? `0${value}` : value);

export const clampNumber = ({ number, max, min }: { number: number; min: number; max: number }) =>
    Math.max(min, Math.min(number, max));

const onlyNumbersAndSingleDotRegex = /[^0-9.]/g;

/**
 * Formats a decimal number string to have a fixed number of decimal places.
 * If the number has more than 2 decimal places, it is rounded to 2 decimal places.
 * If the number has 1 decimal place, it is rounded to 1 decimal place.
 * The number is clamped between the min and max values before formatting.
 */
export function formatDecimalPlaces({ numberStr, min, max }: { numberStr: string; min: number; max: number }) {
    if (+numberStr && numberStr.split('.')[1].length >= 2) {
        return clampNumber({ number: parseFloat(numberStr), max, min }).toFixed(2);
    }
    return clampNumber({ number: Number(numberStr), max, min }).toFixed(1);
}

export function getFinalFormikValue(value: string) {
    const parsedNumber = Number(value);

    // If the value is an empty string, or not a number, or a string representing
    // a non-integer number (float) with a decimal point, set the field as the original string.
    // Otherwise, it's a valid number and set it as a number in the formik field.
    const shouldSetStringValue =
        value === '' || isNaN(parsedNumber) || (!Number.isInteger(parsedNumber) && value.includes('.'));

    return shouldSetStringValue ? value : parsedNumber;
}

export function getFilteredValue(value: string) {
    const filteredValue = prefixZeroIfNeeded(value.replace(onlyNumbersAndSingleDotRegex, ''));
    return {
        filteredValue,
        isValidNumber: +filteredValue,
    };
}
