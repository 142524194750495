import { Grid, LinearProgress, Typography } from '@mui/material';
import { UploadedFilesActionButtons } from "./UploadedFilesActionButtonsComponent";

const ProgressComponent = ({
    progressFiles,
    filePath,
    usePublicDomain,
    handleDeleteFile,
    canEdit,
    setOpenEditModal
}) => {
    return (
        <Grid container={true} className='grid-container'>
            {progressFiles && progressFiles !== null && progressFiles?.map((obj: { fileName: string; percent: number }, i: number) => (
                <Grid key={i} container className='grid-box'>
                    <Grid item xs={10}>
                        <Typography variant='body2' className='file-name'>{`${obj.fileName}`}</Typography>
                        <LinearProgress variant='determinate' value={obj.percent} />
                        <Typography variant='body2' color='black'>{`${Math.round(obj.percent)}%`}</Typography>
                    </Grid>
                    {obj.percent === 100 &&
                        <UploadedFilesActionButtons
                            fileName={obj.fileName}
                            filePath={filePath}
                            usePublicDomain={usePublicDomain}
                            handleDeleteFile={handleDeleteFile}
                            canEdit={canEdit}
                            setOpenEditModal={setOpenEditModal}
                        />


                    }
                </Grid>
            ))}
        </Grid>
    )
}

export { ProgressComponent }
