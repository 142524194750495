import { Box, Grid, Typography, Link } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { CheckboxComponent } from '../inputComponents/CheckboxComponent';
import { InputComponent } from '../inputComponents/InputComponent';
import { InputNumberSpinner } from '../inputComponents/InputNumberSpinner';
import { TooltipText } from '../inputComponents/TooltipText';
import { SelectOption } from '../selectComponents/SelectOption';
import { GridItems } from './GridItems';
import { getIn } from 'formik';
import { currenciesRef } from '../../constants/helpful-link';

interface ParticipationSettingsProps {
    formik: any;
}

function ParticipationSettings({ formik }: ParticipationSettingsProps) {
    const [providedCurrencies, setProvidedCurrencies] = useState([]);

    useEffect(() => {
        const currencies = formik.values.tempParams.availableCurrencies.filter(
            (currency: any) => currency.country === formik.values.configurationParameters.country
        );
        setProvidedCurrencies(currencies);
    }, [formik.values.tempParams.availableCurrencies, formik.values.configurationParameters.country]);

    const addCurrencyId = (e: any, params: { [key: string]: any }) => {
        const ids = providedCurrencies
            .filter((currency: { name: string }) => currency.name === params.option)
            .map(({ currency_id }: any) => currency_id);
        formik.setFieldValue(`allocationRules[${params.index}]['currencyId']`, ids[0]);
    };

    const addRow = () => {
        formik.setFieldValue('allocationRules', [
            ...formik.values.allocationRules,
            { programId: '', lotId: '', currencyId: '', currencyName: '' },
        ]);
    };

    const removeRow = (params: { index: number }) => {
        formik.setFieldValue(
            'allocationRules',
            formik.values.allocationRules.filter((_: any, i: number) => i !== params.index)
        );
    };

    const addValidityValue = (e: any) => {
        const { checked, name } = e.target;
        const fieldLabel = name.replace("['validityCheck']", "['validity']");
        if (checked) {
            formik.setFieldValue(fieldLabel, 1);
        } else {
            formik.setFieldValue(fieldLabel, null);
        }
    };

    return (
        <Box className='participationSettingsBox'>
            <TooltipText
                text={'Participation Settings'}
                textVariant='h4'
                tooltip={
                    <Typography variant='body2'>
                        <Link className='tooltip-link' href={currenciesRef.ALLOCATION_RULES} target='_blank'>
                            Learn more
                        </Link>{' '}
                        about the allocation of certain currency amounts for burned codes.
                    </Typography>
                }
            />
            <Typography>
                Select currencies, specify the amount that should be allocated to participants' wallets after they burn
                pin codes or viral codes that are related to corresponding Program ID, Lot ID/Campaign ID. You can also
                set a validity time in days for each rule
            </Typography>
            <Box className='participation-settings-items'>
                <CheckboxComponent fieldName={'tempParams.addRules'} label={'Add Allocation Rules (Optional)'} />
            </Box>
            {formik.values.tempParams.addRules && (
                <Grid container direction='column' className=''>
                    {formik.values.allocationRules.map((_: string, i: number) => (
                        <Fragment key={i}>
                            <GridItems
                                formik={formik}
                                index={i}
                                component={
                                    <>
                                        <Box className='participation-settings-rules'>
                                            <SelectOption
                                                formik={formik}
                                                formikValue={`allocationRules[${i}]['currencyName']`}
                                                optionsList={formik.values.tempParams.currencyNames}
                                                customAction={addCurrencyId}
                                                customParam={{ index: i }}
                                                title={'Select Currency'}
                                                titleVariant='body2'
                                                touchedField='allocationRules'
                                            />
                                            <InputComponent
                                                formik={formik}
                                                title={'Enter Program ID'}
                                                inputValue={getIn(formik.values, `allocationRules[${i}]['programId']`)}
                                                formikLabel={`allocationRules[${i}]['programId']`}
                                                titleVariant='body2'
                                                touchedErrorPath='allocationRules'
                                            />
                                            <InputComponent
                                                formik={formik}
                                                title={'Enter Lot or Campaign ID'}
                                                inputValue={getIn(formik.values, `allocationRules[${i}]['lotId']`)}
                                                formikLabel={`allocationRules[${i}]['lotId']`}
                                                titleVariant='body2'
                                                touchedErrorPath='allocationRules'
                                            />
                                            <InputComponent
                                                formik={formik}
                                                title={'Amount'}
                                                inputValue={getIn(formik.values, `allocationRules[${i}]['amount']`)}
                                                formikLabel={`allocationRules[${i}]['amount']`}
                                                titleVariant='body2'
                                                touchedErrorPath='allocationRules'
                                            />
                                            <Box sx={{ margin: '10px' }}>
                                                <CheckboxComponent
                                                    fieldName={`allocationRules[${i}]['validityCheck']`}
                                                    label={'Allocation Rule Validity (Optional)'}
                                                    labelVariant='body2'
                                                    customAction={addValidityValue}
                                                />
                                            </Box>
                                            {formik.values.allocationRules[i]['validityCheck'] && (
                                                <InputNumberSpinner
                                                    fieldName={`allocationRules[${i}]['validity']`}
                                                    label='Select Number of Days'
                                                    incrementValue={1}
                                                    fullWidth={true}
                                                    inputClass='participation-settings-number-spinner'
                                                />
                                            )}
                                        </Box>
                                    </>
                                }
                                addAction={addRow}
                                removeAction={removeRow}
                                formikValueArray='allocationRules'
                            />
                        </Fragment>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

export { ParticipationSettings };
