import { FormHelperText, Box, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import { getIn, useFormikContext } from 'formik';
import './SelectOption.css';
import '../../App.css';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
const uniqid = require('uniqid');

interface additionalSelectionOptionsType {
    additionalSelectionLabel: string,
    additionalSelectionValues: object
}
interface SelectOptionProps {
    preSelectedValue?: string,
    selectableFields?: boolean,
    additionalSelectionOptions?: additionalSelectionOptionsType,
    switchFormikFieldName?: string,
    formik?: any,
    formikValue: string,
    optionsList: Array<string | object | undefined>,
    title?: string,
    titleVariant?: "h1" | "h2" | "h3" | "h4" | "body1" | "body2" | "caption"
    description?: string,
    descriptionVariant?: "h1" | "h2" | "h3" | "h4" | "body1" | "body2" | "caption"
    inputLabel?: string,
    customAction?: any
    selectClass?: string,
    customParam?: object,
    errorPath?: string,
    dataTestId?: string,
    touchedField?: string,
    disabled?: boolean
}


let placeholderPropObject: any = {};

function SelectOption({
    preSelectedValue,
    selectableFields = true,
    additionalSelectionOptions,
    formik,
    formikValue,
    optionsList,
    title,
    titleVariant,
    description,
    descriptionVariant,
    inputLabel,
    customAction,
    customParam,
    selectClass,
    errorPath,
    dataTestId = "select-component",
    switchFormikFieldName,
    touchedField,
    disabled = false
}: SelectOptionProps): JSX.Element {
    const formikContext = useFormikContext();
    if (!formik) formik = formikContext;

    const onChange = (primaryFormikField: string, primaryUpdateValue: any, switchFormikField?:string ) => {
        formik.setFieldValue(primaryFormikField, primaryUpdateValue);
        if (switchFormikField && (optionsList.length === 2)) {
            if (primaryUpdateValue === optionsList[0]) {
                formik.setFieldValue(switchFormikField, false);
            } else if (primaryUpdateValue === optionsList[1]) {
                formik.setFieldValue(switchFormikField, true);
            }
        }
    }

    return (
        <Box className={selectClass}>
            {title && <Typography variant={titleVariant} component='div' gutterBottom>{title}</Typography>}
            {description && <Typography variant={descriptionVariant} component='div' sx={{ marginBottom: 2 }}>{description}</Typography>}
            <Box sx={{ minWidth: 120 }}>
                <TextFieldWrapper
                    disabled={disabled}
                    className='input-text'
                    select
                    fullWidth
                    label={inputLabel}
                    value={preSelectedValue ? preSelectedValue : (getIn(formik.values, formikValue) || '')}
                    onChange={(event) => onChange(formikValue, event.target.value, switchFormikFieldName)}
                    SelectProps={{ MenuProps: { className: 'menu-props' } }}
                    inputProps={{ 'data-testid': dataTestId }}
                >
                    {(selectableFields) &&
                        <MenuItem className='menu-item-title' divider={true} disabled>
                            Select value
                        </MenuItem>}

                    {optionsList && optionsList.map((option: any, i: number) => {
                        let value = option;
                        let displayValue = option;
                        if (option === Object(option)) {
                            value = Object.values(option)[0]
                            displayValue = Object.values(option)[1];
                        }
                        return (
                            <MenuItem
                                disabled={!selectableFields}
                                key={i}
                                value={value as string}
                                onClick={((e) => customAction && customAction(e, customParam = { ...customParam, option }))}
                            >
                                {displayValue || ''}
                            </MenuItem>
                        )
                    })}

                    {additionalSelectionOptions &&
                        <MenuItem className='menu-item-title' disabled>
                            {additionalSelectionOptions.additionalSelectionLabel}
                        </MenuItem>
                    }

                    {Object.values(additionalSelectionOptions
                        ? additionalSelectionOptions.additionalSelectionValues
                        : placeholderPropObject)
                        .map((item: any, i) => (<MenuItem key={uniqid()} value={item as string}>{item}</MenuItem>)
                    )}

                </TextFieldWrapper>
                <FormHelperText data-testid={`${dataTestId}-error`} error={true}>
                    {getIn(formik.touched, touchedField || errorPath || formikValue) && getIn(formik.errors, errorPath ? errorPath : formikValue)}
                </FormHelperText>
            </Box>
        </Box>
    )
}

export { SelectOption }
