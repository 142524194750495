import { IconButton, TableBody, TableContainer, Table, TableHead, TableRow, TableCell, Typography, Paper, Link } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";
import { DialogBox } from "../../../common/Dialog/DialogBox";
import { AddToPrizeTiers } from "../../../components/modals/AddToPrizeTiers";
import TextFieldWrapper from "../../../common/TextFieldWrapper/TextFieldWrapper";
interface props {
    formik: any,
    tiersList: number[]
}

const TierLimits = ({formik, tiersList}: props) => {
    const [tiers, setTiers] = useState(tiersList.reduce((acc: any,value: number)=> {acc[value]=null; return acc},{}))
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedTier, setSelectedTier] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
            const tiers = tiersList.reduce((acc: any,value: number)=> {acc[value]=null; return acc},{});
            setTiers(tiers);
    }, [tiersList, tiersList.length]);


    const handleLimitChange = (value: any, tier:string) => {
        formik.setFieldValue('flow.instantWin.params.winningLimitsPerTier', {...formik.values.flow?.instantWin?.params?.winningLimitsPerTier, [tier]: parseInt(value)})

    }

    const handleRemoveRow = () => {
        formik.setFieldValue(
            'flow.instantWin.params.winningLimitsPerTier',
            Object.fromEntries(Object.entries(formik.values.flow.instantWin.params.winningLimitsPerTier).filter(([key,_]) => key !== selectedTier))
        )
        const newTiers = Object.fromEntries(Object.entries(tiers).filter(([key,_]) => key !== selectedTier))
        setTiers(newTiers);
        setShowConfirm(false);
        setSelectedTier('');
    }

    const handleDialogueClose = () => {
        setShowConfirm(false);
        setSelectedTier('')
    }

    return (
        <>
        <DialogBox
        dialogContent={'This will remove the winning limit for this prize. Are you sure?'}
        isOpen={showConfirm}
        dialogCloseHandler={handleDialogueClose}
        actionButtonLabels={['CANCEL', 'DELETE']}
        actionButtonHandlers={[handleDialogueClose, handleRemoveRow]}
        />
        <TableContainer component={Paper} className='vouchersContainer'>
        <Table className='vouchersTable'>
            <TableHead>
                <TableRow className='voucherHeader'>
                     <TableCell><Typography variant='body2'>Prize Tier</Typography></TableCell>
                     <TableCell><Typography variant='body2' align='center'>Limit</Typography></TableCell>
                     <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                        {Object.entries({...tiers, ...formik.values.flow.instantWin.params.winningLimitsPerTier}).map(([tier, limit], i) => {
                            return (
                                <TableRow
                                    className='voucherRow'
                                    key={i}
                                >
                                    <TableCell align="left">
                                        <Typography variant='body2'>{`Tier ${tier}`}</Typography>
                                    </TableCell>
                                    <TableCell align="center" className="limits-table-cell">
                                        <TextFieldWrapper type='number' variant='standard' size='small' value={limit || ''}
                                        onChange={(e) => handleLimitChange(e.target.value, tier)}
                                        InputProps={{classes: {input: 'number-input'}, disableUnderline: true, sx: {fontSize: '14px'}, inputProps: { min: 0, max: 90, style: {textAlign: 'center', width: '100%'} }}}
                                        >
                                        </TextFieldWrapper>
                                    </TableCell>
                                    {(parseInt(tier) > 5) ? (<TableCell>
                                        <IconButton onClick={()=> {setShowConfirm(true); setSelectedTier(tier)}}><ClearIcon/></IconButton>
                                    </TableCell>) : (<TableCell></TableCell>) }
                                </TableRow>
                        )})}
            </TableBody>
        </Table>
        <Typography variant='body1'>Need more tiers? <Link onClick={()=> setOpen(true)} className="configureTiers">Configure tiers</Link></Typography>
        <AddToPrizeTiers modalOpenState={open} listToInsertTo={tiersList} handleClose={setOpen} />
        </TableContainer>
        </>
    )
}

export { TierLimits }
