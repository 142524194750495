import { ChangeEvent, useState } from 'react';
import { Box, FormHelperText, StyledEngineProvider } from '@mui/material';
import { FormikContextType, getIn } from 'formik';

import { TooltipText } from '../../inputComponents/TooltipText';
import { CheckboxComponent } from '../../inputComponents/CheckboxComponent';
import { InputNumberSpinner } from '../../inputComponents/InputNumberSpinner';
import { ICreatePromotionState } from '../../../types/componentTypes/assignPromo';

import './AdditionalPromoDetails.css';

interface InterruptedFlowProps {
    formik: FormikContextType<ICreatePromotionState>;
}

function InterruptedFlow({ formik }: InterruptedFlowProps) {
    const handleInterruptedFlow = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'true' || event.target.checked === true) {
            formik.setFieldValue('additionalDetails', {
                ...formik.values.additionalDetails,
                rejectWinningMoment: {
                    flowLambdas: ['rejectWinningMomentLambda'],
                },
                acceptReservedVoucher: {
                    flowLambdas: ['acceptReservedVoucherLambda'],
                    params: {
                        maxParticipationIds: 10,
                    },
                },
                rejectReservedVoucher: {
                    flowLambdas: ['rejectReservedVoucherLambda'],
                },
            });
        } else {
            let formikState = { ...formik.values.additionalDetails };
            delete formikState.rejectWinningMoment;
            delete formikState.acceptReservedVoucher;
            delete formikState.rejectReservedVoucher;
            formik.setFieldValue('additionalDetails', formikState);
            formik.setFieldValue('maxParticipationIds', null);
        }
    };

    const defaultValue = getIn(formik.values, 'useStatusReserved') || false;
    const [showInputField, setShowInputField] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setShowInputField(checked);
        formik.setFieldValue('useStatusReserved', checked);
    };

    return (
        <StyledEngineProvider injectFirst>
            <Box className='interruptedFlowBox'>
                <CheckboxComponent
                    customBoxClass='useStatusReservedBox'
                    fieldName='useStatusReserved'
                    label={
                        <TooltipText
                            styleName='details-checkbox-tooltip'
                            text='Interrupted Flow'
                            textVariant='body1'
                            tooltip={
                                'Once enabled, Reserved status will be used for vouchers that are distributed within this promotion'
                            }
                        />
                    }
                    customOnChangeHandler={handleChange}
                    customAction={handleInterruptedFlow}
                />

                {showInputField && (
                    <>
                        <TooltipText
                            text='Maximum vouchers to accept'
                            textVariant='body1'
                            styleName='details-checkbox-tooltip'
                            iconSize='medium'
                            tooltip='Multiple vouchers can be accepted per request.'
                        />
                        <InputNumberSpinner
                            fieldName='maxParticipationIds'
                            incrementValue={1}
                            minValue={1}
                            maxValue={100}
                        />
                        <FormHelperText error={formik.errors ? true : false}>
                            {getIn(formik.errors, 'maxParticipationIds')}
                        </FormHelperText>
                    </>
                )}
            </Box>
        </StyledEngineProvider>
    );
}

export { InterruptedFlow };
