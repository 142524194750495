import { unset as _delete, set as _add, get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { ConfigType, PrizeDrawConfig } from '../../../../types/configuration';
import { AdditionalDetailsState, AllowedValueTypes } from '../types/AdditionalDetailsTypes';

/**
 * The `ComponentCache` class is an abstract class that provides a set of methods for managing caches related to component configuration, form state, and values.
 *
 * The class maintains three main caches:
 * - `valueCache`: A cache for storing and retrieving arbitrary values.
 * - `configCache`: A cache for storing and retrieving component configuration data, including both the initial and updated configurations.
 * - `formStateCache`: A cache for storing and retrieving the initial form state.
 *
 * The class provides methods for setting and retrieving values from these caches, as well as for managing the configuration cache, including updating and discarding changes.
 *
 * This class is designed to be extended by concrete component classes that need to manage caching of configuration, form state, and other values.
 */
export abstract class ComponentCache {
    private valueCache: Map<string, AllowedValueTypes>;
    private configCache: Map<'updated-config' | 'init-config', ConfigType | PrizeDrawConfig>;
    private formStateCache: Map<'init-form-state', AdditionalDetailsState>;

    /**
     * Initializes the caches used to store values, configurations, and form state.
     */
    constructor() {
        this.valueCache = new Map();
        this.configCache = new Map();
        this.formStateCache = new Map();
    }

    /**
     * Retrieves the value from the value cache for the given key.
     * @param key - The key to look up in the value cache.
     * @returns The value associated with the given key, or undefined if the key is not found.
     */
    protected getValue(key: string): AllowedValueTypes {
        return this.valueCache.get(key);
    }

    /**
     * Sets the value in the cache for the given key.
     *
     * @param key - The key to associate the value with in the cache.
     * @param value - The value to store in the cache.
     */
    protected setValue(key: string, value: AllowedValueTypes) {
        this.valueCache.set(key, value);
    }

    /**
     * Returns the initial form state from the form state cache.
     * @returns {AdditionalDetailsState} The initial form state.
     */
    protected getInitFormState(): AdditionalDetailsState {
        return this.formStateCache.get('init-form-state');
    }

    /**
     * Sets the initial form state in the form state cache.
     *
     * @param formState - The initial form state to be cached.
     * @returns The form state cache with the initial form state set.
     */
    protected setInitFormState(formState: AdditionalDetailsState) {
        this.formStateCache.clear();

        return this.formStateCache.set('init-form-state', formState);
    }

    /**
     * Retrieves the initial configuration value for the specified key from the configuration cache.
     *
     * @param key - The key to retrieve the initial configuration value for.
     * @returns The initial configuration value for the specified key, or `undefined` if the key is not found in the cache.
     */
    protected getInitConfigValue(key: string): AllowedValueTypes {
        return this.configCache.get('init-config')?.[key];
    }

    /**
     * Sets the value of a specific key in the updated configuration object and updates the 'updated-config' state.
     *
     * @param key - The key to update in the configuration object.
     * @param value - The new value to set for the specified key.
     */
    protected setUpdatedConfigValue<T>(key: string, value: T) {
        const configCopy = this.copyUpdatedConfig();

        _add(configCopy, key, value);
        this.setConfig('updated-config', configCopy);
    }

    /**
     * Removes a specific configuration value from the updated configuration object.
     *
     * @param key - The key of the configuration value to remove. Can include nested properties using dot notation (e.g. 'params.foo').
     * @param parentKeyValue - The parent key value to use when determining the key to delete. Defaults to 'params'.
     * @returns void
     */

    protected removeUpdatedConfigValue(key: string, parentKeyValue: string) {
        const configCopy = this.copyUpdatedConfig();

        if (!configCopy) {
            console.error('Config copy is null or undefined');
            return;
        }

        const keyArr = key.split('.');
        const parentKeyIndex = keyArr.indexOf(parentKeyValue);

        if (parentKeyIndex === -1) {
            return;
        }

        const parentKey = keyArr.slice(0, parentKeyIndex + 1).join('.');

        const parentObject = _get(configCopy, parentKey);

        if (typeof parentObject !== 'object' || parentObject === null) {
            console.error('Parent key does not resolve to a valid object:', parentKey);
            return;
        }

        const keyToDelete =
            key.includes('params') && Object.keys(parentObject).length === 1 ? parentKey : key;

        if (keyToDelete in configCopy) {
            _delete(configCopy, keyToDelete);
            console.log('Deleted key:', keyToDelete);
        } else {
            console.warn('Key to delete does not exist in configCopy:', keyToDelete);
        }

        _delete(configCopy, 'formState');
        this.setConfig('updated-config', configCopy);
    }

    /**
     * Sets a configuration value in the component cache.
     *
     * @param key - The key to use for the configuration value. Can be either 'init-config' or 'updated-config'.
     * @param value - The configuration value to set. Can be either a `ConfigType` or a `PrizeDrawConfig`.
     */
    protected setConfig(key: CachedConfigKeys, value: ConfigType | PrizeDrawConfig) {
        this.configCache.set(key, value);
    }

    /**
     * Retrieves the configuration for the specified key from the configuration cache.
     * @param key - The key for the configuration to retrieve, either 'init-config' or 'updated-config'.
     * @returns The configuration for the specified key, or undefined if the key is not found in the cache.
     */
    protected getCachedConfig(key: CachedConfigKeys) {
        return this.configCache.get(key);
    }

    /**
     * Copies the updated configuration from the configuration cache.
     * @returns {ConfigType | PrizeDrawConfig} The copied updated configuration.
     */
    protected copyUpdatedConfig(): ConfigType | PrizeDrawConfig {
        return _cloneDeep(this.configCache.get('updated-config'));
    }

    /**
     * Sets up a cache for the provided configuration object.
     * @param config - The configuration object to cache.
     */
    protected setupConfigCache(config: ConfigType | PrizeDrawConfig) {
        this.clearCache();

        this.setConfig('init-config', config);
        this.setConfig('updated-config', config);
    }

    /**
     * Clears the value and config caches.
     */
    clearCache() {
        this.valueCache.clear();
        this.configCache.clear();
    }

    /**
     * Updates the configuration cache with the latest configuration.
     */
    updateConfigCache() {
        const updatedConfig = this.getCachedConfig('updated-config');
        this.setupConfigCache(updatedConfig);
    }

    /**
     * Discards any changes made to the component configuration by clearing the value cache and resetting the configuration to the initial state.
     */
    discardChanges() {
        this.valueCache.clear();
        this.setConfig('updated-config', this.getCachedConfig('init-config'));
    }
}

export type CachedConfigKeys = 'init-config' | 'updated-config';
