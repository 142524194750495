import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import { Box, Container, Grid, Link, Pagination, Skeleton, StyledEngineProvider, Typography } from '@mui/material';

import PageHeaderComponent from '../../common/PageHeader/PageHeaderComponent';
import { Notification } from '../../common/Notification/Notification';
import CurrencyModal from '../../components/modals/CurrencyModal';
import CurrencyDataGridComponent from '../../components/currencyComponents/CurrencyDataGridComponent';
import GenericSpinner from '../../common/Spinners/GenericSpinner';

import { CurrencyTableItem, PagesData } from '../../types/componentTypes/CurrencyTypes';
import useFetchCurrencies from './hooks/useGetCurrenciesList';
import useMakePaginatedCurrencies from './hooks/useMakePaginatedCurrencies';

import { getActiveLinkedPromotionsForCurrency, getTransactionsForCurrencyId } from './services/api-calls';
import {
    getErrorMessageFromError,
    isCurrencyOlderThanSixHours,
    removeFalsyValuesFromCurrency,
} from './helpers/currency-helpers';
import {
    defaultNotifState,
    defaultModalState,
    CurrencyPaths,
    TextElements,
    getErrorMessageForCurrencyModifications,
} from '../../constants/currency-constants';

import './CurrenciesStyles.css';

const ListCurrenciesPage = () => {
    const navigate = useNavigate();

    // #region - state slices
    const [notifState, setNotifState] = useState(defaultNotifState);
    const [modalState, setModalState] = useState(defaultModalState);
    const [currentPage, setCurrentPage] = useState('1');
    const [searchBarValue, setSearchBarValue] = useState('');
    const [selectedMarket, setSelectedMarket] = useState('');
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [currencies, setCurrencies] = useState<CurrencyTableItem[]>([]);
    const [pageData, setPageData] = useState<PagesData>({});
    const [pageCount, setPageCount] = useState<number>(0);
    // #endregion

    // #region - custom hooks
    const { fetchingCurrencies, setDataRefresh } = useFetchCurrencies({ setNotifState, setCurrencies });
    const { isPaginationInProgress } = useMakePaginatedCurrencies({
        currencies,
        searchBarValue,
        selectedMarket,
        setNotifState,
        setPageData,
        setPageCount,
        setCurrentPage,
    });
    // #endregion

    // #region - handlers
    const checkCurrencyForTransactionsAndActivePromotions = async (
        currency: CurrencyTableItem,
        action: 'edit' | 'delete'
    ) => {
        const currencyInfo = {
            canModifyCurrency: true,
            containsTransactions: false,
        };

        try {
            const [transactions, activePromos] = await Promise.allSettled([
                getTransactionsForCurrencyId(currency.currency_id),
                getActiveLinkedPromotionsForCurrency(currency),
            ]);

            if (transactions.status === 'rejected' && activePromos.status === 'rejected') {
                setNotifState(
                    getErrorMessageForCurrencyModifications(TextElements.GenericUnableToModifyCurrMessage, action)
                );
                currencyInfo.canModifyCurrency = false;
                return;
            }

            if (transactions.status === 'fulfilled' && transactions.value.length && action === 'edit') {
                setNotifState(
                    getErrorMessageForCurrencyModifications(TextElements.GenericTransactionsFoundMessage, action)
                );
                currencyInfo.canModifyCurrency = false;
                return;
            }

            if (transactions.status === 'fulfilled' && transactions.value.length && action === 'delete') {
                currencyInfo.containsTransactions = true;
                return;
            }

            if (activePromos.status === 'fulfilled' && activePromos.value.length) {
                setNotifState(
                    getErrorMessageForCurrencyModifications(
                        `Currency is linked to active promotion(s): 
                            ${activePromos.value}
                        .`,
                        action
                    )
                );
                currencyInfo.canModifyCurrency = false;
                return;
            }
        } catch (error) {
            console.error(error);
            setNotifState(getErrorMessageForCurrencyModifications(getErrorMessageFromError(error), action));
        } finally {
            return currencyInfo;
        }
    };

    const currencyHasValidDateProps = (currency: CurrencyTableItem, action: 'delete') => {
        if (!currency.is_active) {
            setNotifState(getErrorMessageForCurrencyModifications(TextElements.LegacyCurrModificationMessage, action));
            return false;
        }

        if (!currency.entry_date) {
            setNotifState(getErrorMessageForCurrencyModifications(TextElements.GenericCurrMissingDateMessage, action));
            return false;
        }

        const currencyTime = currency.entry_date?.split('T')[1];
        if (!currencyTime) {
            setNotifState(getErrorMessageForCurrencyModifications(TextElements.GenericCurrMissingTimeMessage, action));
            return false;
        }

        return true;
    };

    const handleDeleteItem = async (currency: CurrencyTableItem) => {
        const action = 'delete';
        try {
            setShowLoadingSpinner(true);

            if (!currencyHasValidDateProps(currency, action)) return;

            const { canModifyCurrency, containsTransactions } = await checkCurrencyForTransactionsAndActivePromotions(
                currency,
                action
            );
            if (!canModifyCurrency) return;

            const sanitisedCurrency = {
                ...removeFalsyValuesFromCurrency(currency),
                is_active: false,
            };

            if (containsTransactions) {
                return setModalState({
                    open: true,
                    description: TextElements.TransactionsFoundWarning,
                    currencyForRemoval: sanitisedCurrency,
                });
            }

            if (isCurrencyOlderThanSixHours(currency.entry_date)) {
                return setModalState({
                    open: true,
                    description: TextElements.MoreThanSixHoursWarning,
                    currencyForRemoval: sanitisedCurrency,
                });
            }

            return setModalState({
                open: true,
                description: TextElements.DeleteCurrencyConfMessage,
                currencyForRemoval: sanitisedCurrency,
            });
        } catch (error) {
            console.error(error);
            setNotifState(getErrorMessageForCurrencyModifications(getErrorMessageFromError(error), action));
        } finally {
            setShowLoadingSpinner(false);
        }
    };

    const handleEditItem = async (currency: CurrencyTableItem) => {
        const action = 'edit';
        try {
            setShowLoadingSpinner(true);

            if (!currency.is_active)
                return setNotifState(
                    getErrorMessageForCurrencyModifications(TextElements.LegacyCurrModificationMessage, action)
                );

            const { canModifyCurrency } = await checkCurrencyForTransactionsAndActivePromotions(currency, action);
            if (!canModifyCurrency) return;

            navigate(`/editCurrency/${currency.currency_id}`, {
                state: { currency },
            });
        } catch (error) {
            console.error(error);
            setNotifState(getErrorMessageForCurrencyModifications(getErrorMessageFromError(error), action));
        } finally {
            setShowLoadingSpinner(false);
        }
    };

    const handleSearchBarInput = (value: string) => {
        setSearchBarValue(value.toLowerCase());
    };

    const handleDropDownChange = (value: string) => setSelectedMarket(() => value);

    const handlePageChange = (...[, page]: [event: unknown, page: number]) => setCurrentPage(page.toString());

    // #endregion

    return (
        <StyledEngineProvider injectFirst>
            <Grid container direction='column'>
                <Notification notificationState={notifState} setNotificationState={setNotifState} />

                <CurrencyModal
                    props={{
                        content: modalState.description,
                        showModal: modalState.open,
                        currencyForRemoval: modalState.currencyForRemoval,
                        setModalState,
                        triggerDataRefresh: setDataRefresh,
                    }}
                />

                <PageHeaderComponent
                    props={{
                        breadCrumbText: 'Currencies',
                        breadCrumbPath: CurrencyPaths.ListCurrencies,
                        labelText: 'Currencies',
                        createButtonText: 'create new currency',
                        createButtonPath: CurrencyPaths.CreateCurrency,
                        searchFieldLabel: 'Search by name or ID',
                        searchBarValue,
                        handleSearchBarInput,
                    }}
                />

                <GenericSpinner showSpinner={showLoadingSpinner} />

                {!fetchingCurrencies && !isPaginationInProgress ? (
                    <Container maxWidth='lg' background-color='white' className='containerStyles'>
                        <CurrencyDataGridComponent
                            props={{
                                selectedMarket,
                                currenciesToRender: pageData[currentPage],
                                handleDropDownChange,
                                handleEditItem,
                                handleDeleteItem,
                                setNotifState,
                            }}
                        />
                        <Grid item xs={4}>
                            <Pagination
                                className='paginationBarStyles'
                                count={pageCount}
                                showFirstButton={true}
                                showLastButton={true}
                                siblingCount={0}
                                boundaryCount={1}
                                onChange={handlePageChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Box className='curenciesHelpfulLinks'>
                                <Typography variant='h5'>Helpful Links</Typography>
                                <Link
                                    target='_blank'
                                    href='https://wiki.coke.com/confluence/display/infomix/Currency+Allocation+Rules'
                                    rel='noopener noreferrer'>
                                    Currencies
                                </Link>
                            </Box>
                        </Grid>
                    </Container>
                ) : (
                    <Grid container alignContent={'center'} justifyContent={'center'} spacing={6}>
                        <Grid item>
                            <Skeleton animation='wave' variant='rectangular' width={1200} height={780} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </StyledEngineProvider>
    );
};

export default ListCurrenciesPage;
