import * as yup from 'yup';
import moment from 'moment';

interface TestContextExtended {
    [key: string]: any;
}

function addPrizeValidationSchema(defaultLanguage: string) {
    return yup.object({
        tempParams: yup.object().shape({
            end_date: yup.date().required()
            .test('endDateTest', 'Selected date cannot be after the promotion end date.',
            (value, testContext) => {
                const {from} = testContext as yup.TestContext & TestContextExtended
                let result = true;
                if (from[0].value.prizeStatus === 'custom' && moment(from[1].value.end_date).isBefore(moment(value), 'minute')) {
                    result=false
                }
                return result
            }),
            img_url: yup.object().shape({
                [defaultLanguage]: yup.array().min(1, 'At least one image is required for the default language'),
            }).test(
                'images-required',
                'At least one image is required for each language if not using default images',
                (img_url, testContext) => {
                    const {parent} = testContext as yup.TestContext & TestContextExtended
                    if(!parent.useDefaultImg) {
                        return Object.keys(img_url).every((key) => {
                            return img_url[key].length > 0;
                        });
                    }
                    return true
                }
            ),
        }),
        name: yup.mixed()
            .test('prizeNameTest', 'Name is required for selected languages',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isEmpty = Object.values(element).some(v => v === '' || v === undefined)
                    if (isEmpty) {
                        result = false
                    }
                })
                return result
            })
            .test('prizeNameLength', 'The maximum length is 90 characters',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isLonger = Object.values(element).some((v:any)=> v && v.length > 90);
                    if (isLonger) {
                        result = false
                    }
                })
                return result
            }),
        img_url: yup.array().min(1, 'At least one image should be uploaded'),
        desc: yup.mixed().test('prizeDescTest', 'Description is required for selected languages',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isEmpty = Object.values(element).some(v => v === '' || v === undefined)
                    if (isEmpty) {
                        result = false
                    }
                })
                return result
            })
            .test('prizeDescLength', 'The maximum length is 1000 characters',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isLonger = Object.values(element).some((v:any)=> v && v.length > 1000);
                    if (isLonger) {
                        result = false
                    }
                })
                return result
            }),
        short_desc: yup.mixed().test('prizeShortDescTest', 'Short description is required for selected languages',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isEmpty = Object.values(element).some(v => v === '' || v === undefined)
                    if (isEmpty) {
                        result = false
                    }
                })
                return result
            })
            .test('prizeShortDescTest', 'The maximum length is 250 characters',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isLonger = Object.values(element).some((v:any)=> v && v.length > 250);
                    if (isLonger) {
                        result = false
                    }
                })
                return result
            }),
        redeem_desc: yup.mixed().test('prizeRedeemDescTest', 'Redemption Instructions are required for selected languages',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isEmpty = Object.values(element).some(v => v === '' || v === undefined)
                    if (isEmpty) {
                        result = false
                    }
                })
                return result
            })
            .test('prizeRedeemDescTest', 'The maximum length is 1000 characters',
            (value) => {
                let result = true;
                value && value.forEach((element: any) => {
                    const isLonger = Object.values(element).some((v:any)=> v && v.length > 1000);
                    if (isLonger) {
                        result = false
                    }
                })
                return result
            }),
        redemption_link: yup.string()
            .notRequired()
            .test('redemptioLinkTest', 'Please enter valid redemption link',
                (value) => {
                    if (value) {
                        const emailRegex = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
                        const urlRegex = /^(mailto:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/[a-zA-Z0-9_-]+[a-zA-Z0-9_])*)$/gm;                        
                        let isValidEmail = emailRegex.test(value);
                        let isValidUrl = urlRegex.test(value);
                        if (!isValidEmail && !isValidUrl) {
                            return false;
                        }
                    }
                    return true;
                }),
        redemption_limit: yup.mixed()
            .test('redemptionLimit', 'Winning limit is required when option is selected',
            (value, testContext) => {
                const {parent} = testContext as yup.TestContext & TestContextExtended
                let result = true;
                if (parent.tempParams.winningLimit === true && (value === '' || value === undefined)) {
                    result=false
                }
                return result
            }),
        total_amount: yup.mixed()
            .test('totalAmountTest', 'Total Quantity is required and must be a positive number',
            (value, testContext) => {
                const {parent} = testContext as yup.TestContext & TestContextExtended
                let result = true;
                if (parent.voucher_dist === 'false' && (value === '' || value === undefined || parseInt(value) <= 0 || isNaN(parseInt(value)))) {
                    result=false
                }
                return result
            }),
        total_available: yup.mixed()
            .test('totalAvailableTest', 'Available Quantity is required positive number and cannot be more than total quantity',
            (value, testContext) => {
                const {parent} = testContext as yup.TestContext & TestContextExtended
                let result = true;
                if (parent.voucher_dist === 'false' && (value === '' || value === undefined || parseInt(value) <= 0 || isNaN(parseInt(value)))) {
                    result=false
                } else if (parent.total_amount < value) {
                    result=false
                }
                return result
            }),
        cost: yup.mixed()
        .test('costTest', 'At least one currency has to be selected',
        (value, testContext) => {
            const {parent} = testContext as yup.TestContext & TestContextExtended;

            if(parent.tempParams.mechanic === 'c&g' && value && value[0] && !value[0].name) {
                return false;
            }

            return true;
        })
        .test('costAmountTest', 'Amount can contain only positive integers',
        (value, testContext) => {
            let result = true;
            const {parent} = testContext as yup.TestContext & TestContextExtended
            if (parent.tempParams.mechanic === 'c&g') {
                value && value.forEach((element: { amount: string, currency_id: string, name: string }) => {
                    if (Number(element.amount) < 0 || !Number.isInteger(Number(element.amount))) {
                        result = false;
                        return;
                    }
                });
            }

            return result
        })
    })
}

export { addPrizeValidationSchema }
