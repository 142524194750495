import { memo, useEffect, useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { InputComponent } from '../../../components/inputComponents/InputComponent';
import { SelectOption } from '../../../components/selectComponents/SelectOption';
import { AllocateDates } from '../../../components/selectComponents/AllocateDates';
import { TooltipText } from '../../../components/inputComponents/TooltipText';
import { SwitchButton } from '../../../components/inputComponents/SwitchComponent';
import { languageCodes } from '../../../constants/lists';
import { FileUpload } from '../../../components/inputComponents/FileUpload/FileUpload.component';
import { getIn, useFormikContext } from 'formik';
import { editPromotionFormikState, PromoMechanic, PromoVariation } from '../../../types/componentTypes/editPromotion';
import { SelectLanguage } from '../../../components/assignPromotionFlowComponent/LanguageSelector';
import { EditPromotionGeneralInfoFormProps } from '../../../types/componentTypes/editPromotion';
import TextFieldWrapper from '../../../common/TextFieldWrapper/TextFieldWrapper';
import { defaultNotifState } from '../../../constants/currency-constants';
import { Notification } from '../../../common/Notification/Notification';

import './EditPromotionGeneralInfoForm.css';

const EditPromotionGeneralInfoForm = memo(
    ({
        campaignData,
        promotionVariation,
        promotionMechanic,
        emailTemplates,
        updateOnCountry,
        handleTabChange,
        updatePromoSubMechanic,
        hasPrizes
    }: EditPromotionGeneralInfoFormProps) => {
        const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();
        const formik = useFormikContext<editPromotionFormikState>();
        const [country, setCountry] = useState<string | undefined>();
        const [notificationState, setNotificationState] = useState(defaultNotifState);

        const toggleOptions = {
            default: [
                'Collect And Get + Auto-Redeem',
                'Collect And Get',
                'Instant Win (Winning Moments)',
                'Instant Win (Winning Moments + Guaranteed Prize)',
                'Instant Win (Always Win)',
            ],
            collectAndGet: { standard: 'Collect & Get', auto: 'Collect & Get + Auto-Redeem' },
            instantWin: [
                'Instant Win (Winning Moments)',
                'Instant Win (Winning Moments + Guaranteed Prize)',
                'Instant Win (Always Win)',
            ],
        };

        const tooltipsText = {
            market: `If you need to select another market, go to the campaign profile and open "General Info"
            where the markets can be managed in case you have corresponding permissions.`,
            language: `Only one language can be selected as a default per promotion.
            The list of languages is updated based on the selected market, but you can also open the full list of languages if needed.`,
            email: `Select the email template if you need to send a voucher and redemption instructions to winners.`,
            ajoEmail: `Provide the name of the template created in AJO and if user id origin is CDS`
        };

        useEffect(() => {
            const langSelected = languageCodes[formik.values.configurationParameters.language];
            setSelectedLanguage(langSelected);
        }, [formik.values.configurationParameters.language]);

        useEffect(() => {
            if (
                !campaignData.promotion_market.includes(formik.values.configurationParameters.country) &&
                formik.values.configurationParameters.country !== ''
            ) {
                setCountry(formik.values.configurationParameters.country);
            }
        }, [campaignData.promotion_market, formik.values.configurationParameters.country]);

        useEffect(() => {
            handleTabChange(formik.dirty && formik.submitCount === 0);
        }, [formik.dirty, formik.submitCount, handleTabChange]);

        return (
            <Box className='generalInfoForm'>
                <Typography variant='h3'>General Info</Typography>

                <Notification notificationState={notificationState} setNotificationState={setNotificationState} />

                <Grid container columnSpacing={4}>
                    <Grid item xs={5} className='disabled'>
                        <Typography variant='body1'>Linked Campaign</Typography>
                        <InputComponent
                            formik={formik}
                            inputValue={getIn(formik.values, 'promotionId')}
                            formikLabel={'promotionId'}
                            title=''
                            titleVariant='caption'
                            label=''
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5} className='disabled'>
                        <Typography variant='body1'>Promotion Mechanic</Typography>
                        <TextFieldWrapper
                            className='input-text'
                            fullWidth
                            disabled={true}
                            id='promo-mechanic-outlined-basic'
                            value={promotionMechanic}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={5} className='disabled'>
                        <Grid container>
                            <Grid container direction='row' justifyContent='space-between' sx={{ height: '23px' }}>
                                <Grid item>
                                    <Typography variant='body1'>Variation</Typography>
                                </Grid>

                                {(promotionMechanic === PromoMechanic.CollectAndGet || promotionMechanic === PromoMechanic.AutoRedeemCnG) && (
                                    <Grid item>
                                        <SwitchButton
                                            formik={formik}
                                            formikValue={'tempParams.autoRedeemPrizeLambda'}
                                            label={'Enable Auto-Redeem'}
                                            disableToggle={promotionMechanic === PromoMechanic.CollectAndGet ? true : false}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            {(promotionMechanic === PromoMechanic.CollectAndGet || promotionMechanic === PromoMechanic.AutoRedeemCnG) && (
                                <Grid item xs={12}>
                                    <TextFieldWrapper
                                        className='input-text'
                                        fullWidth
                                        disabled={true}
                                        id='auto-redeem-outlined-basic'
                                        value={
                                            formik.values.tempParams.autoRedeemPrizeLambda
                                                ? toggleOptions.collectAndGet.auto
                                                : toggleOptions.collectAndGet.standard
                                        }
                                        variant='outlined'
                                    />
                                </Grid>
                            )}

                            {promotionMechanic === 'Instant Win' && promotionVariation !== 'Instant Win' && (
                                <Grid item xs={12}>
                                    <TextFieldWrapper
                                        className='input-text'
                                        fullWidth
                                        disabled={true}
                                        id='promo-variation-outlined-basic'
                                        value={promotionVariation}
                                        variant='outlined'
                                    />
                                </Grid>
                            )}

                            {promotionMechanic === 'Prize Draw (Lottery)' && (
                                <Grid item xs={12}>
                                    <TextFieldWrapper
                                        className='input-text'
                                        fullWidth
                                        disabled={true}
                                        id='promo-variation-outlined-basic'
                                        value={promotionVariation}
                                        variant='outlined'
                                    />
                                </Grid>
                            )}

                            {promotionMechanic === 'Instant Win' && promotionVariation === 'Instant Win' && (
                                <Grid item xs={12}>
                                    <SelectOption
                                        formik={formik}
                                        formikValue={'configurationParameters.configurationSubMechanic'}
                                        optionsList={[PromoVariation.InstantWinAW, PromoVariation.InstantWinWMGP]}
                                        inputLabel='Select Promotion Variation'
                                        dataTestId='selectInstantWinVariation'
                                        customAction={updatePromoSubMechanic}
                                        customParam={{ formik: formik }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <TooltipText
                            text='Market'
                            textVariant='body1'
                            tooltip={tooltipsText.market}
                            iconSize='medium'
                            styleName='tooltip'
                        />
                        <SelectOption
                            formik={formik}
                            formikValue={'configurationParameters.country'}
                            optionsList={Array.from(new Set([country, ...campaignData.promotion_market]))}
                            inputLabel=''
                            dataTestId='selectMarket'
                            customAction={updateOnCountry}
                            customParam={{ formik: formik }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TooltipText
                            text='Default Language'
                            textVariant='body1'
                            tooltip={tooltipsText.language}
                            iconSize='medium'
                            styleName='tooltip'
                        />
                        <SelectLanguage
                            formik={formik}
                            countryValue={formik.values.configurationParameters.country}
                            formikLanguageField={'configurationParameters.language'}
                            selectedLanguage={selectedLanguage}
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <Typography variant='body1'>Promotion Name</Typography>
                        <InputComponent
                            formik={formik}
                            inputValue={getIn(formik.values, 'configurationParameters.additionalInformation.name')}
                            formikLabel={'configurationParameters.additionalInformation.name'}
                            title=''
                            titleVariant='caption'
                            label=''
                            dataTestId='promotionName'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TooltipText
                            text={'Promotion Image (Optional)'}
                            textVariant='body1'
                            tooltip='Allowed image file formats: PNG, JPG, SVG'
                            iconSize='medium'
                            styleName='tooltip'
                        />
                        <FileUpload
                            cachedFiles={
                                getIn(formik.values, 'tempParams.imgUrl')
                                    ? [{ name: getIn(formik.values, 'tempParams.imgUrl') }]
                                    : []
                            }
                            placeholder={'No file chosen'}
                            bucket='public'
                            filePath={`configurationImages/${getIn(formik.values, 'configurationId')}`}
                            uploadCallback={(files: Array<String>) => {
                                formik.setFieldValue(
                                    'configurationParameters.additionalInformation.imgUrl',
                                    files.length ? files[0] : ''
                                );
                                formik.setFieldValue('tempParams.imgUrl', '');
                            }}
                            deleteCallback={() => {
                                formik.setFieldValue('tempParams.imgUrl', '');
                                formik.setFieldValue('configurationParameters.additionalInformation.imgUrl', '');
                            }}
                            setNotificationState={setNotificationState}
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <Typography variant='body1'>Short Description (Optional)</Typography>
                        <InputComponent
                            blockEnterKey={false}
                            formik={formik}
                            inputValue={getIn(formik.values, 'configurationParameters.additionalInformation.shortDescription')}
                            formikLabel={'configurationParameters.additionalInformation.shortDescription'}
                            title=''
                            titleVariant='caption'
                            label=''
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant='body1'>Long Description (Optional)</Typography>
                        <InputComponent
                            blockEnterKey={false}
                            formik={formik}
                            inputValue={getIn(formik.values, 'configurationParameters.additionalInformation.description')}
                            formikLabel={'configurationParameters.additionalInformation.description'}
                            title=''
                            titleVariant='caption'
                            label=''
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={10}>
                        <AllocateDates
                            datetime={true}
                            formikStartDate={`configurationParameters.configurationStartUtc`}
                            formikEndDate={`configurationParameters.configurationEndUtc`}
                            isEdit={true}
                            formikTimezoneValue={`configurationParameters.configurationDatesTimezone`}
                            useTitles={true}
                            containerSpacing={4}
                            startEndDateColumns={3}
                            timeZoneColumns={6}
                            disabled={hasPrizes}
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <TooltipText
                            text='Email Template (Optional)'
                            textVariant='body1'
                            tooltip={tooltipsText.email}
                            iconSize='medium'
                            styleName='tooltip'
                        />
                        <SelectOption
                            formik={formik}
                            formikValue={'configurationParameters.emailTemplateId'}
                            optionsList={Array.from(
                                new Set([...emailTemplates, formik.values.configurationParameters.emailTemplateId])
                            )}
                            inputLabel=''
                            dataTestId='emailTemplates'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TooltipText
                            text="AJO Template Name (Optional)"
                            textVariant='body1'
                            tooltip={tooltipsText.ajoEmail}
                            iconSize="medium"
                            styleName="tooltip"
                        />
                        <InputComponent
                            formik={formik}
                            inputValue={getIn(formik.values, 'configurationParameters.ajoEmailTemplate')}
                            formikLabel={'configurationParameters.ajoEmailTemplate'}
                            title=''
                            titleVariant='caption'
                            label=''
                            dataTestId='ajoTemplate'
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={10} textAlign='right'>
                        <Button variant='contained' color='primary' type='submit' className='submitBtn'>
                            SAVE
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
);

export { EditPromotionGeneralInfoForm };
