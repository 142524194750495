import { Modal, Typography, Box } from '@mui/material'
import { useState } from 'react';
import { Notification } from '../../common/Notification/Notification';
import { FileUpload } from '../inputComponents/FileUpload/FileUpload.component';
import { defaultNotifState } from '../../constants/currency-constants';
import './Modal.css';
import '../inputComponents/FileUpload/FileUpload.css';

interface UploadFileProps {
    modalOpenState: boolean,
    handleClose(modalState:boolean):void,
    configId: string
}

function UploadWinningMomentsModal({modalOpenState, handleClose, configId}: UploadFileProps) {
    const [notificationState, setNotificationState] = useState(defaultNotifState);

    return (
        <Box>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState}/>
            <Modal
            open={modalOpenState}
            onClose={() => { handleClose(false); }}
            data-testid="winningMomentsModal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box className='modal_main'>
                    <Box className='body'>
                        <Typography id="modal-modal-title" variant="h6">
                            Upload Winning Moments
                        </Typography>
                    </Box>
                    <FileUpload
                        placeholder='CSV Files with moments'
                        filePath={`${configId}/winningMomentCSVs`}
                        maxFiles={5}
                        uploadCallback={(files: any) => {
                            setNotificationState({
                                open: true,
                                title: `Moments were added successfully`,
                                content:`Moments file${files.length > 1 ? 's' : ''} uploaded: ${files.join(', ')}`,
                                level: "success"
                            });
                        }}
                        deleteCallback={(file: string) => {
                            setNotificationState({
                                open: true,
                                title: `Moments file was removed successfully`,
                                content: `Removed moments file: ${file}`,
                                level: "success"
                            });
                        }}
                        setNotificationState={setNotificationState}
                    />
                    <Typography variant='body1' style={{fontStyle:'italic'}}>You can select multiple files</Typography>
                </Box>
            </Modal>
      </Box>
    )
}

export { UploadWinningMomentsModal }
