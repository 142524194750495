// custom
import GenericSpinner from '../../../common/Spinners/GenericSpinner';
import FormHeader from './components/FormHeaderComponent';
import HelpfulLinks from './components/HelpfulLinksComponent';

// hooks
import { useAdditionalDetailsFormBuilder } from './hooks/useAdditionalDetailsViewBuilder';

// types
import { AdditionalDetailsFormProps } from './types/AdditionalDetailsTypes';

function AdditionalDetailsForm(props: AdditionalDetailsFormProps) {
    const AdditionalDetailsFormView = useAdditionalDetailsFormBuilder(props);

    return (
        <>
            {/* Loading Comp */}
            <GenericSpinner showSpinner={props.formIsSaving} />

            {/* Form */}
            <FormHeader />
            {AdditionalDetailsFormView}

            {/* Links */}
            <HelpfulLinks mechanic={props.mechanic} />
        </>
    );
}

export { AdditionalDetailsForm };
