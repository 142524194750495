import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { ConfigType } from '../configuration';
import { notificationType } from '../notifications';
import { prizeDataType } from '../../pages/EditPromotion/EditPromotionPage';

export interface PrizeRatioType {
    prizeId: string;
    ratio: number;
}

export interface participationLimitsProps {
    partLimitVer?: number,
    participationLimit: number;
    participationLimitTime: number;
    participationLimitTimeZone: string;
    participationLimitCalendarDatesRange: {
        startDate: number;
        endDate: number;
    };
    participationLimitMultipleChecks: boolean;
    participationLifetimeLimit: number;
    participationLimitStartEndDatesRange: {
        startDate: number;
        endDate: number;
    };
    participationLimitCalendarDatesLimit: number;
    participationLimitCalendarDatesTimeZone: string;
    minAge: number;
}

export interface limits {
    minAge: boolean;
    calendarDay: boolean;
    rolling: boolean;
    lifetime: boolean;
    useRollingCustomDuration: boolean;
}

export interface limitsStateValues extends ConfigType {
    tempParams: {
        limits: limits,
        alwaysWinLimits: {
            prizeOptions: Array<string>;
        };
        winningLimitsPerTierFlag?: boolean;
        instantWinLimitPerTimeInterval?: number;
        instantWinLimitPerTimeIntervalValue?: number;
        instantWinLimitPerConfiguration?: number;
        startDateTime?: number;
        endDateTime?: number;
    };
    alwaysWinLimits: {
        alwaysWinPrizeRatios: Array<PrizeRatioType>;
        ratioWinning: boolean;
    };
    participationLimits: participationLimitsProps;
    selectedDateLimitOption: string;
}

export const initialValues: limitsStateValues = {
    tempParams: {
        limits: {
            minAge: false,
            calendarDay: false,
            rolling: false,
            lifetime: false,
            useRollingCustomDuration: false,
        },
        alwaysWinLimits: {
            prizeOptions: [],
        },
    },
    promotionId: '',
    configurationId: '',
    flow: {
        flowName: {
            params: {
                partLimitVer: 1,
                winningLimitsPerPrize: {},
                winningLimitsPerTier: { '1': 2, '2': 3, '3': 5 },
                participationLimit: 1,
                participationLimitTime: 1,
                participationLimitTimeZone: '',
                participationLimitCalendarDatesRange: {
                    startDate: parseInt(moment().format('x')),
                    endDate: parseInt(moment().add(1, 'days').format('x')),
                },
                participationLimitMultipleChecks: false,
                participationLifetimeLimit: 1,
                participationLimitStartEndDatesRange: {
                    startDate: parseInt(moment().format('x')),
                    endDate: parseInt(moment().add(1, 'days').format('x')),
                },
                participationLimitCalendarDatesLimit: 1,
                participationLimitCalendarDatesTimeZone: '',
                minAge: 12,
            },
            checkerLambdas: [],
        },
    },
    participationLimits: {
        partLimitVer: 1,
        participationLimit: 1,
        participationLimitTime: 1,
        participationLimitTimeZone: '',
        participationLimitCalendarDatesRange: {
            startDate: parseInt(moment().format('x')),
            endDate: parseInt(moment().add(1, 'days').format('x')),
        },
        participationLimitMultipleChecks: false,
        participationLifetimeLimit: 1,
        participationLimitStartEndDatesRange: {
            startDate: parseInt(moment().format('x')),
            endDate: parseInt(moment().add(1, 'days').format('x')),
        },
        participationLimitCalendarDatesLimit: 1,
        participationLimitCalendarDatesTimeZone: '',
        minAge: 12,
    },
    alwaysWinLimits: {
        alwaysWinPrizeRatios: [{ prizeId: "", ratio: 0 }],
        ratioWinning: false,
    },
    selectedDateLimitOption: '',
};
export interface limitFlowProps {
    flowName: string
}

export interface LimitsProps {
    promotionMechanic?: string,
    config: ConfigType;
    setConfig: Dispatch<SetStateAction<ConfigType>>;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
    handleTabChange: Dispatch<SetStateAction<boolean>>;
    prizesData: Array<prizeDataType>;
    setPrizeData: (value: Array<prizeDataType>) => void;
}

export const PARTICIPATION_LIMIT_LAMBDA = 'participationLimit';
export const AGE_LIMIT_LAMBDA = 'age';
export const IW_PRIZE_LIMITS_LAMBDA = 'instantWinPrizeLimits';

export enum IW_LIMIT_PARAMS {
    'instantWinLimitPerTimeInterval',
    'instantWinLimitPerTimeIntervalValue',
    'instantWinLimitPerConfiguration',
}

export enum PARTICIPATION_LIMIT_PARAMS {
    calendarDay = 'calendarDay',
    rolling = 'rolling',
    lifetime = 'lifetime',
    useRollingCustomDuration = 'useRollingCustomDuration',
    minAge = 'minAge',
}
