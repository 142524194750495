import { Typography } from '@mui/material';

function FormHeader() {
    return (
        <Typography sx={{ paddingTop: '1rem', paddingBottom: '1rem' }} variant='h3'>
            Additional Details
        </Typography>
    );
}

export default FormHeader;
