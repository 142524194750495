import { Storage, API, graphqlOperation } from 'aws-amplify';
import { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { Box, Typography, Link } from '@mui/material'
import { getEmailTemplates } from '../../../graphql/queries';
import { Form, Formik } from 'formik';
import { editPromotionValidationSchema } from './editPromotionValidationSchema';
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import { timezones } from '../../../constants/timezones';
import { languageCodes } from '../../../constants/lists';
import { EditPromotionGeneralInfoForm } from './EditPromotionGeneralInfoForm';
import { getPrizesForConfig } from '../../../graphql/queries';
import { updatePrizeCatalogueTable } from '../../../graphql/mutations'
import { saveConfig } from '../../../utils/s3FileUtils';
import { ConfigFlow, ConfigurationSubMechanics, EditPromotionGeneralInfoProps, Flows, generalInfoInitialState, PromoMechanic } from '../../../types/componentTypes/editPromotion';
import { commonLinks } from '../../../constants/helpful-link';
import { helpfulLinks } from './constants/generalInfo-constants';

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: ''
    }
});

const placeholderLocalizationText: string = 'This is placeholder text added due to the promotion language being updated'

const initialState = generalInfoInitialState

const EditPromotionGeneralInfo = memo(({
    campaignData, config, promotionMechanic, promotionVariation,
    setNotificationState, setLoading, handleTabChange, hasPrizes
}: EditPromotionGeneralInfoProps) => {
    let urlParams = useParams();

    const [emailTemplates, setEmailTemplates] = useState<Array<object | undefined>>([]);
    const [formikState, setFormikState] = useState(initialState);

    const invertedTimeZones = useMemo(() =>
        Object.entries(timezones)
            .reduce<Record<string, string>>((acc, [key, value]) => ({ ...acc, [value]: key }), {})
        , [])

    const retrieveEmails = useCallback(async (country: string) => {
        if (!country) return;
        const queryResult: any = await API.graphql({ query: getEmailTemplates, variables: { promotionCountry: country } });
        const response = queryResult.data.getEmailTemplates;
        const emailTemplates = response.items;
        return emailTemplates;
    }, [])

    const transformUTCtoTimezone = useCallback((configDate: number, timezoneString: string) => {
        let date;
        if (configDate) {
            const utcDate = moment.utc(configDate).format();
            const timezone = invertedTimeZones[timezoneString] || moment.tz.guess() || 'Europe/London';
            date = moment.tz(utcDate, timezone).format();
        } else {
            date = moment().format('YYYY-MM-DD HH:mm')
        }

        return date
    }, [invertedTimeZones])

    useEffect(() => {
        if (config.promotionId === "") return;

        const updatedState = {
            ...config,
            promotionId: config.promotionId,
            configurationId: config.configurationId || '',
            configurationParameters: {
                ...config?.configurationParameters,
                country: config.configurationParameters?.country || '',
                language: config.configurationParameters?.language || '',
                configurationStartUtc: config.configurationParameters?.configurationStartUtc
                    ? transformUTCtoTimezone(config.configurationParameters?.configurationStartUtc, config.configurationParameters?.configurationDatesTimezone)
                    : moment(initialState.configurationParameters.configurationStartUtc).format('YYYY-MM-DD HH:mm'),
                configurationEndUtc: config.configurationParameters?.configurationDatesTimezone
                    ? transformUTCtoTimezone(config.configurationParameters?.configurationEndUtc, config.configurationParameters?.configurationDatesTimezone)
                    : moment(initialState.configurationParameters.configurationEndUtc).format('YYYY-MM-DD HH:mm'),
                configurationDatesTimezone: config.configurationParameters?.configurationDatesTimezone ? timezones[config.configurationParameters?.configurationDatesTimezone] : timezones[moment.tz.guess()],
                configurationSubMechanic: config.configurationParameters?.configurationSubMechanic || '',
                emailTemplateId: config.configurationParameters?.emailTemplateId || '',
                ajoEmailTemplate: config.configurationParameters?.ajoEmailTemplate || '',
                additionalInformation: {
                    ...config.configurationParameters?.additionalInformation,
                    name: config.configurationParameters?.additionalInformation?.name || '',
                    description: config.configurationParameters?.additionalInformation?.description || '',
                    shortDescription: config.configurationParameters?.additionalInformation?.shortDescription || '',
                    imgUrl: config.configurationParameters?.additionalInformation?.imgUrl || '',
                },

            },
            tempParams: {
                imgUrl: config.configurationParameters?.additionalInformation?.imgUrl || '',
                autoRedeemPrizeLambda: promotionVariation === ConfigurationSubMechanics.AutoCollectAndGet ? true : false,
                promoMechanic: promotionMechanic || ''
            },
            flow: config.flow || {}
        }

        if (config.configurationParameters?.country) {
            retrieveEmails(config.configurationParameters?.country)
                .then(data => {
                    data && setEmailTemplates(data)
                }).catch(e => {
                    console.log('Error while retrieving email templates', e)
                })
        }
        setFormikState(updatedState)
    }, [config, promotionMechanic, promotionVariation, retrieveEmails, transformUTCtoTimezone])

    const updateOnCountry = (event: any, params: any) => {
        const currentCountry = params.option;
        const { formik } = params;
        if (config.configurationParameters?.country) {
            if (config.configurationParameters?.country !== currentCountry) {
                formik.setFieldValue('configurationParameters.country', currentCountry);
                formik.setFieldValue('configurationParameters.emailTemplateId', '');
                formik.setFieldValue('configurationParameters.language', '');
            }
            retrieveEmails(currentCountry)
                .then(templates => setEmailTemplates(templates))
        }
    }

    const convertToUtc = (configurationParameters: {
        configurationDatesTimezone: string, configurationStartUtc: string, configurationEndUtc: string
    }) => {
        const { configurationDatesTimezone, configurationStartUtc, configurationEndUtc } = configurationParameters;
        const timeZone = invertedTimeZones[configurationDatesTimezone] || 'Europe/London';
        const startTimeString = moment(configurationStartUtc).format("YYYY-MM-DD HH:mm");
        const endTimeString = moment(configurationEndUtc).format("YYYY-MM-DD HH:mm");

        return {
            configurationStartUtc: moment.tz(startTimeString, timeZone).utc().valueOf(),
            configurationEndUtc: moment.tz(endTimeString, timeZone).utc().valueOf(),
            configurationDatesTimezone: timeZone
        }
    }

    const getConfigSubMechanic = (params: { isCollectAndGetPromo: boolean, isAutoCollectAndGetPromo: boolean, configurationSubMechanic?: string }) => {
        if (!params.configurationSubMechanic) {
            return '';
        }

        if (!params.isCollectAndGetPromo) {
            return params.configurationSubMechanic;
        }

        return params.isAutoCollectAndGetPromo ? ConfigurationSubMechanics.AutoCollectAndGet : ConfigurationSubMechanics.CollectAndGet;
    }

    const getFlow = (params: { isCollectAndGetPromo: boolean, isAutoCollectAndGetPromo: boolean, flow: ConfigFlow }) => {
        if (!params.isCollectAndGetPromo) {
            return params.flow
        }

        if (params.isAutoCollectAndGetPromo) {
            return { ...Flows.autoRedeemCnG, ...params.flow }
        }

        return { ...Flows.standardCG, ...params.flow }
    }

    const formatConfigurationData = (formValues: Record<string, string>) => {
        let submitData = JSON.parse(JSON.stringify(formValues, (_, value) => {
            return (value === "" || value.length === 0 ? undefined : value);
        }));

        const isCollectAndGetPromo = submitData.tempParams.promoMechanic.includes(PromoMechanic.CollectAndGet) as boolean
        const isAutoCollectAndGetPromo = submitData.tempParams.autoRedeemPrizeLambda as boolean;
        let languageKeys: string[] = Object.keys(languageCodes);
        if (!(languageKeys.includes(submitData.configurationParameters.language))) {
            const language = submitData.configurationParameters.language;
            const code = languageKeys.find(key => languageCodes[key] === language);
            submitData.configurationParameters.language = code
        }
        submitData = {
            ...config,
            configurationParameters: {
                ...submitData.configurationParameters,
                ...(convertToUtc(submitData.configurationParameters)),
                additionalInformation: {
                    ...submitData.configurationParameters.additionalInformation,
                },
                configurationSubMechanic: getConfigSubMechanic({
                    isCollectAndGetPromo,
                    isAutoCollectAndGetPromo,
                    configurationSubMechanic: submitData.configurationParameters.configurationSubMechanic
                })
            },
            flow: getFlow({ isCollectAndGetPromo, isAutoCollectAndGetPromo, flow: submitData.flow })
        }
        delete submitData.tempParams

        return submitData
    }

    const updatePrizesOnLanguageChange = async (formikValues) => {
        const getPrizes = async () => {
            const queryResult: any = await API.graphql({ query: getPrizesForConfig, variables: { configuration_id: config.configurationId } });
            return queryResult?.data?.getPrizesForConfig?.items;
        };
        const updatePrizes = async (inputValues) => {
            await API.graphql(graphqlOperation(updatePrizeCatalogueTable, { input: inputValues }))
        };
        try {
            getPrizes().then((prizes) => {
                let prizePropertiesToUpdate: string[] = ["name", "desc", "short_desc", "redeem_desc"];
                let prizesToUpdate = prizes.map((prize) => {
                    let languageKey: string = Object.keys(languageCodes).find(key => languageCodes[key] === formikValues.configurationParameters.language);
                    if (!(Object.keys(JSON.parse(prize.name)).includes(languageKey))) {
                        prizePropertiesToUpdate.forEach((propName) => {
                            prize[propName] = JSON.stringify(Object.assign(JSON.parse(prize[propName]), { [languageKey]: placeholderLocalizationText }));
                        });
                    }
                    return prize
                })
                prizesToUpdate.forEach((updatedPrizeObject) => {
                    updatePrizes(updatedPrizeObject);
                });
            });
        } catch (e) {
            setNotificationState({
                open: true,
                title: 'Prizes not updated',
                content: 'Prize update failed!',
                level: "error"
            })
            setLoading(false);
            console.error("Saving prizes to dynamoDB failed with: ", e);
        }

    };


    const handleSubmit = async (formValues: any) => {
        setLoading(true);
        const configuration = formatConfigurationData(formValues)
        let languageChanged: boolean = (formValues.configurationParameters?.language === config.configurationParameters.language) ? false : true;
        if (languageChanged) {
            await updatePrizesOnLanguageChange(formValues);
        }
        await saveConfig({ urlParams, submitData: configuration, setNotificationState });
        setLoading(false);
        setNotificationState({
            open: true,
            title: languageChanged ? 'Promotion and prizes updated successfully.' : 'Promotion updated successfully',
            content: languageChanged ? 'Promotion and additional prize localizaitons were saved!' : 'Promotion Changes were saved!',
            level: "success"
        })
    }

    return (
        <>
            <Formik
                initialValues={formikState}
                enableReinitialize
                validationSchema={editPromotionValidationSchema}
                onSubmit={async (values) => {
                    try {
                        await handleSubmit(values);
                    } catch (e) {
                        console.error(e);
                    }
                }}
            >
                <Form >
                    {campaignData && promotionVariation && <EditPromotionGeneralInfoForm
                        campaignData={campaignData}
                        promotionMechanic={promotionMechanic}
                        promotionVariation={promotionVariation}
                        emailTemplates={emailTemplates}
                        updateOnCountry={updateOnCountry}
                        handleTabChange={handleTabChange}
                        hasPrizes={hasPrizes}
                    />
                    }
                </Form>
            </Formik>
            <Typography variant="h3">
                Helpful Links
            </Typography>
            <Box className="helpfulLinks">
                <Link rel="noopener noreferrer" href={commonLinks.TERMS_AND_ACRONYMS} color="inherit" target="_blank">
                    NGPS Terms and Acronyms
                </Link>
                {helpfulLinks[promotionMechanic].map((helpfulLink, i) => (
                    <Link key={i} target='_blank' href={helpfulLink.link} rel='noopener noreferrer'>
                        {helpfulLink.text}
                    </Link>
                ))}
            </Box>
        </>
    )

})

export { EditPromotionGeneralInfo };
