import { useState, useEffect } from 'react';
import { Card, CardContent, Button, Typography, Divider, Box } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles';
import { StatusBox } from '../../common/StatusBox/StatusBox';
import { Link as RouterLink } from 'react-router-dom'
import './CampaignBox.css'
export interface boxData {
    boxData: Array<any>,
    boxIndex: number
}

export default function CampaignBox({ boxData, boxIndex }: boxData) {
    const [data, setData] = useState(boxData[boxIndex])
    const alignData = (inputData: any) => {
        if (!inputData || !inputData.configurations) {
            inputData.configurations = []
        }
        return inputData;
    }
    useEffect(() => {
        setData(alignData(boxData[boxIndex]))
    }, [boxData, boxIndex]);

    return (
        <StyledEngineProvider injectFirst>
            <Card className="campaignCard">
                <CardContent className="campaignCardContent">
                    <Box className="promotionNameWrapper">
                        <Typography variant="h6" className="promotionNameLabelStyles">
                            {data.promotion_name ? data.promotion_name : "Name not specified"}
                        </Typography>
                        <Button variant="contained" className="manageButtonStyles" size="small" component={RouterLink} to={`/editCampaign/${data.promotion_id}`}>Manage</Button>
                    </Box>
                    <Typography variant="body2" className="promotionIdLabelStyles">
                        {"ID: " + data.promotion_id}
                    </Typography>
                    <Typography variant="body2" className="digitalExperienceStyles">
                        {data.digital_experience && data.digital_experience.length > 1 ? data.digital_experience.join(' | ') : data.digital_experience}
                    </Typography>
                    <Typography variant="body2" className="startEndDateLabelStyles" >
                        {new Date(data.promotion_start_utc).toLocaleDateString("de-CH") + " - " + new Date(data.promotion_end_utc).toLocaleDateString("de-CH")}
                    </Typography>
                </CardContent>
                <Divider variant="middle" />
                <div className="cardFooter">
                    <StatusBox styleName="campaignStatus" archived={data.archived} numOfConfigs={data.configurations ? data.configurations.length : 0} />
                    <Box>
                        <Typography className="livePromotionsCountLabelStyles">
                            {(data.configurations && data.configurations.length === 1) ? data.configurations.length + ' promotion' : (data.configurations && data.configurations.length > 1) ? + data.configurations.length + ' promotions' : '0 promotions'}
                        </Typography>
                    </Box>
                </div>
            </Card>
        </StyledEngineProvider>
    )
};

export { CampaignBox };
