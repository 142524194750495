import { Grid, Typography, Box, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { ParticipationLimits } from '../../../components/assignPromotionFlowComponent/ParticipationLimits';
import { AlwaysWinLimits } from '../../../components/assignPromotionFlowComponent/AlwaysWinLimits';
import { WinningLimits } from './WinningLimits';
import { usePrompt } from '../../../common/PromptOnChanges/promptHelper';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { limitsStateValues } from '../../../types/componentTypes/limits';
import { prizeDataType } from '../EditPromotionPage';

let tiersList = [1, 2, 3, 4, 5];

interface LimitsFormProps {
    handleTabChange: Dispatch<SetStateAction<boolean>>;
    prizesData: Array<prizeDataType>;
}

const LimitsForm = ({ handleTabChange, prizesData }: LimitsFormProps) => {
    const [localChanges, setLocalChangesDetected] = useState(false);
    const formik = useFormikContext<limitsStateValues>();

    useEffect(() => {
        setLocalChangesDetected(formik.dirty && formik.submitCount === 0);
        handleTabChange(formik.dirty && formik.submitCount === 0);
    }, [formik.dirty, formik.submitCount, handleTabChange]);

    usePrompt('This will discard all the changes. Are you sure?', localChanges);

    return (
        <>
            <Grid container columnSpacing={6} className='limits-form-container'>
                <Grid item xs={5}>
                    <Box>
                        <Typography variant='h5' className='limits-form-items'>
                            Participation Limits
                        </Typography>
                        <ParticipationLimits
                            formik={formik}
                            useTitles={false}
                            limitsBoxClass={'limits-participation-box'}
                            startEndDateColumns={4.5}
                            timeZoneColumns={4.5}
                            containerSpacing={2}
                            formClass='form-control-participation-limits'></ParticipationLimits>
                    </Box>
                    {formik.values.flow?.instantWin?.params?.alwaysWin &&
                        <Box>
                            <Typography variant='h5' className='limits-form-items alwaysWin'>
                                Always Win Limits
                            </Typography>
                            <AlwaysWinLimits
                                formik={formik}
                                useTitles={false}
                                limitsBoxClass={'limits-alwaysWin-box'}
                                startEndDateColumns={4.5}
                                timeZoneColumns={4.5}
                                containerSpacing={2}
                                prizesData={prizesData}
                                formClass='form-control-alwaysWin-limits' 
                            />
                        </Box>
                    }
                </Grid>
                {!!formik.values.flow?.instantWin &&
                    <Grid item xs={5}>
                        <Typography variant='h5' className='limits-form-items'>
                            Winning Limits
                        </Typography>
                        <WinningLimits formik={formik} tiersList={tiersList} />
                    </Grid>
                }
            </Grid>
            <Box className='changesButtons'>
                <Button
                    variant='outlined'
                    onClick={() => {
                        formik.resetForm({ values: formik.initialValues });
                        tiersList = [1, 2, 3, 4, 5];
                    }}>
                    Discard
                </Button>
                <Button variant='contained' color='primary' type='submit'>
                    Save
                </Button>
            </Box>
        </>
    );
};

export { LimitsForm };
