import { Grid, FormControl, Typography, Autocomplete, Tooltip, Icon } from '@mui/material';
import { useState } from 'react';

import { promotionMarketList } from '../../constants/lists';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorComponent from '../../components/errorComponent/ErrorComponent';
import { AllowedTextAlignments } from '../../constants/currency-constants';
import { CurrencyFormProps } from '../../types/componentTypes/CurrencyTypes';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

const CurrencyForm = ({ props }: CurrencyFormProps) => {
    const {
        inputNameLimit,
        inputName,
        defaultDropdownItem,
        setPageHasBeenModified,
        setInputName,
        setSelectedDropdownItem,
        setPageHasError,
        groupedCurrencyNames,
        dropdownItem,
        pageHasBeenModified,
        disableFields,
    } = props;

    const [inputError, setInputError] = useState(false);
    const [inputHelperText, setInputHelperText] = useState('');
    const [dropDownError, setDropDownError] = useState(false);
    const [dropdownHelperText, setDropdownHelperText] = useState('');

    const checkIfCurrencyExistsForMarket = () => {
        if (!dropdownItem) return false;
        if (!inputName) return false;
        if (!pageHasBeenModified) return false;
        if (!groupedCurrencyNames[dropdownItem]) return false;
        if (groupedCurrencyNames[dropdownItem].includes(inputName.toLowerCase())) return true;
    };

    const validateInput = () => {
        if (!inputName) {
            setInputError(true);
            setInputHelperText('Currency Name Required');
            setPageHasError(true);
            return;
        }

        if (inputName && inputName.length > inputNameLimit) {
            setInputError(true);
            setInputHelperText('Maximum of 90 Characters');
            setPageHasError(true);
            return;
        }

        if (checkIfCurrencyExistsForMarket()) {
            setInputError(true);
            setInputHelperText('Currency Name Exists For Market');
            setPageHasError(true);
            return;
        }

        setPageHasError(inputError && dropDownError);
    };

    const validateDropDownSelection = () => {
        if (!dropdownItem) {
            setDropDownError(true);
            setDropdownHelperText('Market Required');
            setPageHasError(true);
            return;
        }

        if (checkIfCurrencyExistsForMarket()) {
            setInputError(true);
            setInputHelperText('Currency Name Exists For Market');
            setPageHasError(true);
            return;
        }

        setPageHasError(inputError && dropDownError);
    };

    const handleOnInputChange = (value: string) => {
        setInputError(false);
        setInputHelperText('');
        setPageHasBeenModified(value !== inputName);
        setInputName(value);
    };

    const handleOnDropDownChange = (value: string | null) => {
        setDropDownError(false);
        setDropdownHelperText('');
        setPageHasBeenModified(value !== dropdownItem);
        setSelectedDropdownItem(value);
    };

    return (
        <Grid container spacing={2} direction='column' sx={{ marginTop: '1vh' }}>
            <Grid item>
                <FormControl sx={{ m: 1, width: 400 }}>
                    <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                        <Grid item>
                            <Typography align={AllowedTextAlignments.Left}>Currency Name</Typography>
                        </Grid>

                        <Grid item>
                            <Tooltip
                                placement={AllowedTextAlignments.Right}
                                sx={{ marginTop: '-2px' }}
                                title='Currency name is unique for each market'>
                                <Icon className='icon-style' fontSize='large'>
                                    <HelpOutlineIcon />
                                </Icon>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <TextFieldWrapper
                        name={'currency name'}
                        disabled={disableFields}
                        sx={{ background: 'white' }}
                        required={true}
                        fullWidth
                        multiline
                        id='currency-input-name'
                        variant='outlined'
                        value={inputName}
                        onBlur={validateInput}
                        onChange={(e) => handleOnInputChange(e.target.value)}
                        placeholder={'Up to 90 Characters'}
                        error={inputError}
                    />
                    <ErrorComponent inputText={inputHelperText} alignment={AllowedTextAlignments.Left} />
                </FormControl>
            </Grid>

            <Grid item>
                <FormControl sx={{ m: 1, width: 400 }}>
                    <Typography align={AllowedTextAlignments.Left}>Market</Typography>
                    <Autocomplete
                        disabled={disableFields}
                        sx={{ background: 'white' }}
                        fullWidth
                        data-testid='dropdown-menu-with-search'
                        options={promotionMarketList}
                        value={defaultDropdownItem || null}
                        onChange={(_, value) => {
                            handleOnDropDownChange(value);
                        }}
                        onBlur={validateDropDownSelection}
                        renderInput={(params) => (
                            <TextFieldWrapper {...params} error={dropDownError} placeholder='Select A Market' />
                        )}
                    />
                    <ErrorComponent inputText={dropdownHelperText} alignment={AllowedTextAlignments.Left} />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default CurrencyForm;
