import { Box, Button } from '@mui/material';
import { useFormikContext, getIn } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CurrencyDetails } from '../../../components/assignPromotionFlowComponent/CurrencyDetails/CurrencyDetails.component';
import { EditCurrenciesState } from '../../../types/componentTypes/editPromotion';
import { FormikContextType } from 'formik';

interface props {
    handleTabChange: Dispatch<SetStateAction<boolean>>
}

const formikFieldNames = ['tempParams.validityDays', 'tempParams.currencyValidity', 'tempParams.totalCurrencyAccumulated', 'additionalInformation.totalCurrencyAccumulated', 'configurationParameters.currencies', 'tempParams.useCurrency']

const CurrenciesForm = ({ handleTabChange }: props) => {

    const formik: FormikContextType<EditCurrenciesState> = useFormikContext();
    const [changesApplied, setChangesApplied] = useState(false);


    useEffect(() => {
        const shouldDiscard = formikFieldNames.reduce((accumulator: boolean[], currField) => {
            const value1 = JSON.stringify(getIn(formik.values, currField))
            const value2 = JSON.stringify(getIn(formik.initialValues, currField))
            accumulator.push(value1 === value2)
            return accumulator;
        }, []).includes(false)

        setChangesApplied(shouldDiscard)
        handleTabChange(formik.dirty && formik.submitCount === 0);
    }, [formik.dirty, formik.submitCount, handleTabChange, formik.values, formik.initialValues])


    return (
        <>
            <CurrencyDetails formik={formik} isEdit={true} mainBoxClass={'currencies-main-box'} contentBoxClass={'currencies-details'} />
            <Box className='changesButtons'>
                {changesApplied && <Button variant='outlined' onClick={() => formik.resetForm({ values: formik.initialValues })}>Discard</Button>}
                <Button variant="contained" color="primary" type="submit">Save</Button>
            </Box>
        </>
    )
}

export { CurrenciesForm }
