import React, { useMemo } from 'react';
import { Grid, Autocomplete, Typography, Box, Card, CardContent, IconButton, Divider } from '@mui/material';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { promotionMarketList } from '../../constants/lists';
import { CurrencyDataGridComponentProps } from '../../types/componentTypes/CurrencyTypes';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

const CurrencyDataGridComponent = ({ props }: CurrencyDataGridComponentProps) => {
    const {
        selectedMarket,
        currenciesToRender,
        handleDropDownChange,
        handleEditItem,
        handleDeleteItem,
        setNotifState,
    } = props;

    const mappedCurrencies = useMemo(() => {
        if (!currenciesToRender) return [];

        const copyToClipboard = (value: string) => {
            navigator.clipboard.writeText(value);
            setNotifState({
                open: true,
                title: 'Currency Id Copied!',
                content: '',
                level: 'success',
            });
        };

        return currenciesToRender.map((currency, cardIndex) => (
            <Grid key={`key-${cardIndex}`} item xs={6} data-testid={`currency-card-${cardIndex}`}>
                <Card className='campaignCard' sx={{ height: '200px' }}>
                    <CardContent className='campaignCardContent'>
                        {/* icon and buttons */}
                        <Grid container direction='row'>
                            <Grid item xs={10}>
                                {!currency.icon_url ? (
                                    <NoPhotographyIcon fontSize='medium' />
                                ) : (
                                    currency.icon_url && (
                                        <img
                                            height='32px'
                                            width='32px'
                                            src={currency.icon_url!}
                                            alt={currency.name}
                                            loading='lazy'
                                        />
                                    )
                                )}
                            </Grid>

                            <Grid item xs={1}>
                                <IconButton size='small' onClick={() => handleEditItem(currency)}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={1}>
                                <IconButton size='small' onClick={() => handleDeleteItem(currency)}>
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        {/* other elements to display */}
                        <Grid container direction='column' justifyContent='space-evenly' alignItems='flex-start'>
                            <Grid item>
                                <Typography variant='h6' className='promotionNameLabelStyles'>
                                    {currency.name}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className='promotionNameLabelStyles'>
                                    Currency ID:&nbsp;
                                    <span className='greyText'>
                                        {currency.currency_id}&nbsp;
                                        <ContentCopyIcon
                                            className='copyPrizeId'
                                            onClick={() => copyToClipboard(currency.currency_id)}
                                        />
                                    </span>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className='promotionNameLabelStyles'>{currency.country}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider variant='middle' />
                </Card>
            </Grid>
        ));
    }, [currenciesToRender, handleEditItem, handleDeleteItem, setNotifState]);

    return (
        <>
            <Grid item xs={3} py={2}>
                <Autocomplete
                    sx={{ background: 'white' }}
                    data-testid='dropdown-menu-with-search'
                    options={['All', ...promotionMarketList]}
                    value={selectedMarket || null}
                    onChange={(_, value) => {
                        handleDropDownChange(value!);
                    }}
                    renderInput={(params) => <TextFieldWrapper {...params} label='Filter by market' />}
                />
            </Grid>

            {!currenciesToRender ? (
                <Typography>No Results Found</Typography>
            ) : (
                <Box className='boxGridItems'>
                    <Grid container columnSpacing={3} rowSpacing={2} className='gridStyles'>
                        {mappedCurrencies}
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default CurrencyDataGridComponent;
