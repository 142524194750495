import { notificationType } from '../types/notifications';
import { CurrencyTableItem } from '../types/componentTypes/CurrencyTypes';

export const INPUT_NAME_LIMIT = 90;
export const LIST_CURR_ITEMS_PER_PAGE_LIMIT = 6;

export enum CurrencyPaths {
    ListCurrencies = '/listCurrencies',
    CreateCurrency = '/createCurrency',
    EditCurrency = '/editCurrency/:currencyId',
    SummaryScreen = '/createCurrency/summary',
}

export enum AllowedTextAlignments {
    Left = 'left',
    Right = 'right',
    Center = 'center',
}

export enum TextElements {
    EditCurr = 'Edit Currency',
    CreateCurr = 'Create Currency',
    SubTitleText = 'Enter a unique name to make this currency stand out from the existing ones',
    SuccessCurrCreation = 'Currency has been successfully created',
    SuccessCurrUpdate = 'Currency has been successfully updated',
    DescribeCurrLocation = 'You can find it in the List Currencies Tab',
    MoreThanSixHoursWarning = "It's been more than 6 hours since this currency was created and can be only marked as inactive. Do you want to proceed?",
    TransactionsFoundWarning = 'There were some transactions associated with this currency. It can be only marked as inactive. Do you want to proceed',
    DeleteCurrencyConfMessage = 'This currency will be deleted. Do you want to proceed?',
    LegacyCurrModificationMessage = 'Unable to modify currency due to legacy restrictions.',
    GenericUnableToModifyCurrMessage = 'Unable to modify currency at this time',
    GenericTransactionsFoundMessage = 'There are transactions associated with this currency.',
    GenericCurrMissingDateMessage = 'Currency is missing creation date.',
    GenericCurrMissingTimeMessage = 'Currency is missing creation time.'
}

export const CreateCurrencySummaryProps = ({
    isEdit,
    currencyId,
    addButtonCallBack,
}: {
    isEdit: boolean;
    currencyId: string;
    addButtonCallBack?: () => void;
}) => ({
    titleText: isEdit ? TextElements.EditCurr : TextElements.CreateCurr,
    subTitleText: isEdit ? TextElements.SuccessCurrUpdate : TextElements.SuccessCurrCreation,
    descriptionText: TextElements.DescribeCurrLocation,
    copyButtonLabel: 'Currency ID',
    copyButtonText: 'Copy ID',
    copyButtonValue: currencyId,
    ...(!isEdit
        ? {
            addButtonConfig: {
                buttonId: 'create-currency-button',
                buttonCallBack: addButtonCallBack,
                buttonText: 'Create Another Currency',
            },
        }
        : {}),
    viewButtonConfig: {
        buttonId: 'view-currencies-button',
        buttonPath: CurrencyPaths.ListCurrencies,
        buttonText: 'View Currencies',
    },
});

export const defaultNotifState: notificationType = {
    open: false,
    title: '',
    content: '',
};

export const defaultModalState: {
    open: boolean;
    title?: string;
    description: string;
    currencyForRemoval?: CurrencyTableItem;
} = {
    open: false,
    title: '',
    description: '',
};

export const modalStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export const NotificationMessages: { [key: string]: notificationType } = {
    fileDeleted: {
        open: true,
        title: 'File Deleted',
        content: 'Icon has been removed from currency',
        level: 'info',
    },
    errorGroupingCurr: {
        open: true,
        title: 'Page Error',
        content: 'Unable to create currencies at this time. Please check the console for more details',
        level: 'error',
    },
    noCurrenciesFound: {
        open: true,
        title: 'No active currencies found',
        content: 'No active currencies where returned',
        level: 'info',
    },
    generalListCurrenciesError: {
        open: true,
        title: 'Error',
        content: 'Currencies can not be loaded at this time',
        level: 'error',
    },
    currencyCreationFailed: {
        open: true,
        title: 'Currency Creation Failed',
        content: '',
        level: 'error',
    },
    currencyUpdateFailed: {
        open: true,
        title: 'Currency Update Failed',
        content: '',
        level: 'error',
    },
    currencyEditFailed: {
        open: true,
        title: 'Unable to edit currency',
        content: 'There were transactions associated with this currency. It cannot be edited',
        level: 'error',
    },
    currencyDeleteFailed: {
        open: true,
        title: 'Unable to delete currency',
        content: '',
        level: 'error',
    },
    generalErrorMessage: {
        open: true,
        title: 'Error',
        content: '',
        level: 'error',
    },
};

const CurrencyEditFailedMessage = (errorMessage: string): notificationType => ({
    ...NotificationMessages.currencyEditFailed,
    content: errorMessage,
});

const CurrencyDeleteFailedMessage = (errorMessage: string) => ({
    ...NotificationMessages.currencyDeleteFailed,
    content: errorMessage,
});

export const getCurrencyUpdateFailedMessage = (errorMessage: string): notificationType => ({
    ...NotificationMessages.currencyUpdateFailed,
    content: errorMessage,
});

export const getCurrencyCreationFailedMessage = (errorMessage: string): notificationType => ({
    ...NotificationMessages.currencyCreationFailed,
    content: errorMessage,
});

export const getGeneralCurrencyErrorMessage = (errorMessage: string): notificationType => ({
    ...NotificationMessages.generalErrorMessage,
    content: errorMessage,
});

export const getErrorMessageForCurrencyModifications = (message: string, action: 'delete' | 'edit') => {
    return action === 'delete' ? CurrencyDeleteFailedMessage(message) : CurrencyEditFailedMessage(message)
}
