import { SyntheticEvent } from 'react';
import { Button, Input, Grid, FormHelperText } from '@mui/material';
import TextFieldWrapper from '../../../../common/TextFieldWrapper/TextFieldWrapper';
import { getFileNameFromPath, getUniqueUploadedFiles } from '../fileUploadHelpers';
import { uploadToStorage } from '../services/uploadActions';

const FileInput = ({
    placeholder,
    files,
    textInputFiles,
    cachedFiles,
    usePublicDomain,
    filePath,
    maxFiles,
    errorText,
    hiddenFileInput,
    setNotificationState,
    prepareValidFiles,
    updateProgressInfo,
    bucket,
    acl,
    setProgress,
    setFile,
    uploadCallback,
    allFiles,
}) => {
    const getTextInputValue = () => {
        const items = textInputFiles?.length ? textInputFiles : cachedFiles
        return items
            .map((item: any) => {
                const itemName = item.name || item.fileName
                return getFileNameFromPath(usePublicDomain, itemName, filePath)
            })
            .join(',');
    };

    const handleClick = (_: SyntheticEvent) => {
        hiddenFileInput.current?.click();
    };

    const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const uniqueUploadedFiles = getUniqueUploadedFiles(e, files, maxFiles, setNotificationState, allFiles);
        if (uniqueUploadedFiles) {
            const validFiles = await prepareValidFiles(uniqueUploadedFiles);
            const fileKeys = await uploadToStorage(validFiles,
                updateProgressInfo,
                bucket,
                acl,
                filePath,
                usePublicDomain,
                setProgress,
                setFile);

            if (uploadCallback && validFiles.length > 0) {
                uploadCallback(fileKeys.map((key: string) => key.split('/').at(-1)));
            }

            setFile(Array.from(new Set([...files, ...validFiles])));
        }
    };

    return (
        <Grid container direction='row'>
        <Grid item xs={9}>
            <TextFieldWrapper
                sx={{ width: '400px' }}
                className='file-input-field'
                placeholder={placeholder}
                disabled
                value={getTextInputValue()}
            />
            {errorText && <FormHelperText error={true}>
                    {errorText}
            </FormHelperText>}
        </Grid>
        <Grid item xs={3}>
            {(files.length || cachedFiles.length) < maxFiles && (
                <Button className='file-button' variant='contained' color='primary' onClick={handleClick}>
                    Choose File
                </Button>
            )}
        </Grid>

        <Grid item>
            <Input
                inputRef={hiddenFileInput}
                type='file'
                inputProps={{ multiple: true, 'data-testid': 'fileInput' }}
                onChange={handleUpload}
                style={{ display: 'none' }}
            />
        </Grid>
    </Grid>
    )
}

export { FileInput };
