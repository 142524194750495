import { Box } from '@mui/material';
import { FileInput } from "./FileInputComponent"
import { ProgressComponent } from './ProgressComponent';
import { CachedFilesComponent } from './CachedFilesComponent';

const MainFileUploadComponent = (props) => {
    return (
        <Box>
            <FileInput
                placeholder={props.placeholder}
                files={props.files}
                cachedFiles={props.cachedFiles}
                usePublicDomain={props.usePublicDomain}
                filePath={props.filePath}
                maxFiles={props.maxFiles}
                errorText={props.errorText}
                hiddenFileInput={props.hiddenFileInput}
                textInputFiles={props.textInputFiles}
                prepareValidFiles={props.prepareValidFiles}
                setNotificationState={props.setNotificationState}
                updateProgressInfo={props.updateProgressInfo}
                uploadCallback={props.uploadCallback}
                bucket={props.bucket}
                acl={props.acl}
                setProgress={props.setProgress}
                setFile={props.setFile}
                allFiles={props.allFiles}
            />
            {!props.backstepDone && !props.isEdit &&
                <ProgressComponent
                progressFiles={props.progressInfo}
                usePublicDomain={props.usePublicDomain}
                filePath={props.filePath}
                handleDeleteFile={props.handleDeleteFile}
                canEdit={props.canEdit}
                setOpenEditModal={props.setOpenEditModal}
            /> }
            <CachedFilesComponent
                backstepDone={props.backstepDone}
                isEdit={props.isEdit}
                cachedFiles={props.cachedFiles}
                progressFiles={props.progressInfo}
                filePath={props.filePath}
                usePublicDomain={props.usePublicDomain}
                handleDeleteFile={props.handleDeleteFile}
                canEdit={props.canEdit}
                setOpenEditModal={props.setOpenEditModal}
            />
    </Box>
    )
}

export { MainFileUploadComponent }
