import { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography, Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { Notification } from '../../common/Notification/Notification';
import '../../pages/AssignPromotion/AssignPromotionPage.css';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
import { defaultNotifState } from '../../constants/currency-constants';
interface SaveProps {
    formik: any
}

function SavePromotion({ formik }: SaveProps) {
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const copyConfigId = (value: string) => {
        navigator.clipboard.writeText(value);
        setNotificationState({
            open: true,
            title: "Promotion ID copied to clipboard",
            content: "",
            level: "success"
        })
    }

    return (
        <>
        <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
        <Box className='text-box'>
            <CheckCircleOutlineIcon className='save-promo-icon'></CheckCircleOutlineIcon>
            <Typography variant='h4' gutterBottom>Promotion is successfully created!</Typography>
            <Typography variant='body1' gutterBottom>You can find it in the list of assigned promotions under your Campaign profile</Typography>
            <Box className='save-promo-box'>
            {formik.values?.flow !== 'promoEntry' &&
                <Button variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />} className='save-promo-buttons' component={Link} to={`/addPrize/${formik.values.promotionId}/${formik.values.configurationId}`}>Add a Prize</Button>
            }
                <Button variant="outlined" component={Link} to={`/editPromotion/${formik.values.configurationId}`} color="primary" className='save-promo-buttons'>View Promotion</Button>
            </Box>
            <Box>
                <Grid container={true} justifyContent="center">
                    <TextFieldWrapper
                        name="configId"
                        variant="outlined"
                        label='Promotion ID'
                        value={formik.values.configurationId}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: 'form-control' }}
                    />
                    <Button variant="contained" color="primary" onClick={() => copyConfigId(formik.values.configurationId)}>Copy ID</Button>
                </Grid>

            </Box>
        </Box>
        </>
    )
}

export { SavePromotion }
