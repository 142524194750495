import { Modal, Button, Typography, Box } from '@mui/material'
import { useFormik } from 'formik';
import { SelectLanguage } from '../assignPromotionFlowComponent/LanguageSelector';
import './Modal.css'

interface AddToPrizeTiersProps {
    modalOpenState: boolean,
    listToInsertTo: Array<any>,
    country: string,
    handleClose(modalState: boolean): void,
    handleCustomIndex(index: number | null): void
}


function AddToPrizeLanguageTabs({ modalOpenState, listToInsertTo, country, handleClose, handleCustomIndex }: AddToPrizeTiersProps) {


    const handleCancel = () => {
        handleCustomIndex(listToInsertTo.length - 1)
        handleClose(false);
    }

    const formik = useFormik({
        initialValues: {
            prizeLanguage: ''
        },
        onSubmit: async (values) => {
            try {

                if(listToInsertTo.includes(values.prizeLanguage)){
                    formik.setFieldError('prizeLanguage', 'Such option is already present in the tabs!');
                } else {
                    const index = listToInsertTo.length;
                    listToInsertTo.splice(index, 0, values.prizeLanguage);
                    handleCustomIndex(index);
                    handleClose(false);
                }
            } catch (e) {
                console.error("Failed to insert data with: ", e);
            }
        },
    });

    return (
        <div>
            <Modal
                open={modalOpenState}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal_main'>
                    <Box className='body'>
                        <Typography id="modal-modal-title" variant="h6">
                            Add New Language
                        </Typography>
                    </Box>
                    <Typography id="modal-modal-title" variant="body1">
                        Select Additional Language
                    </Typography>
                    <SelectLanguage formik={formik} countryValue={country} formikLanguageField={'prizeLanguage'} label={''} />
                    <Box sx={{ mt: 5 }}>
                        <Button disabled={formik.values.prizeLanguage ? false : true} onClick={() => formik.submitForm()} className='button'>ADD</Button>
                        <Button onClick={() => handleCancel()} className='button'>CANCEL</Button>
                    </Box>
                </Box>
            </Modal>

        </div>
    )
}

export { AddToPrizeLanguageTabs }
