import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography, Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { saveAllocationRulesProps } from '../../types/componentTypes/allocationRules';
import '../../pages/AssignPromotion/AssignPromotionPage.css';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';


function SaveRule({ formik, onAddNewRuleClick, onGoBackClick }: saveAllocationRulesProps) {
    return (
        <Box className='allocate-rules-save-box'>
            <CheckCircleOutlineIcon className='save-promo-icon'></CheckCircleOutlineIcon>
            <Typography variant='h4' gutterBottom>Rule has been successfully created!</Typography>
            <Box className='save-promo-box'>
                <Button variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />} className='save-promo-buttons' onClick={onAddNewRuleClick}>Add Another Rule</Button>
                <Button variant="outlined" color="primary" className='save-promo-buttons' component={Link} to={`/editPromotion/${formik.values.configuration_id}`} state={{ tab: 'allocationRules' }} onClick={() => onGoBackClick({ showScreen: false })}>View Promotion</Button>
            </Box>
            <Box>
                <Grid container={true} justifyContent="center">
                    <TextFieldWrapper
                        name="ruleId"
                        variant="outlined"
                        label='Rule ID'
                        value={`${formik.values.program_id}|${formik.values.lot_id}|${formik.values.currency_id}`}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: 'form-control' }}
                    />
                    <Button variant="contained" color="primary" onClick={() => { navigator.clipboard.writeText(`${formik.values.program_id}|${formik.values.lot_id}|${formik.values.currency_id}`) }}>Copy ID</Button>
                </Grid>

            </Box>
        </Box>
    )
}

export { SaveRule }
