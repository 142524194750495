import { Modal, Grid, Typography, Button } from '@mui/material';
import { defaultModalState, modalStyles } from '../../constants/currency-constants';
import { updateCurrency } from '../../pages/Currencies/services/api-calls';
import { CurrencyModalProps } from '../../types/componentTypes/CurrencyTypes';

const CurrencyModal = ({ props }: CurrencyModalProps) => {
    const {
        showModal,
        content,
        currencyForRemoval,
        confirmButtonText,
        declineButtonText,
        setModalState,
        triggerDataRefresh,
    } = props;

    const handleDeleteCurrency = async () => {
        try {
            await updateCurrency(currencyForRemoval);
        } catch (error) {
            console.error(error);
        } finally {
            setModalState(defaultModalState);
            triggerDataRefresh(true);
        }
    };

    const handleCloseModal = () => {
        setModalState(defaultModalState);
    };

    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'>
            <Grid container sx={{ ...modalStyles }} justifyContent='center' spacing={2}>
                <Grid item></Grid>
                <Grid item>
                    <Typography className='dialogueContentTextStyles' variant='h4' id='modal-description' gutterBottom>
                        {content}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button sx={{ fontWeight: 'bold' }} onClick={handleCloseModal}>
                        {declineButtonText || 'Cancel'}
                    </Button>
                    <Button sx={{ fontWeight: 'bold' }} onClick={handleDeleteCurrency}>
                        {confirmButtonText || 'Yes'}
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CurrencyModal;
