import * as yup from 'yup';
import moment from "moment";

interface TestContextExtended {
  [key: string]: any;
}

function editPromotionValidationSchema() {
return yup.object({
      configurationParameters: yup.object({
        country: yup
          .string()
          .required('Market has to be selected'),
        language: yup
          .string()
          .required('Language has to be selected'),
        configurationEndUtc: yup.mixed()
          .test("endDateTest", "End date cannot be before or the same as Start date",
          (value, testContext) => {
            const {from} = testContext as yup.TestContext & TestContextExtended;
            if(moment(from[0].value.configurationStartUtc).isAfter(moment(value), 'minute')) {
              return false;
            }
            return true;
          }),
          additionalInformation: yup.object({
            name:yup
              .string()
              .max(90,'The maximum length of the promotion name is 90 characters')
              .required('Promotion name cannot be empty'),
            description:yup
              .string()
              .max(1000,'The maximum length is 1000 characters'),
            shortDescription:yup
              .string()
              .max(250,'The maximum length is 250 characters'),
          }),
      })
    })

}
export { editPromotionValidationSchema }
