import Button from '@mui/material/Button';
import { Auth, Hub } from 'aws-amplify';

function LoginPage() {
    const login = () => {
        Auth.federatedSignIn({ customProvider: "cokeSSO" })
            .then(() => Auth.currentAuthenticatedUser())
            .then(user => {
                Hub.dispatch('auth', { event: 'oAuthSignIn', data: user }, 'Auth');
            }).catch(e => {
                Hub.dispatch('auth', { event: 'oAuthSignIn_failed', data: e }, 'Auth');
            })
    }

    return (
        <div>
            <h3>Please login to your account</h3>
            <Button onClick={login} variant="outlined">
                Log in
            </Button>
        </div>
    )
}

export { LoginPage };
