import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import awsconfig from '../../../../aws-exports';
import { appendPublicDomainName } from '../fileUploadHelpers';

const UploadedFilesActionButtons = ({
    fileName,
    usePublicDomain,
    filePath,
    handleDeleteFile,
    canEdit,
    setOpenEditModal,
}) => {
    return (
        <>
            {canEdit && (
                <Grid item xs={1} className='delete-file-grid'>
                    <EditIcon className='delete-icon' onClick={() => setOpenEditModal({
                        open: true,
                        url: usePublicDomain
                            ? appendPublicDomainName({
                                path: `${filePath}/${decodeURI(fileName)}`,
                                bucketName: awsconfig.aws_user_files_s3_bucket
                            })
                            : fileName
                    })}
                    />
                </Grid>
            )}
            <Grid item xs={canEdit ? 1 : 2} className='delete-file-grid'>
                <DeleteIcon
                    className='delete-icon'
                    onClick={() => {
                        handleDeleteFile(fileName);
                    }}
                />
            </Grid>
        </>
    )
}

export { UploadedFilesActionButtons }
