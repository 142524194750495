import { Storage } from 'aws-amplify';
import { AddPrizeState, emptyConfigType } from '../../../types/componentTypes/prizeComponentTypes';

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: ''
    }
});

export const processSubmitValues = async (values: AddPrizeState, configData: emptyConfigType) => {
    const { tempParams, ...mainValues } = values;
    const data = transformToMap(mainValues);

    const finalResult = await handleInstantWin(data, configData);
    handleImagesMetadata(finalResult, tempParams);

    return finalResult;
}

const handleInstantWin = async (data, configData) => {
    if (configData?.flow?.instantWin) {
        return await checkAndAddWinningLimit(data, configData);
    } else {
        delete data.pool_prize;
        return { ...data, cost: JSON.stringify(data.cost) };
    }
}

const handleImagesMetadata = (finalResult, tempParams) => {
    if (finalResult.images_metadata) {
        const languages = Object.keys(JSON.parse(finalResult.name));
        finalResult.images_metadata = JSON.stringify(
            cleanImageMetadata(finalResult.images_metadata, tempParams.useDefaultImg, languages));
    }

    if (!tempParams.useDefaultImg && !finalResult.images_metadata) {
        finalResult.images_metadata = JSON.stringify(getImgMetadataInfo(tempParams.img_url));
    }
}


const cleanImageMetadata = (metadata, defaultImg, languages) => {
    const imgMetadataObj = Object.fromEntries(
        Object.entries(metadata).map(([key, value]) => [key, Object.values(value)])
    )
    if (defaultImg) {
        const existingLanguage = Object.keys(metadata)[0];
        languages.forEach((lang) => {
            if(!imgMetadataObj[lang]) {
                imgMetadataObj[lang] = [...imgMetadataObj[existingLanguage]]
            }
        })
    }
    return imgMetadataObj
}

const getImgMetadataInfo = (imgUrls) => {
    let imgMetadataObj = {}
    for (const language in imgUrls) {
        imgMetadataObj[language] = imgUrls[language].map((url, index) => ({
            activeStatus: false,
            priority: index + 1,
            ratio: "vertical",
            size: "medium",
            url: url
        }));
        }
    return imgMetadataObj
}

const transformToMap = (values : {[key:string]: any}) => {
    const stateValues = ['name', 'desc', 'short_desc', 'redeem_desc'];
    stateValues.forEach((value:string ) => {
        const arrayValue = values[value];
        const mapValue = Object.assign({}, ...arrayValue);
        values[value] = JSON.stringify(mapValue)
       })
    return values;
}

const checkAndAddWinningLimit = async (values : {[key:string]: any}, configData) => {
    delete values.auto_redeem;
    delete values.cost;
    try {
        if ((values.redemption_limit !== null && values.redemption_limit > 0)) {
            const { prize_id, redemption_limit, configuration_id } = values;
            const winningLimits = {
                ...configData.flow.instantWin?.params?.winningLimitsPerPrize,
                [prize_id]: parseInt(redemption_limit)
            };
            configData.flow.instantWin = {
                ...configData.flow.instantWin,
                params: {
                    ...configData.flow.instantWin?.params,
                    winningLimitsPerPrize: winningLimits
                }
            }
            delete values.redemption_limit;
            const fileName = `${configuration_id}/conf.txt`;
            await Storage.put(fileName, JSON.stringify(configData), { contentType: 'text/plain'});
            } else if ((values.redemption_limit === null || values.redemption_limit <= 0) && configData.flow.instantWin?.params?.winningLimitsPerPrize){
                const { prize_id, configuration_id } = values;
                const {[prize_id]:_, ...rest} = configData.flow.instantWin.params.winningLimitsPerPrize;
                if (Object.keys(rest).length > 0 ) {
                    configData.flow.instantWin.params.winningLimitsPerPrize = {...rest}
                } else {
                    delete configData.flow.instantWin.params.winningLimitsPerPrize;
                }
                delete values.redemption_limit;

                const fileName = `${configuration_id}/conf.txt`;
                await Storage.put(fileName, JSON.stringify(configData), { contentType: 'text/plain'})
            }
            return values
    } catch (err) {
        console.error("Error:", err)
    }
}

