import { Backdrop, CircularProgress } from '@mui/material';

type IGenericSpinnerProps = {
    showSpinner?: boolean;
    backdropColor?: string;
    circularProgressColor?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
};

const GenericSpinner = ({
    showSpinner = false,
    backdropColor = '#fff',
    circularProgressColor = 'inherit',
}: IGenericSpinnerProps) => (
    <>
        <Backdrop sx={{ color: backdropColor, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showSpinner}>
            <CircularProgress color={circularProgressColor} />
        </Backdrop>
    </>
);

export default GenericSpinner;
