import { useState, Dispatch, SetStateAction } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { ensureOrderOfFlowLambdas } from '../../AssignPromotion/AssignPromotionPage';
import { CurrenciesForm } from './CurrenciesForm';
import { saveConfig } from '../../../utils/s3FileUtils';
import { notificationType } from '../../../types/notifications';
import { IUpdatedConfig, updateCurrencies } from './services/api-calls';
import { currenciesValidationSchema } from './CurrenciesValidationSchema';
import useGetCurrencyTabParams from './hooks/useGetCurrencyTabParams';
import { CurrencySubmitValues, EditCurrenciesState } from '../../../types/componentTypes/editPromotion';
import { helpfulLinks } from './constants/currency-constants';

import GenericSpinner from '../../../common/Spinners/GenericSpinner';

import './Currencies.css';

interface CurrenciesProps {
    mechanic: string;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
    handleTabChange: Dispatch<SetStateAction<boolean>>;
}

const processCheckerArrays = ({ initialCheckers = [], updatedCheckers = [] }: {initialCheckers?: string[], updatedCheckers?: string[]}) => {
    if(!updatedCheckers.length) return initialCheckers;

    if(!initialCheckers.length) return updatedCheckers;

    return [...initialCheckers, ...updatedCheckers]
}

const Currencies = ({ setNotificationState, handleTabChange, mechanic }: CurrenciesProps) => {
    const urlParams = useParams();
    const fileName = `${urlParams.promotionId}/conf.txt`;

    const [initialState, setInitialState] = useState<EditCurrenciesState>();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    const [currentConfigCurrencies, setCurrentConfigCurrencies] = useState<string[]>([]);
    const [promoFlow, setPromoFlow] = useState<string>('');

    const { isLoading, refetchCurrencies } = useGetCurrencyTabParams({
        fileName,
        mechanic,
        setPromoFlow,
        setInitialState,
        setNotificationState,
        setCurrentConfigCurrencies,
    });

    const handleSubmitValues = (values: CurrencySubmitValues): IUpdatedConfig => {
        const { params, tempParams, flowLambdas, ...rest } = values;
        let updatedValues = {
            ...rest,
            flow: {
                ...rest.flow,
                [promoFlow]: {
                    ...rest?.flow[promoFlow],
                    ...(rest.flow[promoFlow]?.params && {
                        params: {
                            ...rest.flow[promoFlow].params,
                        },
                    }),
                    ...(rest?.flow[promoFlow]?.checkerLambdas && {
                        checkerLambdas: rest?.flow[promoFlow]?.checkerLambdas
                    })
                },
            },
        };
        if (promoFlow === 'instantWin' || promoFlow === 'promoEntry') {
            if (!tempParams.useCurrency) {
                if (updatedValues.flow[promoFlow]?.params?.reduceAmount) {
                    delete updatedValues.flow[promoFlow]?.params?.reduceAmount;
                    delete updatedValues.configurationParameters.currencies;
                }
                updatedValues.flow[promoFlow].checkerLambdas = updatedValues.flow[promoFlow]?.checkerLambdas?.filter(checkerLambda => checkerLambda !== 'currencyCheckerLambda')
                const currencyReducerExists = updatedValues.flow[promoFlow].flowLambdas.find(
                    (item: string) => item === 'currencyReducer'
                );
                if (currencyReducerExists) {
                    updatedValues.flow[promoFlow].flowLambdas = updatedValues.flow[promoFlow].flowLambdas.filter(
                        (lambda: String) => lambda !== 'currencyReducer'
                    );
                }
                if (updatedValues.configurationParameters.currencies) {
                    delete updatedValues.configurationParameters.currencies;
                }
                if (updatedValues.flow[promoFlow]?.params?.reduceAmount) {
                    delete updatedValues.flow[promoFlow].params.reduceAmount;
                }
            } else {
                const updatedCurrencies: string[] = [];
                for (const reduceAmountObj of params.reduceAmount) {
                    reduceAmountObj.amount = +reduceAmountObj.amount;
                    updatedCurrencies.push(reduceAmountObj.currencyId);
                }
                updatedValues.flow[promoFlow].flowLambdas = ensureOrderOfFlowLambdas(flowLambdas);

                updatedValues.flow[promoFlow].checkerLambdas = processCheckerArrays({
                    initialCheckers: updatedValues.flow[promoFlow]?.checkerLambdas,
                    updatedCheckers: rest?.checkerLambdas
                })
                delete updatedValues.checkerLambdas;
                updatedValues.flow[promoFlow].params.reduceAmount = params.reduceAmount;
                updatedValues.configurationParameters.currencies = updatedCurrencies;
            }
        }

        if (promoFlow === 'redeemPincodeForCurrencies' || promoFlow === 'autoRedeemCnG') {
            if (tempParams.totalCurrencyAccumulated) {
                updatedValues.configurationParameters.additionalInformation.totalCurrencyAccumulated = true;
            } else if (
                !tempParams.totalCurrencyAccumulated &&
                updatedValues.configurationParameters.additionalInformation.totalCurrencyAccumulated
            ) {
                delete updatedValues.configurationParameters.additionalInformation.totalCurrencyAccumulated;
            }

            if (tempParams.currencyValidity) {
                const validityValues = rest.configurationParameters.currencies.reduce((acc: any, currency: string) => {
                    acc[currency] = tempParams.validityDays;
                    return acc;
                }, {});
                updatedValues.configurationParameters.validity = validityValues;
            } else if (!tempParams.currencyValidity && updatedValues.configurationParameters.validity) {
                delete updatedValues.configurationParameters.validity;
            }
        }
        return updatedValues;
    };

    return (
        <>
            <GenericSpinner showSpinner={isLoading || showLoadingSpinner} />

            <Box className='currencies-main-box'>
                <Box className='currencies-tap-wrapper'>
                    <Typography variant='h3'>Currencies</Typography>
                </Box>

                {!isLoading && initialState?.params && (
                    <>
                        <Formik
                            initialValues={initialState}
                            enableReinitialize
                            validationSchema={currenciesValidationSchema}
                            onSubmit={async (values: any, { resetForm }) => {
                                try {

                                    if (promoFlow === 'redeemPincodeForCurrencies' && values.tempParams.currencyNames.length < 1) {
                                        setNotificationState({
                                            open: true,
                                            title: 'Currency is mandatory',
                                            content: 'C&G promotion cannot be saved without currency.',
                                            level: 'error',
                                        });
                                        resetForm()
                                    } else {
                                    setShowLoadingSpinner(true);
                                    const updatedConfig = handleSubmitValues(values);
                                    await saveConfig({ urlParams, submitData: updatedConfig, setNotificationState });

                                    const availableCurrencies = await refetchCurrencies();
                                    await updateCurrencies({
                                        updatedConfig,
                                        availableCurrencies,
                                        currentConfigCurrencies,
                                        previousCurrencies: currentConfigCurrencies,
                                        setNotificationState,
                                        setCurrentConfigCurrencies,
                                    });

                                    setNotificationState({
                                        open: true,
                                        title: 'Promotion updated successfully',
                                        content: 'Promotion Changes were saved!',
                                        level: 'success',
                                    });
                                }
                                } catch (e) {
                                    console.error(e);
                                } finally {
                                    setShowLoadingSpinner(false);
                                }
                            }}>
                            <Form>
                                <CurrenciesForm handleTabChange={handleTabChange} />
                            </Form>
                        </Formik>
                        <Typography variant='h3' className='links-title'>
                            Helpful Links
                        </Typography>
                        {promoFlow && (
                            <Box className='helpfulLinks'>
                                {helpfulLinks[promoFlow].map((helpfulLink, i) => (
                                    <Link key={i} target='_blank' href={helpfulLink.link} rel='noopener noreferrer'>
                                        {helpfulLink.text}
                                    </Link>
                                ))}
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};

export { Currencies };
