import { Modal, Typography, Box, Link, IconButton, Tabs, Tab } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { ObjectSchema } from 'yup';
import { parse } from 'papaparse';
import { COMMON, UTILITY_FUNCTIONS } from '@the-coca-cola-company/ngps-global-common-utils';
import { FileUpload } from '../inputComponents/FileUpload/FileUpload.component';
import { Notification } from '../../common/Notification/Notification';
import { TooltipText } from '../inputComponents/TooltipText';
import { useState } from 'react';
import { defaultNotifState } from '../../constants/currency-constants';
import './Modal.css'
import './UploadPrizesModal.css'

interface AddToListProps {
    value?: string,
    schema?: ObjectSchema<any>,
    header: string,
    textfieldLabel?: string,
    modalOpenState: boolean,
    handleClose(modalState: boolean): void,
    configId: string,
}

const uploadUrlMap = {
    'add': 'bulkPrizesCSVs',
    'update': 'bulkPrizesUpdateCSVs'
}

function UploadPrizesModal({ header, modalOpenState, handleClose, configId }: AddToListProps) {
    const [selectedUploadType, setSelectedUploadType] = useState('add')
    const [notificationState, setNotificationState] = useState(defaultNotifState);

    const validatorFunction = (file: File) => {
        return new Promise<void>((resolve, reject) => {
            parse(file, {
                header: true,
                complete: async (results, file) => {
                    try {
                        const headers = results.meta.fields ? results.meta.fields.join(',') + '\n' : '';
                        if (selectedUploadType === 'add') {
                            const expectedHeaderIW = COMMON.EXPECTED_IW_PRIZES_CSV_HEADER;
                            const expectedHeaderCandG = COMMON.EXPECTED_CG_PRIZES_CSV_HEADER;
                            const iwPrizes = await UTILITY_FUNCTIONS.extractCsvEntries(
                                headers,
                                expectedHeaderIW,
                                false,
                                false,
                                undefined,
                                true
                            );
                            const expectedHeader = iwPrizes ? expectedHeaderIW : expectedHeaderCandG;
                            await UTILITY_FUNCTIONS.extractCsvEntries(headers, expectedHeader, true, false);
                        } else {
                            const expectedUpdateHeader = ['prizeId', 'language', 'name', 'shortDesc', 'desc', 'redeemDesc'];
                            await UTILITY_FUNCTIONS.extractCsvEntries(
                                headers,
                                expectedUpdateHeader,
                                false,
                                false
                            );
                        }
                        resolve();
                    } catch (err) {
                        console.error('Err is', err);
                        reject(false);
                        setNotificationState({
                            open: true,
                            title: 'Error',
                            content: `Error with ${file.name}: ${err}`,
                            level: 'error',
                        });
                    }
                }
            })
        })
    }

    return (
        <div>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            <Modal
                open={modalOpenState}
                onClose={() => { handleClose(false); }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal_main'>
                    <Box className='uploadModalHeader'>
                        <Typography id="modal-modal-title" variant="h6">
                            {header}
                        </Typography>
                        <IconButton onClick={() => handleClose(false)} className='uploadModalCloseIcon'>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Tabs
                        value={selectedUploadType}
                        aria-label="basic tabs example"
                        className="UploadPrizesTab"
                        onChange={(event: React.BaseSyntheticEvent, newValue: string) => { setSelectedUploadType(newValue) }}
                    >
                        <Tab value='add'
                            label={<TooltipText
                                textVariant='subtitle1'
                                text="Add Prizes"
                                iconSize="medium"
                                tooltip={<Typography variant='caption'>Each prize can have up to 5 image urls per language. If more are uploaded, only the first 5 will be considered. For more details, check the&nbsp;
                                    <Link href="https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/4775/Add-Prizes-via-Bulk-Upload" target='_blank' rel="noopener noreferrer">
                                        documentation.
                                    </Link></Typography>}></TooltipText>}
                        />
                        <Tab label="Update Prizes" value='update' />
                    </Tabs>
                    <FileUpload
                        placeholder='CSV File with prizes'
                        filePath={`${uploadUrlMap[selectedUploadType]}/${configId}`}
                        uploadCallback={(files: any) => {
                            setNotificationState({
                                open: true,
                                title: `Prize file was ${selectedUploadType === 'update' ? 'updated' : 'added'} successfully`,
                                content: `Prize file uploaded: ${files.join(', ')}`,
                                level: "success"
                            });
                        }}
                        deleteCallback={(file: string) => {
                            setNotificationState({
                                open: true,
                                title: `Prize file was removed successfully`,
                                content: `Removed prize file: ${file}`,
                                level: "success"
                            });
                        }}
                        headerValidation={true}
                        headerValidatorFunc={validatorFunction}
                        setNotificationState={setNotificationState}
                    />
                </Box>
            </Modal>
        </div>
    )
}

export { UploadPrizesModal }
