import { useState, useEffect } from 'react';
import { GridWithPagination } from '../../common/GridWithPagination/GridWithPagination';

import { Campaign } from '../../types/campaign';

interface searchParams {
    searchBarValue: string;
    dataFetchFunction: (filterInput: string) => Promise<Campaign[]>;
}

const getMatchedSearchItems = (items: Campaign[], searchValue: string) =>
    items.filter(
        (item) =>
            item.promotion_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.promotion_id?.toLowerCase().includes(searchValue.toLowerCase())
    );

export default function Campaigns({ searchBarValue, dataFetchFunction }: searchParams): JSX.Element {
    const [dataToDisplay, setDataToDisplay] = useState<Campaign[]>([]);

    useEffect(() => {
        dataFetchFunction('').then((fetchedData: Campaign[]) =>
            setDataToDisplay(getMatchedSearchItems(fetchedData, searchBarValue))
        );
    }, [searchBarValue, dataFetchFunction]);

    return <GridWithPagination gridData={dataToDisplay} />;
}

export { Campaigns };
