// libs
import { useState } from 'react';
import { Grid } from '@mui/material';

// custom
import { mechanicViews } from '../layouts/MechanicViews';
import ButtonComponent from '../components/ButtonComponent';

//types
import type {
    GetComponentViewProps,
    AdditionalDetailsState,
    AdditionalDetailsFormProps,
    GenericButtonProps,
    GetFormButtonsProps,
} from '../types/AdditionalDetailsTypes';

function getFormView<T extends AdditionalDetailsState>(props: GetComponentViewProps<T>): JSX.Element {
    // reuse the Collect & Get mechanicView for Auto CnG promo
    const formView = (props.mechanic === 'Auto Collect & Get') ? 'Collect & Get' : props.mechanic;

    if (!(formView in mechanicViews)) throw new Error(`Invalid mechanic: ${props.mechanic}`);

    return mechanicViews[formView](props);
}

function getFormButtons(
    props: GetFormButtonsProps
): GenericButtonProps<AdditionalDetailsFormProps['onSaveClickHandler']>[] {
    const { formIsDirty, handleDiscardChanges, pageErrors, onSaveClickHandler } = props;

    return [
        {
            styles: { width: '100px' },
            buttonText: 'Discard',
            testId: 'additional-details-discard-button',
            disabledCondition: !formIsDirty,
            onClickHandler: handleDiscardChanges,
            variant: 'outlined' as const,
            color: 'primary' as const,
        },
        {
            styles: { width: '100px' },
            buttonText: 'Save',
            testId: 'additional-details-save-button',
            disabledCondition: !!pageErrors.size || !formIsDirty,
            onClickHandler: onSaveClickHandler,
            variant: 'contained' as const,
            color: 'primary' as const,
        },
    ];
}

function FormView(props: AdditionalDetailsFormProps) {
    const { formIsDirty, handleDiscardChanges, pageErrors, onSaveClickHandler, updateStateValues, ...otherProps } =
        props;

    const [modalOpen, setModalOpen] = useState(false);

    const formButtons = (
        <Grid container justifyContent='flex-end' spacing={2} sx={{ paddingRight: '9.5rem' }}>
            {getFormButtons({
                formIsDirty,
                handleDiscardChanges,
                pageErrors,
                onSaveClickHandler,
            }).map((buttonProps) => (
                <ButtonComponent key={`additional-details-${buttonProps.buttonText}`} {...buttonProps} />
            ))}
        </Grid>
    );

    const formView = getFormView({
        ...otherProps,
        pageErrors,
        modalOpen,
        setModalOpen,
        updateStateValues,
    });

    return (
        <>
            {formView}
            {formButtons}
        </>
    );
}

export function useAdditionalDetailsFormBuilder(props: AdditionalDetailsFormProps) {
    return <FormView {...props} />;
}
