import { useEffect, useState, useRef } from "react";
import { configureStorage, validateFileAndHeader } from "./fileUploadHelpers";
import { MainFileUploadComponent } from "./components/MainFileUploadComponent";
import { deleteFileFromStorage } from "./services/uploadActions";
import { FileUploadProps } from '../../../types/componentTypes/FileUploadTypes';
import "./FileUpload.css";

const FileUpload = ({
    isEdit,
    backstepDone,
    placeholder,
    filePath,
    maxFiles = 1,
    uploadCallback,
    deleteCallback,
    usePublicDomain = false,
    fileAccess = 'public',
    bucket = 'private',
    acl = 'public-read',
    cachedFiles = [],
    headerValidation = false,
    customAllowedFileTypes,
    canEdit = false,
    setOpenEditModal,
    errorText,
    setNotificationState,
    headerValidatorFunc
}: FileUploadProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>();
    const [files, setFile] = useState<File[]>([]);
    const [progressInfo, setProgress] = useState<Array<{ fileName: string; percent: number }>>([]);
    let progressFiles: Array<{ fileName: string; percent: number }> = [];

    useEffect(() => {
        configureStorage(fileAccess);
    }, [fileAccess]);

    const prepareValidFiles = async (files: File[]) => {
        const validFiles: File[] = [];
        await Promise.all(
            files.map(async (file: File) => {
                const isValid = await validateFileAndHeader(file, customAllowedFileTypes, headerValidation, headerValidatorFunc, setNotificationState);

                if (isValid) {
                    validFiles.push(file);
                    progressFiles.push({ fileName: file.name, percent: 0 });
                }
            })
        );

        return validFiles;
    };

    const updateProgressInfo = (progressFileInfo, index) => {
        progressFiles[index] = progressFileInfo;
        setProgress([...progressInfo, ...progressFiles]);
    }

    const handleDeleteFile = async (fileName: string) => {
        try {
            const newFiles = files.filter((file: File) => file.name !== fileName);
            const newProgressInfo = progressInfo.filter((obj: { fileName: string }) => obj.fileName !== fileName);
            await deleteFileFromStorage({
                bucket,
                filePath,
                fileName,
                deleteCallback,
                usePublicDomain
            })

            setProgress(newProgressInfo);
            setFile(newFiles);
            hiddenFileInput.current.value = null
        } catch (err) {
            console.error('Deleting file failed with:', err);
        }
    };

    return (
        <MainFileUploadComponent
            placeholder={placeholder}
            files={files}
            cachedFiles={cachedFiles}
            textInputFiles={files}
            usePublicDomain={usePublicDomain}
            filePath={filePath}
            maxFiles={maxFiles}
            errorText={errorText}
            hiddenFileInput={hiddenFileInput}
            progressInfo={progressInfo}
            handleDeleteFile={handleDeleteFile}
            canEdit={canEdit}
            setOpenEditModal={setOpenEditModal}
            backstepDone={backstepDone}
            isEdit={isEdit}
            prepareValidFiles={prepareValidFiles}
            setNotificationState={setNotificationState}
            updateProgressInfo={updateProgressInfo}
            uploadCallback={uploadCallback}
            bucket={bucket}
            acl={acl}
            setProgress={setProgress}
            setFile={setFile}
        />
      );
}
export { FileUpload };
