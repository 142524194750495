import Container from '@mui/material/Container';
import { CardMedia } from '@mui/material';

interface StepSliderProps{
    imagesFolderName:string,
    currentStep: number,
    className?: string,
    containerClass?: string
}

function StepSlider({imagesFolderName, currentStep, className, containerClass}:StepSliderProps) {

    return (
        <Container maxWidth="xl" className={containerClass}>
            <CardMedia
                className={className}
                component="img"
                image={`/assets/images/${imagesFolderName}/${currentStep}-step.svg`}
                alt="first-step"
            />
        </Container>
    );
}


 export { StepSlider};

