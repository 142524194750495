import { useFormikContext, getIn } from 'formik';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Button, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PrizeState } from '../../components/addPrizeFlowComponent/PrizeState';
import { PrizeType } from '../../components/addPrizeFlowComponent/PrizeType';
import { WinningLimits } from '../../components/addPrizeFlowComponent/WinningLimits';
import { AdditionalDetails } from '../../components/addPrizeFlowComponent/AdditionalDetails';
import { PrizeTabSwitch } from '../../components/addPrizeFlowComponent/PrizeTabSwitch';
import { SavePrize } from '../../components/addPrizeFlowComponent/SavePrize';
import { timezones } from '../../constants/timezones';
import { useState } from 'react';
import { AddPrizeFormProps } from '../../types/componentTypes/prizeComponentTypes';
import { AddPrizeState } from '../../types/componentTypes/prizeComponentTypes';

const tiersList = [0, 1, 2, 3, 4, 5];
const stepFields: { [key: number]: string[] } = {
    0: ['name', 'img_url', 'desc', 'short_desc', 'redeem_desc', 'redemption_link', 'tempParams.img_url'],
    1: ['cost'],
    2: ['tempParams.end_date'],
    3: ['redemption_limit'],
    4: ['total_amount', 'total_available']
}

function AddPrizeForm({ isEdit, step, updateStep, tabs, setReset, configurationData, initialValues, defaultLang, setNotificationState }: AddPrizeFormProps) {
    const [backstepDone, setBackstepDone] = useState(false);
    const formik = useFormikContext<AddPrizeState>();
    const { setFieldValue } = formik;

    const validateAndMove = async () => {
        stepFields[step].forEach((fieldName) => {
            formik.setFieldTouched(fieldName, true);
        })
        const validation = await formik.validateForm();
        let result = true;
        stepFields[step].forEach((fieldName) => {
            if (getIn(validation, fieldName)) {
                result = false
            }
        })
        if (result) {
            updateStep(1)
        }
    }

    const onAddNewPrizeClick = (e: any) => {
        formik.resetForm({ values: initialValues });
        setReset(true);
        updateStep(-5);
    }

    const convertToUtc = (dateParam: string, timezone: string, targetParam?: string,) => {
        const dateTimeString = getIn(formik.values, dateParam);
        const dateTime = moment(dateTimeString).format("YYYY-MM-DD HH:mm");
        const convertedDateTime = moment.tz(dateTime, timezone).utc().valueOf();
        if (targetParam) {
            setFieldValue(targetParam, convertedDateTime)
        } else {
            return convertedDateTime
        }
    }

    const handleDateTime = () => {
        const prizeTimeZone = formik.values.prize_activity_timezone;
        const timezone = Object.keys(timezones).find(key => timezones[key] === prizeTimeZone) || 'Europe/London';
        convertToUtc('tempParams.start_date', timezone, 'start_date');
        convertToUtc('tempParams.end_date', timezone, 'end_date');
    }

    const checkPrizeActive = () => {
        const { prizeStatus } = formik.values.tempParams;
        if (prizeStatus === 'inactive') {
            setFieldValue('active', false)
        }

        if (prizeStatus === 'active') {
            setFieldValue('active', true);

            const startDate =
                (configurationData.configurationParameters?.configurationStartUtc && configurationData.configurationParameters?.configurationStartUtc !== (0 || ''))
                    ? configurationData.configurationParameters?.configurationStartUtc
                    : convertToUtc('tempParams.start_date', formik.values.prize_activity_timezone);
            setFieldValue('start_date', startDate);

            const endDate =
                (configurationData.configurationParameters?.configurationEndUtc && configurationData.configurationParameters?.configurationEndUtc !== (0 || ''))
                    ? configurationData.configurationParameters?.configurationEndUtc
                    : convertToUtc('tempParams.end_date', formik.values.prize_activity_timezone);

            setFieldValue('end_date', endDate);
        }
    }

    const showNotification = () => {
        const isRatioWinning = configurationData.flow.instantWin?.params?.ratioWinning;
        const isPrizeActive = formik.values.active;
        const isAlwaysWinPrize = typeof formik.values.pool_prize === "string" && formik.values.pool_prize.toLowerCase() === "true";

        if (isRatioWinning && isPrizeActive && isAlwaysWinPrize) {
            setNotificationState({
                open: true,
                title: "",
                content: "Ratio Winning limits are enabled. Please, add the new always-win prize to the Ratio Winning Limit under the promotion Limits tab.",
                level: "warning"
            })
        }
    }

    const handleSubmitChanges = () => {
        handleDateTime();
        checkPrizeActive();
        showNotification();
        formik.submitForm()
    }

    const handleBackstep = () => {
        updateStep(-1);
        setBackstepDone(true);
    }

    return (
        <>
            {step === 0 && <PrizeTabSwitch backstepDone={backstepDone} isEdit={isEdit} formik={formik} tabs={tabs} defaultLang={defaultLang}/>}
            {step === 1 && <PrizeType configurationData={configurationData} formik={formik} />}
            {step === 2 && <PrizeState formik={formik} />}
            {step === 3 && <WinningLimits formik={formik} tiersList={tiersList} />}
            {step === 4 && <AdditionalDetails isEdit={isEdit} formik={formik} />}
            {step === 5 && <SavePrize isEdit={isEdit} formik={formik} customAction={onAddNewPrizeClick} />}
            <Box className='buttons' sx={{ mt: 5 }}>
                {step <= 0 && <Button variant="outlined" component={Link} to={`/editPromotion/${configurationData.configurationId}/prizeTab`} >Cancel</Button>}
                {step > 0 && step <= 4 && <Button color="secondary" variant="contained" startIcon={<ArrowBackIosIcon />} onClick={() => handleBackstep()}>
                    Previous
                </Button>}
                {step === 4 && <Button variant="contained" onClick={() => handleSubmitChanges()}>Complete</Button>}
                {step < 4 && <Button variant="contained" name="Next" endIcon={<ArrowForwardIosIcon />} onClick={() => validateAndMove()}>Next</Button>}
            </Box>
        </>
    )
}

export { AddPrizeForm }
