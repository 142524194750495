import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { timezones } from '../../../../constants/timezones';
import {
    limitsStateValues,
    initialValues,
    PrizeRatioType,
} from '../../../../types/componentTypes/limits';
import { ConfigType } from '../../../../types/configuration';
import {
    getIWLimitsForConfig,
    validateCheckerLambdas,
} from '../helpers/limit-helpers';
import { prizeDataType } from '../../EditPromotionPage';

const useLimitsInitialState = (config: ConfigType, prizesData?: Array<prizeDataType>) => {
    const [initialState, setInitialState] =
        useState<limitsStateValues>(initialValues);
    const [promoFlow, setPromoFlow] = useState<string>('');

    // ratio winning state
    const [prizeRatios, setPrizeRatios] = useState<Array<PrizeRatioType>>([]);

    // ratio winning dropdown options state
    const [prizeOptions, setPrizeOptions] = useState<Array<string>>([]);

    useEffect(() => {
        (config.flow?.promoEntry) ? setPromoFlow('promoEntry') : setPromoFlow('instantWin');
    },
        [config]);

    const getPrizeName = (prize: prizeDataType, languageCode: string) => {
        const prizeName = JSON.parse(JSON.stringify(prize.name));
        return JSON.parse(prizeName)[languageCode];
    };

    const processPrizeData = useCallback((prizesData: prizeDataType[], language: string) => {
        const createOptions = (currPrize) => {
            if (!currPrize.active || !currPrize.pool_prize) return null;
            return `${getPrizeName(currPrize, language)} / ${currPrize.prize_id}`;
        }

        const createData = (currPrize) => {
            if (!currPrize.winning_ratio) return null;
            return {
                prizeId: `${getPrizeName(currPrize, language)} / ${currPrize.prize_id}`,
                ratio: currPrize.winning_ratio / 100,
            };
        }

        return prizesData.reduce((acc: { options: string[], data: PrizeRatioType[] }, currPrize) => {
            const option = createOptions(currPrize);
            const datum = createData(currPrize);

            if (option) acc.options.push(option);
            if (datum) acc.data.push(datum);

            return acc;
        }, { options: [], data: [] })
    }, []);

    useEffect(() => {
        if (!prizesData.length) return;

        const language = config.configurationParameters.language;
        const { options, data } = processPrizeData(prizesData, language);

        setPrizeRatios(data.length ? data : [{ prizeId: "", ratio: 0 }]);
        setPrizeOptions(options);
    },
        [prizesData, config.configurationParameters.language, processPrizeData])

    useEffect(() => {
        const updatedState: limitsStateValues = {
            ...config,
            tempParams: {
                limits: {
                    ...initialValues.tempParams.limits,
                    minAge: !!config.flow[promoFlow]?.params?.minAge,
                    lifetime:
                        !!config.flow[promoFlow]?.params?.participationLifetimeLimit,
                    rolling: !!config.flow[promoFlow]?.params?.participationLimit,
                    calendarDay:
                        !!config.flow[promoFlow]?.params
                            ?.participationLimitCalendarDatesLimit,
                },
                alwaysWinLimits: {
                    ...initialValues.tempParams.alwaysWinLimits,
                    prizeOptions: prizeOptions,
                },
                ...(promoFlow === 'instantWin' &&
                {
                    winningLimitsPerTierFlag: config.flow[promoFlow]?.params?.winningLimitsPerTier ? true : false,
                    ...getIWLimitsForConfig(config)
                }),
                startDateTime: config.configurationParameters?.configurationStartUtc,
                endDateTime: config.configurationParameters?.configurationEndUtc,
            },
            flow: {
                ...config.flow,
                [promoFlow]: {
                    ...config.flow[promoFlow],
                    params: {
                        ...config.flow[promoFlow]?.params,
                    },

                    checkerLambdas: (promoFlow === 'instantWin' ? validateCheckerLambdas(config, promoFlow) : config.flow[promoFlow]?.checkerLambdas)
                },
            },
            participationLimits: {
                participationLimit:
                    config.flow[promoFlow]?.params?.participationLimit || 1,
                participationLimitTime:
                    config.flow[promoFlow]?.params?.participationLimitTime || 1,
                participationLimitTimeZone:
                    (config.flow[promoFlow]?.params?.participationLimitTimeZone &&
                        timezones[
                        config.flow[promoFlow]?.params?.participationLimitTimeZone
                        ]) ||
                    (config.configurationParameters?.configurationDatesTimezone &&
                        timezones[
                        config.configurationParameters?.configurationDatesTimezone
                        ]) ||
                    timezones[moment.tz.guess()] ||
                    '',
                participationLimitCalendarDatesRange: {
                    startDate:
                        config.flow[promoFlow]?.params
                            ?.participationLimitCalendarDatesRange?.startDate ||
                        config.configurationParameters?.configurationStartUtc ||
                        parseInt(moment().format('x')),
                    endDate:
                        config.flow[promoFlow]?.params
                            ?.participationLimitCalendarDatesRange?.endDate ||
                        config.configurationParameters?.configurationEndUtc ||
                        parseInt(moment().add(1, 'days').format('x')),
                },
                participationLimitMultipleChecks:
                    !!config.flow[promoFlow]?.params?.participationLimitMultipleChecks,
                participationLifetimeLimit:
                    config.flow[promoFlow]?.params?.participationLifetimeLimit || 1,
                participationLimitStartEndDatesRange: {
                    startDate:
                        config.flow[promoFlow]?.params
                            ?.participationLimitStartEndDatesRange?.startDate ||
                        config.configurationParameters?.configurationStartUtc ||
                        parseInt(moment().format('x')),
                    endDate:
                        config.flow[promoFlow]?.params
                            ?.participationLimitStartEndDatesRange?.endDate ||
                        config.configurationParameters?.configurationEndUtc ||
                        parseInt(moment().add(1, 'days').format('x')),
                },
                participationLimitCalendarDatesLimit:
                    config.flow[promoFlow]?.params
                        ?.participationLimitCalendarDatesLimit || 1,
                participationLimitCalendarDatesTimeZone:
                    (config.flow[promoFlow]?.params
                        ?.participationLimitCalendarDatesTimeZone &&
                        timezones[
                        config.flow[promoFlow]?.params
                            ?.participationLimitCalendarDatesTimeZone
                        ]) ||
                    (config.configurationParameters?.configurationDatesTimezone &&
                        timezones[
                        config.configurationParameters?.configurationDatesTimezone
                        ]) ||
                    timezones[moment.tz.guess()] ||
                    '',
                minAge: config.flow[promoFlow]?.params?.minAge || 12,
            },
            alwaysWinLimits: {
                alwaysWinPrizeRatios: prizeRatios,
                ratioWinning: config.flow[promoFlow]?.params?.ratioWinning,
            },
            selectedDateLimitOption:
                moment(config.configurationParameters?.configurationStartUtc).isSame(
                    config.flow[promoFlow]?.params?.participationLimitStartEndDatesRange
                        ?.startDate
                ) &&
                    moment(config.configurationParameters?.configurationEndUtc).isSame(
                        config.flow[promoFlow]?.params?.participationLimitStartEndDatesRange
                            ?.endDate
                    )
                    ? 'useConfigStartEndDates'
                    : 'participationLimitStartEndDatesRange',
        };
        setInitialState(updatedState);
    }, [config, promoFlow, prizeOptions, prizeRatios]);

    return {
        initialState
    }
};

export default useLimitsInitialState;
