import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {ListAssignedPromotions} from './ListAssignedPromotions';
import SearchBar from './../../components/searchBarComponent/SearchBarComponent';
import {Button, Box} from '@mui/material';
import './AssignedPromotions.css';

const searchBarDataComponents = {
    helperText: "Search for specific promotion",
    label: "Search by Name,ID,etc...",
    variant: "filled",
    size: "small",
    wrapperClass: 'search-bar-form-control',
    textFieldClass: 'search-bar-assign-promotions-component'
}
const AssignedPromotionsInfo = ({campaignData}: any) => {
    const [addPromoDisabled, setAddPromoDisabled] = useState(false);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        setAddPromoDisabled(moment(campaignData.promotion_end_utc) < moment() || campaignData.archived)
    }, [campaignData])

    const inputHandler = (e: any) => {
        const lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };
    return (
        <Box className='componentWrap'>
            <Box className='assign-promotions-wrapper'>
                <Button variant="outlined" className={`add-new-promo-button ${addPromoDisabled ? 'disabled' : ''}`} component={Link} to={`/assignPromotion/${campaignData.promotion_id}`} disabled={addPromoDisabled}>Add New Promotion</Button>
                <SearchBar data={searchBarDataComponents} inputHandler={inputHandler}/>
            </Box>
            <ListAssignedPromotions input={inputText} data={campaignData} createDisabled={addPromoDisabled}/>
        </Box>
    );
}

export { AssignedPromotionsInfo }


