import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Notification } from '../../common/Notification/Notification';
import CurrencyConfiguratorComponent from '../../components/currencyComponents/CurrencyConfiguratorComponent';
import { getErrorMessageFromError, makeUniqID } from './helpers/currency-helpers';
import { CurrencyTableItem } from '../../types/componentTypes/CurrencyTypes';
import { getAndGroupCurrencies, removeIconFromCurrency } from './services/api-calls';
import {
    defaultNotifState,
    getGeneralCurrencyErrorMessage,
    NotificationMessages,
} from '../../constants/currency-constants';

const CurrencyConfiguratorPage = ({ isEdit }: { isEdit: boolean }) => {
    const navigate = useNavigate();

    // #region - state slices
    const [notifState, setNotifState] = useState(defaultNotifState);
    const [fileLocation, setFileLocation] = useState('');
    const [showSummaryScreen, setShowSummaryScreen] = useState(false);
    const [currencyId, setCurrencyId] = useState(makeUniqID);
    const [pageHasBeenModified, setPageHasBeenModified] = useState(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [previousFileHasBeenDeleted, setPreviousFileHasBeenDeleted] = useState(false);
    const [groupedCurrencyNames, setGroupedCurrencyNames] = useState<{
        [key: string]: string[];
    }>({});
    // #endregion

    // #region - useEffects
    useEffect(() => {
        (async () => {
            try {
                const groupedCurr = await getAndGroupCurrencies();
                setGroupedCurrencyNames(groupedCurr);
            } catch (error) {
                console.error(error);
                setNotifState(NotificationMessages.errorGroupingCurr);
                setSaveButtonDisabled(true);
            }
        })();
    }, []);
    // #endregion

    // #region - handlers
    const handleAddButtonCallBack = async () => {
        setShowSummaryScreen(false);
        setCurrencyId(makeUniqID);
        const groupedCurr = await getAndGroupCurrencies();
        setGroupedCurrencyNames(groupedCurr);
    };

    const handleCancelAction = async (currency?: CurrencyTableItem) => {
        if (fileLocation && (previousFileHasBeenDeleted || pageHasBeenModified)) {
            try {
                await removeIconFromCurrency(fileLocation, currency);
                setNotifState(NotificationMessages.fileDeleted);
            } catch (error) {
                console.error(error);
                setNotifState(getGeneralCurrencyErrorMessage(getErrorMessageFromError(error)));
            } finally {
                setFileLocation('');
                setPageHasBeenModified(false);
            }
        }

        navigate(-1);
    };

    const handleDeleteIcon = async (currency?: CurrencyTableItem) => {
        if (fileLocation) {
            try {
                await removeIconFromCurrency(fileLocation, currency);
                setNotifState(NotificationMessages.fileDeleted);
            } catch (error) {
                console.error(error);
                setNotifState(getGeneralCurrencyErrorMessage(getErrorMessageFromError(error)));
            } finally {
                setCancelButtonDisabled(true);
                setPageHasBeenModified(true);
                setPreviousFileHasBeenDeleted(true);
                setFileLocation('');
            }
        }
    };
    // #endregion

    return (
        <>
            <Notification notificationState={notifState} setNotificationState={setNotifState} />
            <Grid container alignItems='center' justifyContent={'center'} sx={{ marginTop: '2vh' }}>
                <CurrencyConfiguratorComponent
                    props={{
                        handlers: {
                            handleDeleteIcon,
                            handleAddButtonCallBack,
                            handleCancelAction,
                        },
                        actions: {
                            setCurrencyCreated: setShowSummaryScreen,
                            setNotificationState: setNotifState,
                            setCancelButtonDisabled,
                            setSaveButtonDisabled,
                            setPageHasBeenModified,
                            setFileLocation,
                            setCurrencyId,
                        },
                        variables: {
                            isEdit,
                            showSummaryScreen,
                            saveButtonDisabled,
                            previousFileHasBeenDeleted,
                            cancelButtonDisabled,
                            pageHasBeenModified,
                            fileLocation,
                            currencyId,
                            groupedCurrencyNames,
                        },
                    }}
                />
            </Grid>
        </>
    );
};

export default CurrencyConfiguratorPage;
