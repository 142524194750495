import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Container } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

interface props {
    earliestDate: string;
    latestDate: string;
    handleChange: any;
    customParams: any;
    valueToDisplay?: 'start' | 'end';
}
const DateTimePicker = ({ earliestDate, handleChange, customParams, latestDate, valueToDisplay }: props) => {
    const [minDate, setMinDate] = useState(moment());
    const [maxDate, setMaxDate] = useState(moment());
    const [dateValue, setDateValue] = useState(moment());

    useEffect(() => {
        if (earliestDate !== '' && latestDate !== '') {
            const initialMinDate = moment(earliestDate);
            setMinDate(initialMinDate);
            const initialMaxDate = moment(latestDate);
            setMaxDate(initialMaxDate);
            valueToDisplay === 'start' ? setDateValue(initialMinDate) : setDateValue(initialMaxDate);
        }
    }, [earliestDate, latestDate, valueToDisplay]);

    return (
        <Container className='datetimeContainer' disableGutters>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en-US'>
                <DesktopDateTimePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={dateValue}
                    onChange={(dateValue) =>
                        handleChange({ date: dateValue?.format('YYYY-MM-DD HH:mm'), ...customParams })
                    }
                    ampm={false}
                    ampmInClock={false}
                    slots={{
                        openPickerIcon: ArrowDropDownIcon,
                    }}
                    slotProps={{
                        textField: {
                            size: 'small',
                            className: 'input-text',
                            inputProps: {
                                style: { fontSize: '13px' },
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        </Container>
    );
};
export { DateTimePicker };
