import { Grid, Button } from '@mui/material';
import { GenericButtonProps, AdditionalDetailsFormProps } from '../types/AdditionalDetailsTypes';

function ButtonComponent(props: GenericButtonProps<AdditionalDetailsFormProps['onSaveClickHandler']>) {
    const { buttonText, onClickHandler, disabledCondition, testId, variant, color, styles } = props;

    return (
        <Grid item>
            <Button
                sx={{ ...styles }}
                data-testid={testId}
                disabled={disabledCondition}
                variant={variant || 'outlined'}
                color={color || 'primary'}
                onClick={onClickHandler}>
                {buttonText}
            </Button>
        </Grid>
    );
}

export default ButtonComponent;
