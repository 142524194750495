import { winningMomentsRef, prizesRef } from '../../../../constants/helpful-link';

export const headers = ['Prize Name', 'Start Date', 'End Date'];

export const helpfulLinks = [
    {
        link: winningMomentsRef.WM_GENERATION,
        text: 'Winning moments generation'
    },
    {
        link: winningMomentsRef.WM_EXPIRATION,
        text: 'Winning moments expiration'
    },
    {
        link: prizesRef.VOUCHER_TIMESTAMP,
        text: 'Prize voucher timestamp'
    },
    {
        link: winningMomentsRef.WM_CONFIGURE,
        text: 'How to configure Winning Moments (Wiki and Video)'
    }
]