import { collectAndGetRef, commonLinks, instantWinRef, prizeDrawRef } from "../../../../constants/helpful-link";
import { PromoMechanic, PromoVariation } from "../../../../types/componentTypes/editPromotion";

export const helpfulLinks = {
    [PromoMechanic.InstantWin]: [
        {
            link: instantWinRef.IW_WINNING_MOMENTS,
            text: PromoVariation.InstantWinWM
        },
        {
            link: instantWinRef.IW_WM_GUARANTE_PRIZE,
            text: PromoVariation.InstantWinWMGP
        },
        {
            link: instantWinRef.IW_ALWAYS_WIN,
            text: PromoVariation.InstantWinAW
        },
        {
            link: instantWinRef.IW_INTERRUPTED_FLOW,
            text: PromoVariation.InstantWinIF
        },
        {
            link: commonLinks.ADD_PROMO,
            text: 'How to add a promotion (Wiki and Video)'
        }
    ],
    [PromoMechanic.CollectAndGet]: [
        {
            link: collectAndGetRef.CG_LOYALTY,
            text: PromoMechanic.CollectAndGet
        },
        {
            link: collectAndGetRef.CG_AUTO_REDEEM,
            text: PromoVariation.CollectAndGetAuto
        },
        {
            link: collectAndGetRef.ADD_CG,
            text: 'How to add a Collect and Get promotion (Wiki and Video)'
        },
    ],
    [PromoMechanic.PromoEntry]: [
        {
            link: prizeDrawRef.PRIZE_DRAW,
            text: PromoMechanic.PromoEntry
        },
    ],
    [PromoMechanic.AutoRedeemCnG]: [
        {
            link: collectAndGetRef.CG_AUTO_REDEEM,
            text: PromoVariation.CollectAndGetAuto
        }
    ]
};
