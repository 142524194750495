import { Fragment, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableFooter, Typography, IconButton, TableSortLabel, Box, Link } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UploadVouchersModal } from '../../../components/modals/UploadVouchersModal';
import { getPrizesForConfig } from '../../../graphql/queries';
import { Notification } from '../../../common/Notification/Notification';
import { ConfigType } from '../../../types/configuration';
import { SortIcon } from './SortIcon';
import { defaultNotifState } from '../../../constants/currency-constants';
import { headers, helpfulLinks } from './constants/voucher-constants';

import './EditPromotionVouchers.css';
import '../CommonStyles.css';
const uniqid = require('uniqid');

interface EditVouchersProps {
    config: ConfigType,
}

interface prizeName {
    [key: string]: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
    ) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const EditPromotionVouchers = ({ config }: EditVouchersProps) => {
    const [prizes, setPrizes] = useState([{
        name: '{}', prize_id: "", total_amount: null, total_available: null, total_claimed: null,
        total_expired: null, total_removed: null, total_reserved: null, total_redeemed: null,
    }]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState<Order>('asc');
    const [open, setOpen] = useState(false);
    const [prizeId, setprizeId] = useState('');
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const handleOpen = (prizeId: string) => {
        setOpen(true);
        setprizeId(prizeId);
    }
    const orderBy = 'name';

    useEffect(() => {
        const retrieveVouchersData = async () => {
            const queryResult: any = await API.graphql({ query: getPrizesForConfig, variables: { configuration_id: config.configurationId } });
            const vouchersInfo = queryResult.data.getPrizesForConfig.items;
            return vouchersInfo;
        }

        retrieveVouchersData()
            .then(data => {
                setPrizes(data);
            })
            .catch(e => {
                console.error('Error while retrieving vouchers info', e)
            })
    }, [config])

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prizes.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCopy = (prize: any) => {
        navigator.clipboard.writeText(prize.prize_id);
        setNotificationState({
            open:true,
            title:'Prize Id Copied!',
            content:"",
            level: "success"
        })
    }

    const handleRequestSort = (
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };

    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState}/>
            <UploadVouchersModal modalOpenState={open} handleClose={setOpen} prizeId={prizeId} configId={config.configurationId}/>
            <Grid item xs={9.7}>
                <Typography className='vouchersTitle' variant='h3'>Vouchers</Typography>
                <TableContainer component={Paper} className='vouchersContainer'>
                    <Table className='vouchersTable'>
                        <TableHead>
                            <TableRow className='voucherHeader'>
                                {headers.map((header, i) =>
                                    <Fragment key={i}>
                                        {header === 'Prize Name'
                                            ? <TableCell key={i}>
                                                <TableSortLabel
                                                    active={orderBy === 'name'}
                                                    direction={orderBy === 'name' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('name')}
                                                    IconComponent={() => <SortIcon order={order} />}
                                                >
                                                    <Typography variant='body2'>{header}</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            : <TableCell key={i}><Typography variant='body2'>{header}</Typography></TableCell>}
                                    </Fragment>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? prizes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy))
                                : prizes
                            ).map((prize) => (
                                <TableRow
                                    className='voucherRow'
                                    key={uniqid()}
                                >
                                    <TableCell align="left">
                                        <Typography variant='body1' className="prizeName">
                                            {Object.values(JSON.parse(prize.name) as prizeName)[0]}
                                        </Typography>
                                        <Typography variant='body2' className='prizeIdTitle'>
                                            Prize ID: <span className="prizeIdText"> {prize.prize_id}
                                                <IconButton onClick={() => handleCopy(prize)}>
                                                    <ContentCopyIcon className="copyPrizeId" />
                                                </IconButton>
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">{prize.total_available ? prize.total_available : 0}</TableCell>
                                    <TableCell align="center">{prize.total_reserved ? prize.total_reserved : 0}</TableCell>
                                    <TableCell align="center">{prize.total_claimed ? prize.total_claimed : 0}</TableCell>
                                    <TableCell align="center">{prize.total_redeemed ? prize.total_redeemed : 0}</TableCell>
                                    <TableCell align="center">{prize.total_expired ? prize.total_expired : 0}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton aria-label='uploadVouchers' onClick={() => handleOpen(prize.prize_id)}>
                                            <UploadFileIcon className='uploadVouchersIcon' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow key={uniqid()} style={{ height: 50 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    className='tablePagination'
                                    classes={{ menuItem: 'paginationMenu' }}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    count={prizes.length}
                                    colSpan={3}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    sx={{ borderBottom: 0 }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <>
                    <Typography variant='h5' sx={{ padding: '0 10px' }}>Helpful Links</Typography>
                    <Box className='helpfulLinks'>
                        {helpfulLinks.map((helpfulLink, i) =>
                            <Link key={i} target='_blank' href={helpfulLink.link}>{helpfulLink.text}</Link>
                        )}
                    </Box>
                </>
            </Grid>
        </>
    )
}

export { EditPromotionVouchers };
