import { Typography } from "@mui/material";
import { AllowedTextAlignments } from "../../constants/currency-constants";

const ErrorComponent = ({
    inputText,
    alignment,
}: {
    inputText: string;
    alignment: AllowedTextAlignments;
}) => {
    return (
        <Typography
            sx={{ color: "red", fontStyle: "bold" }}
            variant={"caption"}
            align={alignment}
        >
            {inputText}
        </Typography>
    );
};

export default ErrorComponent;
