import { API } from 'aws-amplify';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getCurrencyTable } from '../graphql/queries';
import { GetCurrencyTableResult } from "../types/componentTypes/CurrencyTypes";

/**
 * Returns currencies from the table
 * @returns The currencies items
 */
const getCurrenciesData = async () => {
    try {
        const currenciesData: { [key: string]: any } = await API.graphql({ query: getCurrencyTable }) as GraphQLResult<GetCurrencyTableResult>;
        return currenciesData.data.getCurrencyTable.items;
    } catch (e) {
        console.log(e);
    }
};

/**
 * Filter currencies by config currencies ids
 * @param currencies currencies objects
 * @param configCurrencies currencies ids saved in the config
 * @returns Filtered currencies objects
 */
const filterCurrenciesByConfigIds = (currencies, configCurrencies) => {
    return currencies.filter(currency => configCurrencies.includes(currency.currency_id));
}

/**
 * Get currencies ids
 * @param currencies currencies objects
 * @returns Array of currencies ids
 */
const getCurrenciesIds = (currencies) => {
    return currencies.map(((obj) => obj.currency_id)) || []
}

/**
 * Get currencies names
 * @param currencies currenciescurrencies objects
 * @returns Array of currencies names
 */
const getCurrenciesNames = (currencies) => {
    return currencies.map(((obj) => obj.name)) || []
}

export {
    getCurrenciesData,
    filterCurrenciesByConfigIds,
    getCurrenciesIds,
    getCurrenciesNames,
};
