import * as yup from 'yup';

interface TestContextExtended {
    [key: string]: any;
}

const alphaNumericRegex = /^[a-zA-Z0-9]+$/

function allocationRulesValidationSchema() {
    return yup.object({
        program_id: yup.string()
            .trim('This field cannot contain white spaces')
            .strict(true)
            .nullable()
            .test(
                'programidtest',
                'Program id must contain alphanumeric string with 26 characters.',
                (value) => {
                    if (!value || !(value.match(alphaNumericRegex)) || value.length !== 26) {
                        return false;
                    }
                    return true;
                }
            ),
        lot_id: yup.string()
            .trim('This field cannot contain white spaces')
            .strict(true)
            .nullable()
            .test(
                'lotidtest',
                'Lot id must contain alphanumeric string with 26 characters.',
                (value, testContext) => {
                    const { from } = testContext as yup.TestContext & TestContextExtended;
                    if (from[0].value.tempParams.ruleFor === 'lotId') {
                        if (!value || !(value.match(alphaNumericRegex)) || value.length !== 26) {
                            return false;
                        }
                        return true;
                    }
                    return true
                }
            ),
        campaign_id: yup.string()
            .trim('This field cannot contain white spaces')
            .strict(true)
            .nullable()
            .test(
                'campaignidtest',
                'Campaign id must contain alphanumeric string with 26 characters.',
                (value, testContext) => {
                    const { from } = testContext as yup.TestContext & TestContextExtended;
                    if (from[0].value.tempParams.ruleFor === 'campaignId') {
                        if (!value || !(value.match(alphaNumericRegex)) || value.length !== 26) {
                            return false;
                        }
                        return true;
                    }
                    return true
                }
            ),
        currency_id: yup.string()
            .nullable()
            .matches(/^[a-zA-Z0-9@]+$/, 'This field cannot contain white spaces and special characters')
            .test(
                'currencyidtest',
                'Currency id has to be provided and can contain only alphanumeric values',
                (value) => {
                    if (!value || !value.match(alphaNumericRegex)) {
                        return false
                    }
                    return true
                }
            ),
        amount: yup.string()
            .nullable()
            .matches(/^[a-zA-Z0-9@]+$/, 'This field cannot contain white spaces and special characters')
            .test(
                'amounttest',
                'Amount has to be provided and can contain only positive number',
                (value) => {
                    if (!value || (!(Number.isInteger(Number(value))) || Number(value) <= 0)) {
                        return false
                    }
                    return true
                }
            ),
        validity: yup.string()
            .nullable()
            .matches(/^[a-zA-Z0-9@]+$/, 'This field cannot contain white spaces and special characters')
            .test(
                'validitytest',
                'Valitidy can contain only positive number',
                (value) => {
                    if (value && (!(Number.isInteger(Number(value))) || Number(value) < 0)) {
                        return false
                    }
                    return true
                }
            )
    })
}

export { allocationRulesValidationSchema }
