import { InputAdornment, FormControl } from '@mui/material';
import SearchRounded from '@mui/icons-material/SearchRounded';
import './SearchBarComponent.css';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';


export default function SearchBar({data, inputHandler}:any) {

    return (
        <FormControl className={data.wrapperClass}>
        <TextFieldWrapper helperText={data.helperText} label={data.label} variant={data.variant} size={data.size} className={data.textFieldClass}
            InputProps={{
                disableUnderline: true,
                color: "success",
                endAdornment: (
                    <InputAdornment position='end'>
                    <SearchRounded/>
                    </InputAdornment>
                ),
            }}
            onChange={inputHandler}
        />
        </FormControl>
    );
}

