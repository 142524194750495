import { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { getConfig } from '../../../../utils/s3FileUtils';
import { EditCurrenciesState } from '../../../../types/componentTypes/editPromotion';
import { ConfigType } from '../../../../types/configuration';
import { notificationType } from '../../../../types/notifications';
import { getGeneralCurrencyErrorMessage } from '../../../../constants/currency-constants';
import { getErrorMessageFromError } from '../../../Currencies/helpers/currency-helpers';
import { getCurrencies } from '../../../Currencies/services/api-calls';
import { CurrencyTableItem } from '../../../../types/componentTypes/CurrencyTypes';

type IUpdateConfigWithUpdatedCurrenciesProps = {
    mechanic: string;
    fileName: string;
    setPromoFlow: Dispatch<SetStateAction<string>>;
    setCurrentConfigCurrencies: Dispatch<SetStateAction<string[]>>;
    setInitialState: Dispatch<SetStateAction<EditCurrenciesState>>;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
};

const useGetCurrencyTabParams = ({
    mechanic,
    fileName,
    setPromoFlow,
    setInitialState,
    setNotificationState,
    setCurrentConfigCurrencies,
}: IUpdateConfigWithUpdatedCurrenciesProps): {
    isLoading: boolean;
    refetchCurrencies: () => Promise<CurrencyTableItem[]>;
} => {
    const [getData, setGetData] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (getData && !isLoading) {
                setGetData(false);
                setIsLoading(true);

                try {
                    const { componentState, promoFlow, previousCurrencies } = await makeComponentParams({
                        fileName,
                        mechanic,
                        setNotificationState,
                    });

                    setInitialState(componentState);
                    setCurrentConfigCurrencies(previousCurrencies);
                    setPromoFlow(promoFlow);
                } catch (error) {
                    console.error(error);
                    setNotificationState(getGeneralCurrencyErrorMessage(getErrorMessageFromError(error)));
                } finally {
                    setIsLoading(false);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData]);

    return {
        isLoading: getData,
        refetchCurrencies: async () => {
            return await getCurrenciesList();
        },
    };
};

async function makeComponentParams(params: {
    fileName: string;
    mechanic: string;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
}) {
    const [config, currencies] = await Promise.allSettled([getConfig(params.fileName), getCurrenciesList()]);

    if (config.status === 'rejected' || currencies.status === 'rejected') {
        params.setNotificationState(getGeneralCurrencyErrorMessage(getErrorMessageFromError('Failed to get data')));
        return;
    }

    const promoFlow = getPromoFlow(config.value);
    const componentState = createComponentState(config.value, promoFlow, currencies.value, params.mechanic);
    const previousCurrencies = config.value.configurationParameters.currencies || [];

    return {
        componentState,
        previousCurrencies,
        promoFlow,
    };
}

async function getCurrenciesList() {
    return getCurrencies(true);
}

function createComponentState(
    config: ConfigType,
    promoFlow: string,
    currencies: CurrencyTableItem[],
    mechanic: string
): EditCurrenciesState {
    if (!promoFlow) return;

    return {
        ...config,
        params: {
            reduceAmount: config.flow[promoFlow]?.params?.reduceAmount || [{ currencyId: '', amount: 0 }],
        },
        flowLambdas: config.flow[promoFlow]?.flowLambdas || [''],
        configurationParameters: {
            ...config.configurationParameters,
            currencies: config.configurationParameters?.currencies || [''],
            ...(config.configurationParameters?.country && {
                country: config.configurationParameters?.country,
            }),
        },
        tempParams: {
            availableCurrencies: currencies || [{ country: '', name: '', currency_id: '' }],
            useCurrency:
                config.configurationParameters?.currencies &&
                    config.configurationParameters?.currencies.length > 0 &&
                    (promoFlow === 'instantWin' || promoFlow === 'promoEntry')
                    ? true
                    : false,
            flowOption: mechanic,
            currencyNames:
                config.flow[promoFlow]?.params?.reduceAmount && config.flow[promoFlow]?.params?.reduceAmount.length > 0
                    ? getCurrencyName(config.flow[promoFlow]?.params?.reduceAmount, currencies)
                    : [''],
            currencyValidity: !!config.configurationParameters?.validity,
            validityDays: config.configurationParameters?.validity
                ? Object.values(config.configurationParameters?.validity)[0]
                : 1,
            totalCurrencyAccumulated: !!config.configurationParameters?.additionalInformation?.totalCurrencyAccumulated,
        },
    };
}

function getPromoFlow(config: ConfigType) {
    return config.flow?.autoRedeemCnG
        ? 'autoRedeemCnG'
        : config.flow?.redeemPincodeForCurrencies
            ? 'redeemPincodeForCurrencies'
            : config.flow?.instantWin
                ? 'instantWin'
                : 'promoEntry';
}

function getCurrencyName(values: Array<{ currencyId: string; amount: number }>, data: CurrencyTableItem[]) {
    const names: string[] = [];
    values.forEach((currencyObject: { currencyId: string; amount: number }) => {
        const name = data
            .filter((currency: { currency_id: string }) => currency.currency_id === currencyObject.currencyId)
            .map(({ name }: any) => name);
        names.push(name[0]);
    });
    if (names.length > 0) return names;
    return [''];
}

export default useGetCurrencyTabParams;
