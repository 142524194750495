import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getIn } from 'formik';
import './SwitchComponent.css';
import { Typography } from '@mui/material';
import { SwitchProps } from '../../types/componentTypes/switchComponent';

const SwitchButton = ({
    formik,
    formikValue,
    label,
    formStyle,
    customAction,
    disableToggle
}: SwitchProps) => {
    const value = getIn(formik.values, formikValue)

    const handleChange = () => {
        const switchedValue = !value
        formik.setFieldValue(formikValue, !value)

        if(customAction) {
            customAction(switchedValue)
        }
    }

    return (
        <FormGroup className={formStyle}>
            <FormControlLabel
                control={
                    <Switch
                        className='root'
                        checked={value}
                        onChange={handleChange}
                        size="medium"
                        disabled={disableToggle}
                    />
                }
                label={<Typography variant='body1'>{label}</Typography>}
                />
        </FormGroup>
    );
}

export { SwitchButton }
