export interface TooltipProps {
    text: string,
    tooltip: string | any,
    textVariant: "h1" | "h2" | "h3" | "h4" | "h6" | "body1" | "body2" | "subtitle1",
    iconSize?: 'medium' | 'small' | 'inherit',
    styleName?: string,
    placement?: ToolTipPlacement,
    dataTestId?: string,
}

export enum ToolTipPlacement {
    Bottom = 'bottom',
    BottomStart = 'bottom-start',
    BottomEnd = 'bottom-end',
    Left = 'left',
    LeftEnd ='left-end',
    LeftStart = 'left-start',
    Right = 'right',
    RightEnd ='right-end',
    RightStart = 'right-start',
    Top = 'top',
    TopEnd = 'top-end',
    TopStart = 'top-start',
}
