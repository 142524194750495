import { limitsRef } from "../../../../constants/helpful-link";
import { PromoMechanic } from "../../../../types/componentTypes/editPromotion";


export const helpfulLinks = {
    [PromoMechanic.InstantWin]: [
        {
            link: limitsRef.LIMITS,
            text: 'Limits',
        },
        {
            link: limitsRef.PARTICIPATION_LIMITS,
            text: 'Participation Limits',
        },
        {
            link: limitsRef.WINNING_LIMITS,
            text: 'Winning Limits',
        }
    ],
    [PromoMechanic.PromoEntry]: [
        {
            link: limitsRef.LIMITS,
            text: 'Limits',
        },
        {
            link: limitsRef.PARTICIPATION_LIMITS,
            text: 'Participation Limits',
        },
    ],
};