import { Modal, Button, Typography, Box } from '@mui/material'
import * as yup from 'yup';
import { Formik } from 'formik';
import { InputNumberSpinner } from '../inputComponents/InputNumberSpinner';
import { TooltipText } from "../inputComponents/TooltipText";
import './Modal.css'


interface AddToPrizeTiersProps {
    modalOpenState: boolean,
    listToInsertTo: Array<any>,
    handleClose(modalState: boolean): void,
}

function AddToPrizeTiers({ modalOpenState, listToInsertTo, handleClose }: AddToPrizeTiersProps) {
    const addTierValidationSchema = yup.object({
        tier: yup
            .number()
            .required('Field is required')
            .max(11, '11 tiers maximum can be added per promotion')
    });

    return (
        <div>
            <Formik
                initialValues={{ tier: '5' }}
                validationSchema={addTierValidationSchema}
                onSubmit={async (values, actions) => {
                    try {
                        if (listToInsertTo.includes(values.tier)) {
                            actions.setFieldError('tier', 'Such option is already present in the list!')
                        } else {
                            const selectedTier = parseInt(values.tier);
                            const lastTier = 5;
                            const newTiers = Array.from({length: selectedTier-lastTier}, (_, n) => lastTier+n+1);
                            listToInsertTo.push(...newTiers);
                            handleClose(false);
                        }
                    } catch (e) {
                        console.error("Failed to insert data with: ", e);
                    }
                }}
            >
                {formik => (
                        <Modal
                            open={modalOpenState}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className='modal_main'>
                                <Box className='body'>
                                    <Typography id="modal-modal-title" variant="h6">Configure Tiers</Typography>
                                </Box>
                                <TooltipText styleName='tier-subheader' text='Number of Tiers' textVariant='body1' tooltip="The number of tiers can be increased if more group of prizes is required. This change will be reflected in the promotion profile. For example, if 7 is selected, every prize under this promotion can be included in one of the 7 tiers." iconSize='medium' />
                                <InputNumberSpinner customClass='tier-input' fieldName={'tier'} incrementValue={1} minValue={5} maxValue={11}></InputNumberSpinner>
                                <Box sx={{ mt: 5 }}>
                                    <Button onClick={formik.submitForm} className='button'>ADD</Button>
                                    <Button onClick={() => handleClose(false)} className='button'>CANCEL</Button>
                                </Box>
                            </Box>
                        </Modal>
                )}
            </Formik>
        </div>
    )
}

export { AddToPrizeTiers }
