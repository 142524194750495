import { useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    Link
} from '@mui/material'
import { Formik } from 'formik';
import moment from 'moment';
import { campaignValidationSchema, addNewExperienceValidationSchema } from '../../pages/CreateCampaign/campaignValidationSchema'
import { AllocateDates } from '../selectComponents/AllocateDates';
import SaveIcon from '@mui/icons-material/Save';
import { API, graphqlOperation } from 'aws-amplify';
import { updatePromotionsTable } from '../../graphql/mutations';
import { usePrompt } from '../../common/PromptOnChanges/promptHelper';
import { Notification } from '../../common/Notification/Notification';
import { MultipleSelectOptions } from '../selectComponents/MultipleSelectOptions';
import { AddToListModal } from '../modals/AddToListModal';
import { experiencesList, promotionMarketList, marketBrands } from '../../constants/lists';
import './GeneralCampaignInfo.css';
import { createCampaignState } from '../../pages/CreateCampaign/CreateCampaignPage';
import { DialogBox } from '../../common/Dialog/DialogBox';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
import { defaultNotifState } from '../../constants/currency-constants';
import { TooltipText } from '../inputComponents/TooltipText';
import { ToolTipPlacement } from '../../types/componentTypes/tooltip';
const validationSchema = campaignValidationSchema();

interface GeneralCampaignInfoProps {
    campaignData: UpdateCampaignState
}

interface UpdateCampaignState extends createCampaignState {
    configurations: Array<String>,
    last_modified: number | undefined
}
const newExperienceSchema = addNewExperienceValidationSchema();

function GeneralCampaignInfo({ campaignData }: GeneralCampaignInfoProps) {
    const [open, setOpen] = useState(false);
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [openConfirm, setOpenConfirm] = useState(false)


    const initialValues = {
        ...campaignData,
        promotion_start_utc: campaignData.promotion_start_utc,
        promotion_end_utc: campaignData.promotion_end_utc,
        promotion_dateRange: [campaignData.promotion_start_utc, campaignData.promotion_end_utc],
        promotion_market: typeof campaignData.promotion_market === 'string' ? campaignData.promotion_market.replace(/[[\]]/g, "").split(",") : campaignData.promotion_market,
        promotion_brand: campaignData.promotion_brand ? [...campaignData.promotion_brand] : []
    };

    const submissionFunc = async (values: any) => {
        try {
            let submitValues = { ...values } as object as UpdateCampaignState;
            delete submitValues.promotion_dateRange;
            submitValues = { ...submitValues, last_modified: moment().toDate().getTime() }
            await API.graphql(graphqlOperation(updatePromotionsTable, { input: submitValues }));
            setNotificationState({
                open: true,
                title: 'Campaign data saved successfully',
                content: 'Campaign Changes were saved!',
                level: "success"
            })
        } catch (e) {
            console.error("Failed to update data with: ", e);
            setNotificationState({
                open: true,
                title: 'Error',
                content: 'Campaign update failed',
                level: "error"
            })
        }
    }
    usePrompt(
        "This will discard all the changes. Are you sure?",
    );

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={(values) => { submissionFunc(values) }}>
            {formik =>
                <Box>
                    <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
                    <form onSubmit={formik.handleSubmit} >
                        <Box className="componentWrap">
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography variant="h5">
                                        Campaign Details
                                    </Typography>

                                    <Box className="textFieldWrap">
                                        <TextFieldWrapper
                                            fullWidth id="promo-name-outlined-basic"
                                            label="Campaign Name"
                                            name="promotion_name"
                                            variant="outlined"
                                            value={formik.values.promotion_name}
                                            error={formik.touched.promotion_name && Boolean(formik.errors.promotion_name)}
                                            helperText={formik.touched.promotion_name && formik.errors.promotion_name}
                                            onChange={formik.handleChange}
                                        />
                                    </Box>
                                    <Box className="textFieldWrap">
                                        <TextFieldWrapper
                                            fullWidth id="promo-full-name-outlined-basic"
                                            label={
                                                <TooltipText
                                                    text='Campaign Full Name'
                                                    textVariant='body1'
                                                    tooltip={<>
                                                    You can validate the Campaign Full Name in the Data Dictionary Experience Tool which you can download here -
                                                    <Link className='tooltip-link' href={'https://groups.coca-cola.com/sites/HarmonyHub'} target='_blank'>
                                                        Harmony Hub - Home (coca-cola.com)
                                                    </Link>
                                                    </>}
                                                    placement={ToolTipPlacement.RightEnd}
                                            />}
                                            name="promotion_fullName"
                                            variant="outlined"
                                            value={formik.values.promotion_fullName}
                                            error={formik.touched.promotion_fullName && Boolean(formik.errors.promotion_fullName)}
                                            helperText={formik.touched.promotion_fullName && formik.errors.promotion_fullName}
                                            onChange={formik.handleChange}
                                        />
                                    </Box>
                                    <Box className="textFieldWrap">
                                        <TextFieldWrapper
                                            fullWidth id="promo-author-outlined-basic"
                                            label="Author"
                                            name="promotion_author"
                                            value={formik.values.promotion_author || ''}
                                            variant="outlined"
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box className="textFieldWrap">
                                        <TextFieldWrapper
                                            fullWidth id="promo-owner-outlined-basic"
                                            label="Owner"
                                            name="promotion_owner"
                                            variant="outlined"
                                            value={formik.values.promotion_owner}
                                            margin="dense"
                                            className="textField"
                                            error={formik.touched.promotion_owner && Boolean(formik.errors.promotion_owner)}
                                            helperText={formik.touched.promotion_owner && formik.errors.promotion_owner}
                                            onChange={formik.handleChange}
                                        />
                                    </Box>

                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography variant="h5">
                                            Experience(s)
                                        </Typography>
                                        <MultipleSelectOptions
                                            formik={formik}
                                            listOfOptions={experiencesList}
                                            formikFieldName="digital_experience"
                                            inputLabel="Select Experience(s)*"
                                            enableAdditional={true}
                                            additionalLabel="+ Add New Experience"
                                            handleOpen={() => setOpen(true)}
                                            modalComponent={<AddToListModal formikState={formik} formikFieldName="digital_experience" value='option_name' schema={newExperienceSchema} header="+ Add New Experience" modalOpenState={open} listToInsertTo={experiencesList} handleClose={setOpen} />}
                                        />
                                    </Box>
                                    <Box className="marketsBox">
                                        <Typography variant="h5">
                                            Market(s)
                                        </Typography>
                                        <MultipleSelectOptions
                                            formik={formik}
                                            listOfOptions={promotionMarketList}
                                            formikFieldName="promotion_market"
                                            inputLabel="Select Market(s)*"
                                            enableAdditional={false}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="h5">
                                            Timeline
                                        </Typography>
                                        <AllocateDates
                                            description='Start & End Date'
                                            datetime={true}
                                            formikStartDate={'promotion_start_utc'}
                                            formikEndDate={'promotion_end_utc'}
                                            formikDateRange={'promotion_dateRange'}
                                            formikTimezoneValue={'configurationParameters.configurationDatesTimezone'}
                                            containerSpacing={2}
                                            startEndDateColumns={6}
                                            timeZoneColumns={12}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h5">
                                        Brand(s)
                                    </Typography>
                                    <Box className="brandsSelectWrapper">
                                        <MultipleSelectOptions
                                            formik={formik}
                                            listOfOptions={marketBrands}
                                            formikFieldName="promotion_brand"
                                            inputLabel="Select Brand(s)"
                                            enableAdditional={false}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Button variant="contained" className="saveButton" startIcon={<SaveIcon />} onClick={()=> setOpenConfirm(true)}>Save Changes</Button>
                            <DialogBox
                                dialogContent={'This will save promotion changes. Are you sure?'}
                                isOpen={openConfirm}
                                dialogCloseHandler={() => setOpenConfirm(false)}
                                actionButtonLabels={['CANCEL', 'SAVE']}
                                actionButtonHandlers={[() => setOpenConfirm(false), () => {formik.submitForm(); setOpenConfirm(false)}]}
                            />
                        </Box>
                    </form>
                </Box>
            }
        </Formik>
    )
}

export { GeneralCampaignInfo }
