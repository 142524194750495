import {
    Box,
    Typography,
    Link,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { CheckboxComponent } from '../../components/inputComponents/CheckboxComponent';
import { InputNumberSpinner } from '../../components/inputComponents/InputNumberSpinner';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { AddToPrizeTiers } from '../../components/modals/AddToPrizeTiers';
import { WinningLimitsProps } from '../../types/componentTypes/prizeComponentTypes';

function WinningLimits({ formik, tiersList }: WinningLimitsProps) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleWinningLimit = (e: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('redemption_limit', e.target.checked ? 1 : null);
    };

    return (
        <Box className='prize-box-main'>
            <Typography variant='h4' gutterBottom>
                Limits
            </Typography>
            <Typography variant='body1' gutterBottom>
                {formik.values.tempParams.mechanic === 'iw'
                    ? 'Specify the individual winning limit for this prize, or skip it and enter the tier number if you want to group prizes based on some criteria. Later on, you can set the winning limit per tier in the promotion profile'
                    : 'Specify the maximum number of times the prize can be claimed'}
            </Typography>
            <Box className='prize-box-content'>
                <CheckboxComponent
                    fieldName={'tempParams.winningLimit'}
                    label={
                        formik.values.tempParams.mechanic === 'iw'
                            ? 'Apply Winning Limit (Optional)'
                            : 'Redemption Limit (Optional)'
                    }
                    customAction={handleWinningLimit}
                />
                {formik.values.tempParams.winningLimit && (
                    <InputNumberSpinner
                        fieldName={'redemption_limit'}
                        incrementValue={1}
                        label={'Wining Limit'}
                        minValue={1}
                    />
                )}
                {formik.values.tempParams.mechanic === 'iw' && (
                    <>
                        <TooltipText
                            styleName='prize-type-title'
                            text='Select Prize Tier'
                            textVariant='body1'
                            tooltip='Select the tier number if you want to group prizes by some criteria. For example, if 2 out of 20 prizes are valuable, they can be grouped in 1st Tier, then the winning limit can be applied to the whole tier.
                            Select No Tier if there is no need to group prizes'
                            iconSize='medium'
                        />
                        <Typography variant='body1'>
                            Need more tiers? <Link onClick={handleOpen}>Configure tiers</Link>
                        </Typography>
                        <AddToPrizeTiers
                            modalOpenState={open}
                            listToInsertTo={tiersList}
                            handleClose={setOpen}
                        />
                        <ToggleButtonGroup
                            exclusive
                            className='button-group'
                            size='small'
                            value={formik.values.tier}
                            onChange={(e, value) => formik.setFieldValue('tier', value)}>
                            {tiersList
                                .sort((a, b) => a - b)
                                .map(
                                    (value, index) =>
                                        (value === 0 && (
                                            <ToggleButton
                                                className='button-group-selected'
                                                key={index}
                                                value={0}>
                                                No Tier
                                            </ToggleButton>
                                        )) ||
                                        (value > 0 && (
                                            <ToggleButton
                                                className='button-group-selected'
                                                key={index}
                                                value={value}>
                                                Tier #{value}
                                            </ToggleButton>
                                        ))
                                )}
                        </ToggleButtonGroup>
                    </>
                )}
            </Box>
        </Box>
    );
}

export { WinningLimits };
