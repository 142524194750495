import { Box, Typography, Link } from '@mui/material';
import { helpfulLinks } from '../constants/HelpfulLinks';
import type { Mechanic } from '../types/AdditionalDetailsTypes';

function HelpfulLinks({ mechanic }: { mechanic: Mechanic }) {
    return (
        <>
            <Typography variant='h3'>Helpful Links</Typography>
            <Box className='helpfulLinks'>
                {helpfulLinks[mechanic]?.map((helpfulLink: { link: string; text: string }, i: number) => (
                    <Link key={i} target='_blank' href={helpfulLink.link} rel='noopener noreferrer'>
                        {helpfulLink.text}
                    </Link>
                ))}
            </Box>
        </>
    );
}

export default HelpfulLinks;
