import { Breadcrumbs,Link, Typography } from '@mui/material';

interface SubNavProps { 
    linksMapper:Array<linksObj>,
    breadcrumbsStyle?: string,
    textVariant?: 'body1' | 'body2' | 'caption' | 'h5'| 'overline',
    linkClass?: string
}

type linksObj = { 
    name:string,
    href?:string
}

function SubNav ({linksMapper, breadcrumbsStyle, textVariant, linkClass}:SubNavProps) { 

    return (   
        <Breadcrumbs aria-label="breadcrumb" className={breadcrumbsStyle}>
            {linksMapper.map((linkObj,i) =>{ 
                if(i+1===linksMapper.length) return (<Typography variant={textVariant ? textVariant: 'body2'} key={i}>{linkObj.name}</Typography>)
                return(
                    <Link className={linkClass} key={i} underline="always" color="inherit" href={linkObj.href}>
                        {linkObj.name}
                    </Link>
                )
            })}
        </Breadcrumbs>
 
    )
}

export { SubNav }