// Enums
enum AllowedVPCStage {
    'production',
    'prod',
    'gamma',
    'production_it',
}

enum MixCodeURIs {
    RegularPincodesUri = 'https://mixcodes.coke.com/pcservices/rest/v7/pincodes/',
    ViralPincodesUri = 'https://mixcodes.coke.com/pcservices/rest/v7/viralcodes/',
    InternalRegularPincodesUri = 'https://mixcodes-internal.coke.com/pcservices/rest/v7/pincodes/',
    InternalViralPincodesUri = 'https://mixcodes-internal.coke.com/pcservices/rest/v7/viralcodes/',
}

// Interfaces
export interface MixCodesProps {
    formik: any;
    isEdit?: boolean;
    mainBoxClass?: string;
}

export interface IRenderMixCodeComponents {
    mixCodesConfigurationParam: MixCodesConfigurationParams
    items: unknown[];
    titleText: string;
}
//Types

export type MixCodesConfigurationParams = 'campaignIds' | 'lotIds';

// getters
export const getMixCodeURI = {
    uriVPCMap: {
        Pincodes: MixCodeURIs.InternalRegularPincodesUri,
        'Viral Codes': MixCodeURIs.InternalViralPincodesUri,
    },
    uriMap: {
        Pincodes: MixCodeURIs.RegularPincodesUri,
        'Viral Codes': MixCodeURIs.ViralPincodesUri,
    },
};

export const usingVPC = () => (process.env.REACT_APP_AWS_STAGE in AllowedVPCStage ? true : false);
