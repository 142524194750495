/**
 * Splits a configuration property path string into its root key and form state key components.
 *
 * @param configPropPath - The configuration property path string to split.
 * @returns An array containing the root key and form state key.
 */
export function splitConfigPropKey(configPropPath: string) {
    const configPropPathArray = configPropPath.split('.');
    const rootKey = configPropPathArray.at(0);
    const formStateKey = configPropPathArray.at(-1);

    return [rootKey, formStateKey];
}

/**
 * Splits a configuration property path string into an array of individual keys.
 *
 * @param configPropPath - The configuration property path string to split.
 * @returns An array of individual keys from the configuration property path.
 */
export function splitConfigPropPath(configPropPath: string) {
    return configPropPath.split(',').map((key) => key.trim());
}

/**
 * Checks if the provided string value is a valid number.
 * @param value - The string value to check.
 * @returns `true` if the value is a valid number, `false` otherwise.
 */
export function isValidNumberString(value: string) {
    return !isNaN(parseFloat(value));
}
