import { Typography, Box, Button } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { INotFoundProps } from "../../types/componentTypes/notFound";
import './NotFoundPage.css';

function NotFoundPage({ titleText }: INotFoundProps) {
    return (
        <Box>
        <ErrorOutlineIcon className='error-icon'></ErrorOutlineIcon>
        <Typography variant='h3' gutterBottom>{titleText || 'Page Not Found'}</Typography>
        <Typography variant='body1'>Please go back to home page</Typography>
        <Button className='home-button' variant="contained" color="primary" startIcon={<ArrowBackIosIcon />} component={Link} to='/'>Home</Button>
        </Box>
    )
}

export { NotFoundPage }
