import { useEffect, useState } from 'react';
import { filterCurrencyItems, getErrorMessageFromError } from '../helpers/currency-helpers';
import { CurrencyTableItem, PagesData } from '../../../types/componentTypes/CurrencyTypes';
import { getGeneralCurrencyErrorMessage } from '../../../constants/currency-constants';
import { notificationType } from '../../../types/notifications';

const useMakePaginatedCurrencies = ({
    currencies,
    searchBarValue,
    selectedMarket,
    setNotifState,
    setPageData,
    setPageCount,
    setCurrentPage,
}: {
    currencies: CurrencyTableItem[];
    searchBarValue: string;
    selectedMarket: string;
    setNotifState: React.Dispatch<React.SetStateAction<notificationType>>;
    setPageData: React.Dispatch<React.SetStateAction<PagesData>>;
    setPageCount: React.Dispatch<React.SetStateAction<number>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!currencies.length || isLoading) return;

        try {
            setIsLoading(true);
            const { chunkedData, pages } = filterCurrencyItems({
                searchBarValue,
                selectedMarket,
                currencies,
            });

            setPageData(chunkedData);
            setPageCount(parseInt(pages));
        } catch (error) {
            console.error(error);
            setNotifState(getGeneralCurrencyErrorMessage(getErrorMessageFromError(error)));
        } finally {
            setIsLoading(false);
            setCurrentPage('1');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies, searchBarValue, selectedMarket]);

    return { isPaginationInProgress: isLoading };
};

export default useMakePaginatedCurrencies;
