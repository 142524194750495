import { Box, FormControl, FormGroup } from '@mui/material';
import { FormikContextType } from 'formik';
import { CheckboxComponent } from '../../../components/inputComponents/CheckboxComponent';
import { InputNumberSpinner } from '../../../components/inputComponents/InputNumberSpinner';
import { limitsStateValues } from '../../../types/componentTypes/limits';
import { TierLimits } from './TierLimits';

interface props {
    formik: FormikContextType<limitsStateValues>;
    tiersList: number[];
}

const WinningLimits = ({ formik, tiersList }: props) => {
    const handlePerTierChecker = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (!checked) {
            delete formik.values.flow.instantWin?.params?.winningLimitsPerTier;
        } else {
            if (!formik.values.flow.instantWin?.params?.winningLimitsPerTier) {
                formik.setFieldValue('flow.instantWin.params', {
                    ...formik.values.flow.instantWin.params,
                    winningLimitsPerTier: {},
                });
            }
        }
    };

    return (
        <Box>
            <FormControl className='form-control-participation-limits'>
                <FormGroup>
                    <CheckboxComponent
                        fieldName={'tempParams.instantWinLimitPerConfiguration'}
                        label='Lifetime'
                    />
                    {formik.values.tempParams.instantWinLimitPerConfiguration && (
                        <InputNumberSpinner
                            fieldName={'tempParams.instantWinLimitPerConfiguration'}
                            label={'Limit'}
                            incrementValue={1}
                            minValue={1}
                        />
                    )}
                </FormGroup>
                <FormGroup>
                    <CheckboxComponent
                        fieldName={'tempParams.instantWinLimitPerTimeInterval'}
                        label='Per Hour'
                    />
                    {formik.values.tempParams.instantWinLimitPerTimeInterval && (
                        <>
                            <InputNumberSpinner
                                fieldName={'tempParams.instantWinLimitPerTimeInterval'}
                                label={'Limit'}
                                incrementValue={1}
                                minValue={1}
                            />
                            <InputNumberSpinner
                                fieldName={'tempParams.instantWinLimitPerTimeIntervalValue'}
                                label={'Limit window in hours'}
                                incrementValue={1}
                                minValue={1}
                            />
                        </>
                    )}
                </FormGroup>
                <FormGroup>
                    <CheckboxComponent
                        fieldName={'tempParams.winningLimitsPerTierFlag'}
                        label='Per Prize Tier'
                        customAction={handlePerTierChecker}
                    />
                    {formik.values.tempParams.winningLimitsPerTierFlag && (
                        <TierLimits formik={formik} tiersList={tiersList} />
                    )}
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export { WinningLimits };
