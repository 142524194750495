import * as React from 'react';
import { Box, Typography, Button, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';
import { StepSlider } from '../../common/StepSlider/StepSlider';
import './Navigation.css';

interface TabPanelProps {
    index: number;
    value: number;
    headers?: headersTypes;
    subHeaders?: headersTypes;
}

type headersTypes = {
    [key: number]: string
}

function TabPanel({ index, value, headers, subHeaders }: TabPanelProps): JSX.Element {

    return (
        <Box className={`${value !== index && 'hidden'}`}>
            {value === index &&
                <Box className='tabWrapper'>
                    {headers && subHeaders && <Box className='textAllignLeft'>
                        <Typography variant='h4' className='textStyling'>{headers[index]}</Typography>
                        <Typography variant='body1' className='textStyling'>{subHeaders[index]}</Typography>
                    </Box>}
                    <StepSlider imagesFolderName="homeImages" currentStep={index} className={index === 0 ? 'navigationFirstStepImage' : 'navigationStepImages'} containerClass='homeImageContainer'></StepSlider>
                    {index === 0 && <Box className='textAllignLeft'>
                        <Button component={Link} to="/createCampaign" variant="contained" size="medium" className='navButton'>Create</Button>
                        <Button component={Link} to="/listCampaigns" size="medium" className='navButton'>List Campaigns</Button>
                    </Box>}
                </Box>}
        </Box>
    );
}
interface NavBarProps {
    tabs: Array<string>,
    headers?: headersTypes,
    subHeaders?: headersTypes
}

function NavBar({ tabs, headers, subHeaders }: NavBarProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Tabs TabIndicatorProps={{ style: { height: "4px", textTransform: 'uppercase' } }} value={value} onChange={handleChange}>
                {tabs.map((t, i) => <Tab label={t} key={i} />)}
            </Tabs>
            {(headers === undefined || subHeaders === undefined)
                ? <TabPanel index={value} value={value} ></TabPanel>
                : <TabPanel index={value} value={value} headers={headers} subHeaders={subHeaders}></TabPanel>
            }
        </Box>
    );
}

export default NavBar
