import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import './ProgressBar.css';

export default function LinearIndeterminate() {
  return (
    <Box className='linearProgressbar'>
      <LinearProgress />
    </Box>
  );
}
