import { Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "../../pages/AssignPromotion/AssignPromotionPage.css";
import { CurrencyPaths } from "../../constants/currency-constants";
import { SummaryViewProps } from "../../types/componentTypes/CurrencyTypes";
import TextFieldWrapper from "../TextFieldWrapper/TextFieldWrapper";

const SummaryView = ({ props }: SummaryViewProps) => {
    const {
        titleText,
        subTitleText,
        copyButtonLabel,
        copyButtonText,
        copyButtonValue,
        addButtonConfig,
        viewButtonConfig,
        descriptionText,
    } = props;

    return (
        <Grid
            container
            alignItems="center"
            justifyContent={"center"}
            sx={{ marginTop: "2vh", width: "400px" }}
            direction="column"
        >
            <Typography variant="h4" gutterBottom>
                {titleText}
            </Typography>

            <CheckCircleOutlineIcon className="save-promo-icon" />

            <Typography variant="h4" gutterBottom>
                {subTitleText}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {descriptionText}
            </Typography>

            <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ marginTop: "3vh" }}
            >
                {addButtonConfig && addButtonConfig.buttonCallBack && (
                    <Button
                        id={addButtonConfig.buttonId}
                        variant="contained"
                        color="primary"
                        className="save-promo-buttons"
                        onClick={addButtonConfig.buttonCallBack}
                    >
                        {addButtonConfig.buttonText}
                    </Button>
                )}

                {addButtonConfig && addButtonConfig.buttonPath && (
                    <Button
                        id={addButtonConfig.buttonId}
                        variant="contained"
                        color="primary"
                        className="save-promo-buttons"
                        component={Link}
                        to={CurrencyPaths.CreateCurrency}
                    >
                        {addButtonConfig.buttonText}
                    </Button>
                )}

                {viewButtonConfig.buttonCallBack && (
                    <Button
                        id={viewButtonConfig.buttonId}
                        variant="outlined"
                        color="primary"
                        className="save-promo-buttons"
                        onClick={viewButtonConfig.buttonCallBack}
                    >
                        {viewButtonConfig.buttonText}
                    </Button>
                )}

                {viewButtonConfig.buttonPath && (
                    <Button
                        id={viewButtonConfig.buttonId}
                        variant="outlined"
                        color="primary"
                        className="save-promo-buttons"
                        component={Link}
                        to={viewButtonConfig.buttonPath}
                    >
                        {viewButtonConfig.buttonText}
                    </Button>
                )}
            </Grid>

            <Grid
                container
                justifyContent="center"
                direction="row"
                alignItems="center"
                sx={{ marginTop: "5vh" }}
            >
                <Grid item>
                    <TextFieldWrapper
                        name="copyButtonLabel"
                        variant="outlined"
                        label={copyButtonLabel}
                        value={copyButtonValue}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: "form-control" }}
                    />
                </Grid>

                <Grid item>
                    <Button
                        sx={{ height: "40px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigator.clipboard.writeText(copyButtonValue);
                        }}
                    >
                        {copyButtonText}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SummaryView;
