import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import './DialogBox.css'

interface DialogProps {
    dialogContent: string
    isOpen: boolean
    dialogCloseHandler: any
    actionButtonLabels: string[]
    actionButtonHandlers: any
    buttonDisabled?: boolean,
    timeoutBeforeSubmit?:boolean
}

function DialogBox({ isOpen, actionButtonLabels, actionButtonHandlers, buttonDisabled, dialogCloseHandler, dialogContent, timeoutBeforeSubmit=true }: DialogProps) {
    const [buttonDisabledState, setButtonDisabledState] = useState(buttonDisabled)
    useEffect(() => {
        if(timeoutBeforeSubmit) {
            setButtonDisabledState(buttonDisabled)
            if (buttonDisabled) {
                setTimeout(() => (setButtonDisabledState(false)), 3000);
            }
        }
    }, [buttonDisabled, timeoutBeforeSubmit])
    return (
        <StyledEngineProvider injectFirst>
            <Dialog
                className="dialogBoxStyles"
                maxWidth='xs'
                data-testid="DialogBox"
                PaperProps={{ className: "dialogPaperStyles" }}
                open={isOpen}
                onClose={() => { dialogCloseHandler() }}>
                <DialogContent>
                    <Typography className="dialogueContentTextStyles" gutterBottom>
                        {dialogContent}
                    </Typography>
                </DialogContent>
                <DialogActions >
                    {actionButtonLabels.map((element: any, index: any) =>
                        <Button className="dialogueButtonStyles" disabled={buttonDisabledState} key={index} onClick={() => { actionButtonHandlers[index]() }} autoFocus >
                            {actionButtonLabels[index]}
                        </Button>)}
                </DialogActions>
            </Dialog>
        </StyledEngineProvider>
    )
}

export { DialogBox };
