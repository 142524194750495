import { ConfigType, PrizeDrawConfig } from '../../../../types/configuration';
import { Mechanic } from '../types/AdditionalDetailsTypes';

export class TagManager {
    /**
     * Retrieves the tags associated with the given configuration and promotion flow.
     *
     * @param config - The configuration object, which can be either a `ConfigType` or a `PrizeDrawConfig`.
     * @param promoFlow - The promotion flow to use.
     * @param mechanic - The mechanic associated with the configuration.
     * @returns An object containing the user-defined tags and the default tags.
     */
    getTags(config: ConfigType | PrizeDrawConfig, promoFlow: string, mechanic: Mechanic) {
        const { flow, configurationParameters } = config;
        const includesCurrencyReducer = flow[promoFlow]?.flowLambdas?.includes('currencyReducer');
        const includesBurnPincodes = flow[promoFlow]?.flowLambdas?.includes('burnPincodes');
        const configurationTags = configurationParameters?.additionalInformation?.tags || [];

        const defaultTags = this.getDefaultTags(mechanic, includesCurrencyReducer, includesBurnPincodes);
        const userDefinedTags = this.filterUserDefinedTags(
            configurationTags,
            defaultTags,
            includesCurrencyReducer,
            includesBurnPincodes
        );

        return { userDefinedTags, defaultTags };
    }

    /**
     * Generates a set of default tags based on the provided mechanic.
     *
     * @param mechanic - The mechanic type, such as 'Prize Draw (Lottery)', 'Collect & Get', or 'Instant Win'.
     * @param includesCurrencyReducer - A boolean indicating whether the 'instantwincost' tag should be included.
     * @param includesBurnPincodes - A boolean indicating whether the 'instantwinpe' tag should be included.
     * @returns A set of default tags for the given mechanic.
     */
    private getDefaultTags(
        mechanic: Mechanic,
        includesCurrencyReducer: boolean,
        includesBurnPincodes: boolean
    ): Set<string> {
        const defaultTags = new Set<string>();

        switch (mechanic) {
            case 'Prize Draw (Lottery)':
                defaultTags.add('prizedrawlottery');
                break;
            case 'Collect & Get':
                defaultTags.add('C&G PE');
                break;
            case 'Instant Win':
                includesCurrencyReducer && defaultTags.add('instantwincost');
                includesBurnPincodes && defaultTags.add('instantwinpe');
                break;
        }

        return defaultTags;
    }

    /**
     * Filters a list of configuration tags, removing any tags that are part of the default set of tags, as well as any tags related to currency reducers or burn pincodes if those features are not included.
     *
     * @param configurationTags - The list of configuration tags to filter.
     * @param defaultTags - The set of default tags that should be excluded.
     * @param includesCurrencyReducer - Whether the configuration includes a currency reducer.
     * @param includesBurnPincodes - Whether the configuration includes burn pincodes.
     * @returns A set of the filtered user-defined tags.
     */
    private filterUserDefinedTags(
        configurationTags: string[],
        defaultTags: Set<string>,
        includesCurrencyReducer: boolean,
        includesBurnPincodes: boolean
    ): Set<string> {
        const userDefinedTags = new Set<string>();

        if (!configurationTags || !configurationTags.length) return userDefinedTags;

        configurationTags
            .filter((tag) => !defaultTags.has(tag))
            .forEach((tag) => {
                if (!includesCurrencyReducer && tag.includes('instantwincost'))
                    return console.info('removing cost tag');

                if (!includesBurnPincodes && tag.includes('instantwinpe'))
                    return console.info('removing pincode entry tag');

                userDefinedTags.add(tag);
            });

        return userDefinedTags;
    }
}
