import AppBar from '@mui/material/AppBar';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CardMedia, CardActionArea, Card } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import "./Header.css";

type HeaderProps = {
    auth: CognitoUser | null
}

export interface CognitoUser {
    [key: string]: any
}

export interface ButtonState {
    [key: string]: any
}

export default function ButtonAppBar({ auth }: HeaderProps) {
    const [btnState, setBtnState] = useState<ButtonState>({'homeToggleOn': true});

    const headerButtonHandler = ({target}: React.BaseSyntheticEvent) => {
        const btn = target.text.toLowerCase();
        if (!(`${btn}ToggleOn` in btnState)) {
            setBtnState((prevState) => ({
                [`${btn}ToggleOn`]: !prevState[`${btn}ToggleOn`],
            }));
        }
    }

    const location = useLocation();
    const promotionId = location.pathname.replace(/^.*\/(.*)$/, "$1");
    const isAssignedPromotionPage = location.pathname.includes('assignPromotion');

    return (
        <StyledEngineProvider injectFirst>
            <Box>
                <AppBar className='headerWrapper'>
                    <Toolbar>
                        <Box className='profileSection'>
                            <Card className='cardMedia'>
                            <CardActionArea
                                component={Link}
                                to='/'
                            >
                            <CardMedia
                                className='profileIcon'
                                component="img"
                                image="/ngps-ss-logo.svg"
                                alt="Ngps Logo"
                            />
                            </CardActionArea>
                            </Card>
                            { !isAssignedPromotionPage &&
                                <>
                                    <Button component={Link} to="/" className={`headerButton ${btnState.homeToggleOn && location.pathname === '/' && 'toggledOn'}`} onClick={headerButtonHandler}>
                                        HOME
                                    </Button>

                                    <Button component={Link} to="/listCampaigns" className={`headerButton ${(btnState.campaignsToggleOn || location.pathname === '/listCampaigns') && 'toggledOn'}`} onClick={headerButtonHandler}>
                                        CAMPAIGNS
                                    </Button>

                                    <Button
                                        component={Link}
                                        to="/listCurrencies"
                                        className={`headerButton ${(btnState.currenciesToggleOn || location.pathname === '/listCurrencies') && 'toggledOn'}`}
                                        onClick={headerButtonHandler}
                                    >
                                        CURRENCIES
                                    </Button>
                                </>
                            }
                            { isAssignedPromotionPage &&
                            <Button component={Link} to={`/editCampaign/${promotionId}`} className={`headerButton ${btnState.campaignsToggleOn && 'toggledOn'}`} onClick={headerButtonHandler}>
                                Back to Campaign
                            </Button>
                            }
                        </Box>
                        {auth &&
                            <Box>
                                <Button className='profileButton'><AccountCircleIcon />{auth.email}</Button>
                                <Button className='profileButton' onClick={async () => await Auth.signOut()}><LogoutIcon />Logout</Button>
                            </Box>
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        </StyledEngineProvider>
    );
}
