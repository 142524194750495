const baseButtons = ['generalInfo', 'additionalDetails', 'currencies', 'mixcodes']
const peButtons = ['limits', 'participationExport'];
const iwButtons = ['limits', 'prizes', 'winningMoments', 'vouchers']
const cngButtons = ['prizes', 'allocationRules', 'vouchers']

export const getFlowTabButtons = (promotionMechanic: string) => {
    let flowButtons = [];

    switch (promotionMechanic) {
        case 'Instant Win':
            flowButtons = iwButtons;
            break;
        case 'Prize Draw (Lottery)':
            flowButtons = peButtons;
            break;
        case 'Collect & Get':
            flowButtons = cngButtons;
            break;
        case 'Auto Collect & Get':
            flowButtons = cngButtons;
            break;
        default:
            flowButtons = [];
    }

    return [...baseButtons, ...flowButtons];
}

export const flowTabButtonsMap = {
    generalInfo: {
        label: "General Info"
    },
    prizes: {
        label: "Prizes"
    },
    vouchers: {
        label: "Vouchers"
    },
    winningMoments: {
        label: "Winning Moments"
    },
    limits: {
        label: "Limits"
    },
    allocationRules: {
        label: "Allocation Rules"
    },
    mixcodes: {
        label: "MiX Codes"
    },
    currencies: {
        label: "Currencies"
    },
    participationExport: {
        label: "Participation Export"
    },
    additionalDetails: {
        label: "Additional Details"
    },
}
