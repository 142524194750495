import * as yup from 'yup';

interface TestContextExtended {
    [key: string]: any;
}

const limitsValidationSchema = yup.object().shape({
    participationLimits: yup.object().shape({
        minAge: yup.mixed()
            .test('minAgeTest',
                'Minimum age must be a positive integer from 1 to 120 only',
                (value, testContext) => {
                    const { from } = testContext as yup.TestContext & TestContextExtended;
                    const limit = from[1].value.tempParams.limits.minAge;
                    if (limit &&
                        (!(Number.isInteger(Number(value))) || (Number(value) <= 0 || Number(value) > 120))
                    ) {
                        return false;
                    }

                    return true;
                })
    }),
    alwaysWinLimits: yup.object().shape({
        ratiosSum: yup.mixed()
            .test('prizeRatiosSumTest', 'The sum of the “Ratio winning limit” of the Always Win prize(s) should be equal to 100%.',
                (value, testContext) => {
                    const { from } = testContext as yup.TestContext & TestContextExtended;
                    const alwaysWinLimits = from[1].value.alwaysWinLimits;
                    if (!alwaysWinLimits.ratioWinning) return true;

                    const sum = alwaysWinLimits.alwaysWinPrizeRatios.reduce((acc: number, item: { prizeId: string, ratio: number }) => (acc + Number(item.ratio)), 0);
                    if (sum !== 100) return false;

                    return true;
                }),
        alwaysWinPrizeRatios: yup.array().of(
            yup.object({
                prizeId: yup.mixed()
                    .test('prizeRatioSelectTest', 'Please select a prize(s) from the drop-down menu.',
                        (value, testContext) => {
                            const { from, path } = testContext as yup.TestContext & TestContextExtended;
                            const alwaysWinLimits = from[2].value.alwaysWinLimits;
                            if (!alwaysWinLimits.ratioWinning) return true;

                            const matches = path.match(/\[(.*?)\]/);
                            if (
                                matches
                                && alwaysWinLimits.alwaysWinPrizeRatios[matches[1]]?.ratio !== undefined
                                && alwaysWinLimits.alwaysWinPrizeRatios[matches[1]]?.prizeId === undefined
                            ) {
                                return false;
                            }

                            return true;
                        }),
                ratio: yup.mixed()
                    .test('prizeRatioInputTest', 'Please enter a positive number in the Ratio field.',
                        (value, testContext) => {
                            const { from, path } = testContext as yup.TestContext & TestContextExtended;
                            const alwaysWinLimits = from[2].value.alwaysWinLimits;
                            if (!alwaysWinLimits.ratioWinning) return true;

                            const matches = path.match(/\[(.*?)\]/);
                            const ratioVal = Number(alwaysWinLimits.alwaysWinPrizeRatios[matches[1]]?.ratio);

                            if (
                                matches
                                && alwaysWinLimits.alwaysWinPrizeRatios[matches[1]]?.prizeId !== undefined
                                && (isNaN(ratioVal) || ratioVal <= 0)
                            ) {
                                return false;
                            }

                            return true;
                        }),
            })
        )
    })
});

export { limitsValidationSchema }
