import { Typography, Box} from '@mui/material';
import './StatusBox.css';

interface StatusBoxProps {
    archived: boolean,
    numOfConfigs: number,
    text?:string|undefined,
    styleName?: string|undefined,
    align?: "right" | "left" | "inherit" | "center" | "justify" | undefined,
}

function StatusBox({archived, numOfConfigs, text, styleName, align="left"}:StatusBoxProps) {
    let status = numOfConfigs > 0 ? 'active' : 'draft';
    if(archived) status = 'archived';
    return (
        <Typography className={styleName} component="div" align={align}>
            {text} <Box className='statusBox'>{status}</Box>
        </Typography>
    )
}

export {StatusBox}
