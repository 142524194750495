import { Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './SortIcon.css'

interface props {
    order: string
}
const SortIcon = ({order}: props) => {
    let arrowUpColor = order === 'asc' ? '#A4AFB7' : 'black';
    let arrowDownColor = order === 'asc' ? 'black' : '#A4AFB7';

    return (
        <>
        <Box component='span' className='icon-wrapper'>
            <ArrowDropUpIcon className='arrowUp' sx={{color: arrowUpColor}}/>
            <ArrowDropDownIcon className='arrowDown' sx={{color: arrowDownColor}} />
        </Box>
      </>
    )
}

export { SortIcon }
