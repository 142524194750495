import { ChangeEvent } from 'react';
import { Box, Typography, Link, FormControl } from '@mui/material';
import { getIn } from 'formik';
import { TooltipText } from '../inputComponents/TooltipText';
import { InputNumberSpinner } from '../inputComponents/InputNumberSpinner';
import { AllocateDates } from '../selectComponents/AllocateDates';
import { RadioButton } from '../inputComponents/RadioButton';
import { InputComponent } from '../inputComponents/InputComponent';
import { CheckboxComponent } from '../inputComponents/CheckboxComponent';
import { limitsRef } from '../../constants/helpful-link';
import '../../pages/AssignPromotion/AssignPromotionPage.css';

interface ParticipationProps {
    formik?: any;
    useTitles?: boolean;
    limitsBoxClass?: string;
    formClass?: string;
    startEndDateColumns?: number;
    timeZoneColumns?: number;
    containerSpacing?: number;
}

function ParticipationLimits({
    formik,
    useTitles = true,
    limitsBoxClass,
    formClass,
    startEndDateColumns,
    timeZoneColumns,
    containerSpacing,
}: ParticipationProps) {
    const handleSelectedRollingLimit = (e: ChangeEvent<HTMLInputElement>) => {
        const configStartDate = getIn(formik.values, 'tempParams.startDateTime');
        const configEndDate = getIn(formik.values, 'tempParams.endDateTime');

        formik.setFieldValue(`participationLimits.participationLimitStartEndDatesRange['startDate']`, configStartDate);
        formik.setFieldValue(`participationLimits.participationLimitStartEndDatesRange['endDate']`, configEndDate);

        if (e.target.value === 'participationLimitStartEndDatesRange') {
            formik.setFieldValue('tempParams.limits.useRollingCustomDuration', true);
        } else {
            formik.setFieldValue('tempParams.limits.useRollingCustomDuration', false);
        }
    };

    const applyRollingPeriodToEntirePromotionByDefault = (e: ChangeEvent<HTMLInputElement>) => {
        const stringToPut = 'useConfigStartEndDates';
        formik.setFieldValue('selectedDateLimitOption', stringToPut);
        const configStartDate = getIn(formik.values, 'tempParams.startDateTime');
        const configEndDate = getIn(formik.values, 'tempParams.endDateTime');

        formik.setFieldValue(`participationLimits.participationLimitStartEndDatesRange['startDate']`, configStartDate);
        formik.setFieldValue(`participationLimits.participationLimitStartEndDatesRange['endDate']`, configEndDate);
    };

    const handleCalendarLimit = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (checked) {
            const configStartDate = getIn(formik.values, 'tempParams.startDateTime');
            const configEndDate = getIn(formik.values, 'tempParams.endDateTime');
            const configTimezone = getIn(formik.values, 'configurationParameters.configurationDatesTimezone');
            formik.setFieldValue('participationLimits.participationLimitCalendarDatesRange.startDate', configStartDate);
            formik.setFieldValue('participationLimits.participationLimitCalendarDatesRange.endDate', configEndDate);
            formik.setFieldValue('participationLimits.participationLimitCalendarDatesTimeZone', configTimezone);
        }
    };

    return (
        <Box data-testid={'participation-limits-box'} className='participation-limits-box'>
            {useTitles && (
                <>
                    <TooltipText
                        text='Participation Limits'
                        textVariant='h4'
                        tooltip={
                            <Typography variant='body2'>
                                <Link className='tooltip-link' href={limitsRef.PARTICIPATION_LIMITS} target='_blank'>
                                    Learn more
                                </Link>{' '}
                                about the participation limits.
                            </Typography>
                        }
                    />
                    <Typography className='typography-style' variant='body1'>
                        Specify the participation limits for this promotion
                    </Typography>{' '}
                </>
            )}
            <Box className={limitsBoxClass ? limitsBoxClass : 'limits-box'}>
                <FormControl className={formClass}>
                    <Box>
                        <CheckboxComponent fieldName={'tempParams.limits.lifetime'} label='Lifetime' />
                        {formik.values.tempParams.limits.lifetime && (
                            <Box>
                                <InputNumberSpinner
                                    fieldName={'participationLimits.participationLifetimeLimit'}
                                    label={'Limit'}
                                    incrementValue={1}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <CheckboxComponent
                            fieldName={'tempParams.limits.calendarDay'}
                            label='Per calendar day'
                            customAction={handleCalendarLimit}
                        />
                        {formik.values.tempParams.limits.calendarDay && (
                            <Box>
                                <InputNumberSpinner
                                    fieldName={'participationLimits.participationLimitCalendarDatesLimit'}
                                    label={'Limit'}
                                    incrementValue={1}
                                />
                                <div className='time-box-assigned-promotion'>
                                    <AllocateDates
                                        description={'Date Range'}
                                        datetime={true}
                                        formikStartDate={`participationLimits.participationLimitCalendarDatesRange['startDate']`}
                                        formikEndDate={`participationLimits.participationLimitCalendarDatesRange['endDate']`}
                                        startEndDateColumns={startEndDateColumns}
                                        showTimeZone={false}
                                        containerSpacing={containerSpacing}
                                    />
                                </div>
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <CheckboxComponent
                            customAction={applyRollingPeriodToEntirePromotionByDefault}
                            fieldName={'tempParams.limits.rolling'}
                            label='Per rolling period'
                        />
                        {formik.values.tempParams.limits.rolling && (
                            <Box>
                                <div className='participation-box-rolling-period'>
                                    <InputNumberSpinner
                                        fieldName={'participationLimits.participationLimit'}
                                        incrementValue={1}
                                        label={'Limit'}
                                    />
                                </div>
                                <TooltipText
                                    text='Rolling period (hours)'
                                    textVariant='body2'
                                    styleName='participation-limit-tooltip'
                                    iconSize='medium'
                                    tooltip='Rolling period starts counting after the first participation. The minimum value of the rolling period is 1 hour.'
                                />
                                <div className='participation-box-rolling-period'>
                                    <InputNumberSpinner
                                        fieldName={'participationLimits.participationLimitTime'}
                                        incrementValue={1}
                                    />
                                </div>
                                <RadioButton
                                    formik={formik}
                                    values={[
                                        {
                                            value: 'useConfigStartEndDates',
                                            label: 'Apply the rolling period limit to the entire promotion duration',
                                        },
                                        {
                                            value: 'participationLimitStartEndDatesRange',
                                            label: 'Apply the rolling period to a custom duration',
                                        },
                                    ]}
                                    radioGroupName={'selectedDateLimitOption'}
                                    customAction={handleSelectedRollingLimit}
                                />
                                {formik.values.selectedDateLimitOption === 'participationLimitStartEndDatesRange' && (
                                    <div className='time-box-assigned-promotion'>
                                        <AllocateDates
                                            description={'Date Range'}
                                            datetime={true}
                                            formikStartDate={`participationLimits.participationLimitStartEndDatesRange['startDate']`}
                                            formikEndDate={`participationLimits.participationLimitStartEndDatesRange['endDate']`}
                                            startEndDateColumns={startEndDateColumns}
                                            showTimeZone={false}
                                            containerSpacing={containerSpacing}
                                        />
                                    </div>
                                )}
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <CheckboxComponent fieldName={'tempParams.limits.minAge'} label='Minimum Age' />
                        {formik.values.tempParams.limits.minAge && (
                            <InputComponent
                                inputClassName='input-size'
                                formik={formik}
                                inputValue={getIn(formik.values, 'participationLimits.minAge')}
                                formikLabel={'participationLimits.minAge'}
                                text='Minimum Age Limit'
                                textVariant='body2'
                                label='Positive numbers only'
                            />
                        )}
                    </Box>
                </FormControl>
            </Box>
        </Box>
    );
}

export { ParticipationLimits };
