import { Box, Typography } from '@mui/material'
import { SelectOption } from '../selectComponents/SelectOption';
import { TooltipText } from '../inputComponents/TooltipText';
import { SelectLanguage } from './LanguageSelector';
import { SelectMarketLanguageProps } from '../../types/componentTypes/assignPromo';
import '../selectComponents/SelectOption.css'
import '../../App.css';
import '../../pages/AssignPromotion/AssignPromotionPage.css';

function SelectMarketLanguage({ formik,  markets}: SelectMarketLanguageProps) {

    return (
        <Box className='text-box'>
            <TooltipText text='Market/Language' textVariant="h4" tooltip="If there is a need to change the list of markets, you can go back to the campaign profile and open 'General Info' where the markets can be managed in case you have needed permissions." />
            <SelectOption formik={formik} formikValue={'configurationParameters.country'} optionsList={markets} description='Only one market can be selected per promotion. The list of markets can be managed in the campaign profile.' descriptionVariant='body1' inputLabel={'Select Market'} />
            <Box className='market-language-box'>
                <Typography variant='body1' gutterBottom> Only one language can be selected as a default per promotion. The list of languages is updated based on the selected market, but you can also open the full list of languages if needed.</Typography>
                <SelectLanguage formik={formik} countryValue={formik.values.configurationParameters.country} formikLanguageField={'configurationParameters.language'} selectedLanguage={formik.values.configurationParameters.language}/>
            </Box>
        </Box>
    )
}

export { SelectMarketLanguage }





