import { prizesRef } from '../../../../constants/helpful-link';

export const headers = ['Prize Name', 'Available', 'Reserved', 'Claimed', 'Redeemed', 'Expired', 'Upload'];
export const helpfulLinks = [
    {
        link: prizesRef.PRIZE_CATALOGUE,
        text: 'Prize Catalog(Wiki)'
    },
    {
        link: prizesRef.VOUCHER_STATUS,
        text: 'Prize voucher statuses'
    },
    {
        link: prizesRef.VOUCHER_TIMESTAMP,
        text: 'Prize voucher timestamp'

    },
    {
        link: prizesRef.UPLOAD_VOUCHERS,
        text: 'How to upload vouchers (Wiki and Video)'

    }
]