import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import './GridSkeleton.css';

interface GridProps {
    items?: number;
}

export default function GridSkeleton(props: GridProps) {
    const { items = 3 } = props;
    return (
        <Box className='skeleton'>
            <Grid container wrap="nowrap" className='skeletonGrid'>
                {Array.from(new Array(items)).map((item, index) => (
                    <Box key={index} className='skeletonBox'>
                        <Skeleton variant="rectangular" width={210} height={118} />
                        <Box className='skeletonBoxChild'>
                            <Skeleton animation={'wave'} />
                            <Skeleton animation={'wave'} width="60%" />
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    );
}
