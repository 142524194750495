// libs
import { useState, useRef, useEffect } from 'react';
import { isEqual as _isEqual } from 'lodash';

// custom components
import { AdditionalDetailsForm } from './AdditionalDetailsForm';

// custom functionality
import { AdditionalDetailsHelpers } from './utils/AdditionalDetailsHelpers';
import { pageErrorsMap } from './constants/AdditionalDetailsVars';
import { saveConfig } from '../../../utils/s3FileUtils';

// types
import type {
    AdditionalInfoProps,
    Mechanic,
    PageErrors,
    UpdateConfigPropsParams,
} from './types/AdditionalDetailsTypes';

// css
import '../CommonStyles.css';

const AdditionalDetails = ({
    setNotificationState,
    handleTabChange,
    mechanic,
    promoFlow,
    urlParams,
    config,
}: AdditionalInfoProps) => {
    const AdditionalInfoHelpersInstance = AdditionalDetailsHelpers.getInstance({
        config,
        promoFlow,
        mechanic: mechanic as Mechanic,
    });
    const configRef = useRef(config);
    const initFormStateRef = useRef(AdditionalInfoHelpersInstance.getInitialFormState());

    const [formState, setFormState] = useState(initFormStateRef.current);
    const [pageErrors, setPageErrors] = useState<PageErrors>(pageErrorsMap);
    const [formIsSaving, setFormIsSaving] = useState(false);

    const formIsDirty = !_isEqual(initFormStateRef.current, formState);

    useEffect(() => {
        if (!_isEqual(config, configRef.current)) {
            const updatedInitState = AdditionalInfoHelpersInstance.getInitialFormState();

            setFormState(updatedInitState);
            initFormStateRef.current = updatedInitState;
        }

        configRef.current = config;
    }, [AdditionalInfoHelpersInstance, config]);

    useEffect(() => {
        handleTabChange(formIsDirty);
    }, [formIsDirty, handleTabChange]);

    function updateStateValues(props: UpdateConfigPropsParams) {
        if (!props.actionType) return console.error('Action type is required');

        const updateProps = { setFormState, formState, ...props };
        AdditionalInfoHelpersInstance.handleStateUpdates(updateProps);
    }

    function handleDiscardChanges() {
        setFormState(initFormStateRef.current);
        setPageErrors(pageErrorsMap);

        AdditionalInfoHelpersInstance.discardChanges();
    }

    function removeEmpty(obj) {
        if (obj && typeof obj === 'object') {
          Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object') {
              removeEmpty(obj[key]);
            }

            if (
              (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) ||
              obj[key] === '' ||
              (Array.isArray(obj[key]) && obj[key].length === 0)
            ) {
              delete obj[key];
            }
          });
        }
        return obj;
    }

    async function onSaveClickHandler() {
        setFormIsSaving(true);

        try {
            const updatedConfig = removeEmpty(AdditionalInfoHelpersInstance.getUpdatedConfig());
            await saveConfig({
                submitData: updatedConfig,
                urlParams: urlParams,
                setNotificationState,
            });

            cleanupAfterSave();
        } catch (e) {
            console.error(e);
        } finally {
            setFormIsSaving(false);
        }
    }

    function cleanupAfterSave() {
        setNotificationState({
            open: true,
            title: 'Promotion updated successfully',
            content: 'Promotion Changes were saved!',
            level: 'success',
        });

        AdditionalInfoHelpersInstance.syncConfigUpdates();
        initFormStateRef.current = formState;
    }

    return (
        <AdditionalDetailsForm
            promoFlow={promoFlow}
            formIsDirty={formIsDirty}
            formIsSaving={formIsSaving}
            mechanic={mechanic as Mechanic}
            pageErrors={pageErrors}
            setPageErrors={setPageErrors}
            updateStateValues={updateStateValues}
            formState={formState}
            handleDiscardChanges={handleDiscardChanges}
            onSaveClickHandler={onSaveClickHandler}
        />
    );
};

export { AdditionalDetails };
