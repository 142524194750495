import { Button, Grid } from '@mui/material';
import { Notification } from '../../common/Notification/Notification';
import { useState } from 'react';
import './CopyInput.css'
import TextFieldWrapper from '../TextFieldWrapper/TextFieldWrapper';
import { defaultNotifState } from '../../constants/currency-constants';

interface CopyInputProps {
    inputValue: string,
    inputLabel: string,
    inputName: string,
    align?: string
}

function CopyInput({ inputValue, inputLabel, inputName, align = "right" }: CopyInputProps) {

    const [notificationState, setNotificationState] = useState(defaultNotifState);
    function copyToClipboard(value: string, inputLabel: string) {
        navigator.clipboard.writeText(value);
        setNotificationState({
            open: true,
            title: `${inputLabel} copied to clipboard`,
            content: "",
            level: "success"
        })
    }

    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            <Grid className='copy-input-grid' container={true} justifyContent={align}>
                <TextFieldWrapper
                    className='copy-input'
                    id="currency-details-outlined-basic"
                    label={inputLabel}
                    name={inputName}
                    variant="outlined"
                    value={inputValue}
                    sx={{ width: "300px", background: "white" }}
                    disabled
                />
                <Button className='copy-input-button' variant="contained" color="primary" sx={{ width: "150px" }} onClick={() => copyToClipboard(inputValue, inputLabel)}>COPY ID</Button>
            </Grid>
        </>
    )
}

export { CopyInput }
