export const PRIZE_IMAGE_PRIORITY = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5"
};
export const PRIZE_IMAGE_SIZES = {
    thumbnail: "Thumbnail",
    small: "Small",
    medium: "Medium",
    large: "Large"
};
export const PRIZE_IMAGE_RATIO = {
    vertical: "Vertical",
    horizontal: "Horizontal",
    square: "Square"
};

export const PRIZE_IMAGE_STATUS = {
    true: "Active",
    false: "Inactive"
};

export const defaultModalMetadataState = {
    open: false,
    url: ''
}

const transformObjectToOptionValues = (obj: Record<string, string>): {
    value: string;
    label: string;
}[] => Object.entries(obj).map(([key, value]) => ({
    value: key,
    label: value
}));

export const generateRadioButtonConfigs = (url: string, lang: string) => [
    {
        formLabel: 'Priority',
        values: transformObjectToOptionValues(PRIZE_IMAGE_PRIORITY),
        radioGroupName: `images_metadata.[${lang}].["${url}"].priority`,
        row: true
    },
    {
        formLabel: 'Size',
        values: transformObjectToOptionValues(PRIZE_IMAGE_SIZES),
        radioGroupName: `images_metadata[${lang}].["${url}"].size`,
    },
    {
        formLabel: 'Ratio',
        values: transformObjectToOptionValues(PRIZE_IMAGE_RATIO),
        radioGroupName: `images_metadata[${lang}].["${url}"].ratio`,
    },
    {
        formLabel: 'Image Status',
        values: transformObjectToOptionValues(PRIZE_IMAGE_STATUS),
        radioGroupName: `images_metadata[${lang}].["${url}"].activeStatus`,
    }
];
