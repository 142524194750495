import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { columnsMap } from './constants/winnersTable-constants';
import { WinnersTableProps} from '../../../types/componentTypes/ParticipationExportTypes';

const createHeaders = (data) => {
    const colArr = data.reduce((arr, row) => {
        return [...new Set([...arr, ...Object.keys(row)])];
    }, []);

    return columnsMap.map((col) => {
        return {
            ...col,
            isVisible: colArr.includes(col.accessor),
        };
    });
}

const WinnersTable = ({ data }: WinnersTableProps) => {
    const headers = createHeaders(data.winners);
    return (
        <TableContainer className="winners-table">
            <Table aria-label="winners-table">
                <TableHead>
                    <TableRow>
                        {headers.map((col, index) => (
                            col.isVisible &&
                            <TableCell
                                align='center'
                                key={index}>{col.header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.winners.map((row, index) => (
                        <TableRow key={index}>
                            {headers.map((col, colIndex) => (
                                col.isVisible && <TableCell align='left' key={colIndex}>{row[col.accessor]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default WinnersTable;
