import { useEffect, useState } from 'react';
import { Modal, Grid, Button, Box } from '@mui/material';
import { generateRadioButtonConfigs } from '../../constants/prize-constants';
import { RadioButton } from '../inputComponents/RadioButton';

const PrizeImageMetadataModal = ({ props }) => {

    const {
        showModal,
        language,
        url,
        formik,
        setModalState,
    } = props;

    const [radioButtonConfigs, setRadioButtonConfigs] = useState([]);

    const handleDiscardAndCloseModal = () => {
        const updatedData = { ...formik.values.images_metadata };
        if (Object.keys(updatedData).length) {
            delete updatedData[language][`${url}`];
            formik.setFieldValue(
                `images_metadata[${language}]`,
                updatedData[language]
            );
        }

        setModalState({open: false, index: null, url: '' });
    };


    const handleSaveModal = () => {
        const selectedPriority = formik.values.images_metadata[language][url].priority;
        formik.setFieldValue(
            `images_metadata[${language}]["${url}"].priority`,
            parseInt(selectedPriority)
          );

        formik.setFieldValue(
            `images_metadata[${language}].["${url}"].url`, url)
        setModalState({open: false, index: null, url: '' });
    };

    const handleCloseModal = () => {
        setModalState({open: false, index: null, url: '' });
    }

    useEffect(() => {
        setRadioButtonConfigs(generateRadioButtonConfigs(url, language));
    }, [url, language]);


    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
        >
            <Box className='modal_main'>
                <Grid container justifyContent='center' spacing={2} direction={'column'}>
                    {radioButtonConfigs.map((config, index) => (
                        <Grid item key={index}>
                            <RadioButton
                                formLabel={config.formLabel}
                                row={config.row || false}
                                formik={formik}
                                values={config.values}
                                radioGroupName={config.radioGroupName}
                            />
                        </Grid>
                    ))}
                    <Grid item>
                        <Button  variant="outlined" onClick={handleDiscardAndCloseModal}>
                            Discard
                        </Button>
                        <Button variant="contained" onClick={handleSaveModal}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default PrizeImageMetadataModal;
