import * as yup from 'yup';

interface TestContextExtended {
    [key: string]: any;
  }

function currenciesValidationSchema() {
    return yup.object({
        params: yup.object().shape({
            reduceAmount: yup.mixed().test('reduceAmountTest', 'Cost must be a positive number',
            (value, testContext) => {
                const {from} = testContext as yup.TestContext & TestContextExtended
                let result = true;
                value && value.forEach((element: {currencyId: string, amount: string})  => {
                    if (from[1].value.tempParams.useCurrency && (!(/^\d+$/.test(element.amount)) || (parseInt(element.amount) <= 0))) {
                        result = false
                    }
                })
                return result
            })
            .test('reduceAmountTest', 'Currency should be selected',
            (value, testContext) => {
                const {from} = testContext as yup.TestContext & TestContextExtended
                let result = true;
                value && value.forEach((element: {currencyId: string, amount: string})  => {
                    if (from[1].value.tempParams.useCurrency && !element.currencyId) {
                        result = false
                    }
                })
                return result
            }),
        })
    })
}

export { currenciesValidationSchema }
