import { SearchRounded } from "@mui/icons-material";
import {
    StyledEngineProvider,
    Container,
    Box,
    Breadcrumbs,
    Typography,
    Button,
    InputAdornment,
    Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PageHeaderComponentProps } from "../../types/componentTypes/CurrencyTypes";

import "./PageHeaderComponent.css";
import TextFieldWrapper from "../TextFieldWrapper/TextFieldWrapper";

const PageHeaderComponent = ({ props }: PageHeaderComponentProps) => {
    const {
        breadCrumbPath,
        breadCrumbText,
        labelText,
        createButtonPath,
        createButtonText,
        searchBarDisabled,
        searchFieldHelperText,
        searchFieldLabel,
        searchBarValue,
        handleSearchBarInput,
    } = props;
    return (
        <StyledEngineProvider injectFirst>
            <Container maxWidth="lg" margin-top="120" background-color="white">
                <Box
                    sx={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-around",
                    }}
                >
                    <div role="presentation">
                        <Breadcrumbs
                            className="breadCrumbsStyles"
                            aria-label="breadcrumb"
                        >
                            <Link
                                underline="hover"
                                fontSize="15px"
                                color="inherit"
                                href={`${breadCrumbPath}`}
                            >
                                {breadCrumbText}
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <Typography className="bigLabelStyles">
                        {labelText}
                    </Typography>
                    <Button
                        component={RouterLink}
                        to={`${createButtonPath}`}
                        variant="contained"
                        className="createNewItemButtonStyles"
                        data-testid='create-currency-button'
                    >
                        {createButtonText}
                    </Button>
                    <TextFieldWrapper
                        value={searchBarValue}
                        disabled={searchBarDisabled}
                        id="page-header-outlined-basic"
                        helperText={searchFieldHelperText}
                        label={searchFieldLabel}
                        variant="filled"
                        size={"small"}
                        className="searchBarStyles"
                        InputProps={{
                            style: { backgroundColor: "white" },
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(event) => {
                            handleSearchBarInput(event.target.value);
                        }}
                    />
                </Box>
            </Container>
        </StyledEngineProvider>
    );
};

export default PageHeaderComponent;
