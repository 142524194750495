import { API, graphqlOperation } from 'aws-amplify';
import { updateCurrencyAllocationRulesTable } from '../../graphql/mutations';
import { Modal, Button, Typography, Box } from '@mui/material';
import * as yup from 'yup';
import { Formik, getIn } from 'formik';
import moment from 'moment-timezone';
import { deleteAllocationRuleProps } from '../../types/componentTypes/allocationRules';
import { useEffect, useState } from 'react';
import { InputComponent } from '../inputComponents/InputComponent';
import './Modal.css';

const initialValues = {
    deletion_timestamp: moment().valueOf(),
    jira_ticket_id: null,
    rule_active: false,
    user_koid: null,
};

const deleteValidationSchema = yup.object({
    jira_ticket_id: yup
        .mixed()
        .test('ticketTest', 'Ticket id is required and can contain only alphanumeric values', (value) => {
            const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
            if (!value || !value.match(alphaNumericRegex)) {
                return false;
            }
            return true;
        }),
    user_koid: yup
        .mixed()
        .test('ticketTest', 'User KO ID is required and can contain only alphanumeric values', (value) => {
            const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
            if (!value || !value.match(alphaNumericRegex)) {
                return false;
            }
            return true;
        }),
});

function DeleteAllocationRule({ modalOpenState, rule, handleClose }: deleteAllocationRuleProps) {
    const [initialState, setInitialState] = useState(initialValues);

    useEffect(() => {
        const updatedState = {
            ...rule,
            ...initialValues,
        };
        setInitialState(updatedState);
    }, [rule]);

    return (
        <div>
            <Formik
                initialValues={initialState}
                enableReinitialize
                validationSchema={deleteValidationSchema}
                onSubmit={async (values) => {
                    try {
                        await API.graphql(graphqlOperation(updateCurrencyAllocationRulesTable, { input: values }));
                        handleClose();
                    } catch (e) {
                        console.error('Failed to delete rule with: ', e);
                    }
                }}>
                {(formik) => (
                    <Modal
                        open={modalOpenState}
                        onClose={handleClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'>
                        <Box className='modal_main'>
                            <Box className='body'>
                                <Typography id='modal-modal-title' variant='h5'>
                                    This will delete the allocation rule. Are you sure?
                                </Typography>
                            </Box>
                            <InputComponent
                                formik={formik}
                                inputValue={getIn(formik.values, 'user_koid')}
                                formikLabel={'user_koid'}
                                title='User KO ID'
                                titleVariant='body1'
                                label=''
                            />
                            <InputComponent
                                formik={formik}
                                inputValue={getIn(formik.values, 'jira_ticket_id')}
                                formikLabel={'jira_ticket_id'}
                                title='Ticket ID'
                                titleVariant='body1'
                                label=''
                            />
                            <Box className='delete-buttons-style'>
                                <Button
                                    onClick={() => {
                                        formik.submitForm();
                                    }}
                                    className='button'>
                                    DELETE
                                </Button>
                                <Button onClick={() => handleClose()} className='button'>
                                    CANCEL
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                )}
            </Formik>
        </div>
    );
}

export { DeleteAllocationRule };
