export const commonLinks = {
    PROMO_MECHANICS: ' https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10535/Promo-Mechanics',
    TERMS_AND_ACRONYMS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10655/NGPS-Definitions',
    ADD_PROMO: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5832/How-to-add-a-promotion-',
    ADD_RECAPTCHA: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5967/reCAPTCHA-setup',
}

export const instantWinRef = {
    IW_WINNING_MOMENTS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10558/Instant-Win-(Winning-Moments)',
    IW_WM_GUARANTE_PRIZE: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10560/Instant-Win-(Winning-Moments-Guaranteed-Prize)',
    IW_ALWAYS_WIN: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10596/Instant-Win-(Always-Win)',
    IW_INTERRUPTED_FLOW: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10598/Instant-Win-(Winning-Moments-Interrupted-Flow)',
}

export const collectAndGetRef = {
    CG_LOYALTY: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10604/Collect-Get-(Loyalty)',
    CG_AUTO_REDEEM: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10610/Auto-Collect-Get-(Loyalty)',
    ADD_CG: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5958/Collect-and-Get-(Loyalty)-mechanic'
}

export const prizeDrawRef = {
    PRIZE_DRAW: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10635/Prize-Draw-(Offline-Draw)'
}

export const limitsRef = {
    PARTICIPATION_LIMITS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5965/Participation-Limits',
    LIMITS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10672/Limits',
    WINNING_LIMITS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5959/Winning-Limits',
}

export const prizesRef = {
    PRIZE_CATALOGUE: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10657/Prize-Catalog',
    CREATE_PRIZE: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5834/How-to-create-a-prize-',
    VOUCHER_STATUS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/11351/Prize-Voucher-Statuses',
    VOUCHER_TIMESTAMP: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/11355/Prize-Voucher-Timestamp',
    UPLOAD_VOUCHERS: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5971/Winning-Moments',
    VOUCHER_HISTORY: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/11587/Query-Expiration-Wallet-By-User'
}

export const winningMomentsRef = {
    WM_GENERATION: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/11356/Winning-Moments-Generation',
    WM_EXPIRATION: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/11358/Winning-Moments-Expiration',
    WM_CONFIGURE: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5971/Winning-Moments'
}

export const mixCodes = {
    MIX_CODE_INTEGRATION: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10643/MixCodes-NGPS-Integration',
}

export const currenciesRef = {
    BURN_PINCODE: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/3486/Collect-Get',
    CURRENCY_REDUCER: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/3634/Currency-Reducer',
    CURRENCY_CHECKER: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/3636/Currency-Checker',
    CREATE_CURRENCY: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5169/How-to-create-currencies-',
    ALLOCATION_RULES: 'https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10663/Currency-Allocation-Rules',
    USER_WALLET: "https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/10664/User's-Wallet",
    CURRENCY_DETAILS: "https://dev.azure.com/GlobalEngg/NextGen%20Promo%20Services%20NGPS/_wiki/wikis/NextGen-Promo-Services-NGPS.wiki/5963/Currency-Details",
}