import { Modal, Typography, Box, Link } from '@mui/material';
import { useState } from 'react';
import { Notification } from '../../common/Notification/Notification';
import { TooltipText } from '../inputComponents/TooltipText';
import './Modal.css';
import '../inputComponents/FileUpload/FileUpload.css';
import { FileUpload } from '../inputComponents/FileUpload/FileUpload.component';
import { defaultNotifState } from '../../constants/currency-constants';

interface UploadFileProps {
    modalOpenState: boolean;
    handleClose(modalState: boolean): void;
    prizeId: string;
    configId: string;
}

const vouchersTemplate = 'https://wiki.coke.com/confluence/display/infomix/Templates+For+Ops+Tickets';

function UploadVouchersModal({ modalOpenState, handleClose, prizeId, configId }: UploadFileProps) {
    const [notificationState, setNotificationState] = useState(defaultNotifState);

    return (
        <Box>
            <Notification
                notificationState={notificationState}
                setNotificationState={setNotificationState}
                messageVisibilityTime={2000}
            />
            <Modal
                open={modalOpenState}
                onClose={() => {
                    handleClose(false);
                }}
                data-testid='vouchersModal'
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box className='modal_main'>
                    <Box className='body'>
                        <Typography id='modal-modal-title' variant='h6'>
                            Upload Vouchers
                        </Typography>
                    </Box>
                    <TooltipText
                        styleName='voucher-upload-subheader'
                        text='Select CSV Template'
                        textVariant='body1'
                        tooltip={
                            <Typography variant='body2'>
                                <Link className='tooltip-link' href={vouchersTemplate} target='_blank'>
                                    Find the vouchers template on Wiki.
                                </Link>
                            </Typography>
                        }
                        iconSize='medium'
                    />
                    <FileUpload
                        maxFiles={10}
                        customAllowedFileTypes={['text/csv']}
                        placeholder='CSV File with prizes'
                        filePath={`${configId}/prizes/${prizeId}/voucherCSVs`}
                        uploadCallback={(files: string[]) => {
                            setNotificationState({
                                open: true,
                                title: `Vouchers for ${prizeId} were added`,
                                content: `Vouchers file uploaded: ${files.join(', ')}`,
                                level: 'success',
                            });
                        }}
                        deleteCallback={(file: string) => {
                            setNotificationState({
                                open: true,
                                title: `Vouchers file was removed successfully`,
                                content: `Removed vouchers file: ${file}`,
                                level: 'success',
                            });
                        }}
                        setNotificationState={setNotificationState}
                    />
                    <Typography variant='body1' style={{ fontStyle: 'italic' }}>
                        You can select multiple files
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
}

export { UploadVouchersModal };
