export const columnsMap = [
    {
        header: "User ID",
        accessor: "gpp_user_id",
        isVisible: false
    },
    {
        header: "Entry Date",
        accessor: "entry_date",
        isVisible: false
    },
    {
        header: "Participation Time",
        accessor: "participation_time",
        isVisible: false
    },
    {
        header: "Burned Pincode",
        accessor: "burned_pincode",
        isVisible: false
    },
    {
        header: "Lot ID",
        accessor: "lot_id",
        isVisible: false
    },
    {
        header: "Voucher Code",
        accessor: "voucher_code",
        isVisible: false
    },
    {
        header: "Optional Information",
        accessor: "optional_information",
        isVisible: false
    },
    {
        header: "Image Promo Entry",
        accessor: "participation_image",
        isVisible: false
    }
]
