// libs
import { Dispatch, SetStateAction } from 'react';
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';

// custom
import { flowMap } from '../constants/AdditionalDetailsVars';
import { CacheManager } from './ConfigManager';

// types
import type {
    AddParams,
    AdditionalDetailsState,
    CheckerParams,
    FlowMapType,
    HandleStateUpdates,
    Handlers,
    MinAgeStateUpdate,
    ModifyParams,
    MutableFlowMapType,
    RemoveParams,
} from '../types/AdditionalDetailsTypes';

// utils
import { isValidNumberString, splitConfigPropKey, splitConfigPropPath } from './Utils';

/**
 * The `StateManager` class is responsible for managing the state and configuration values related to the promotion editor.
 * It provides methods for adding, removing, and updating form state and configuration values.
 *
 * The `StateManager` is a singleton class, and can be accessed using the `getInstance` method.
 *
 * The `handlers` object contains the logic for managing the different types of state updates, such as adding, removing, and modifying form state and configuration values.
 *
 * The `getFlowParams` method retrieves the flow parameters for a specified flow key.
 *
 * The `add`, `modify`, and `remove` methods handle the corresponding state update operations, updating both the form state and the configuration values.
 *
 * The `modifyCheckers` method handles updates to the checkers in the additional details state.
 *
 * The `handleMinAgeUpdates` method handles updates to the minimum age state in the form.
 *
 * The `handleStateUpdates` method is the main entry point for handling state updates, dispatching to the appropriate handler based on the action type.
 *
 * The `updateFormState` method updates the form state with the provided updated form state.
 */
export class StateManager {
    private flowMap = new Map(Object.entries(flowMap));
    private configManager: CacheManager;

    private static instance: StateManager;
    private constructor(configManager: CacheManager) {
        this.configManager = configManager;
    }

    public static getInstance(configManager: CacheManager) {
        return (StateManager.instance = new StateManager(configManager));
    }

    public static deleteInstance() {
        if (!StateManager.instance) return;

        StateManager.instance = null;
    }

    /**
     * Handlers for managing the state and configuration values related to the promotion editor.
     * This object contains methods for adding, removing, and updating form state and configuration values.
     */
    private handlers: Handlers = {
        add: {
            formState: (params) => {
                params.formStateCopy[params.formStateKey] = true;
            },

            cachedValue: (params) => {
                params.formStateCopy[params.formStateKey] = params.value;
                this.configManager.updateConfigValue(params.configPropKey, params.value);
            },

            flow: (params) => {
                const { formStateKey, configPropKey } = params;

                params.formStateCopy[formStateKey] = params.value;

                const castedFlowStateKey = formStateKey as keyof MutableFlowMapType;
                const flowParams = this.getFlowParams(castedFlowStateKey);

                if (!flowParams) return this.configManager.updateConfigValue(configPropKey, params.value);

                return this.configManager.updateConfigValue(configPropKey, flowParams);
            },

            defaultAdd: (params) => {
                const formStateHandler = this.handlers.update.formState;
                const { formStateKey, configPropKey, value, formStateCopy } = params;
                const statePropertyType = typeof formStateCopy[formStateKey];

                const isNumberString = isValidNumberString(value as string);
                const isSameType = typeof value === statePropertyType;
                const isNumberTypeMismatch =
                    typeof value === 'string' && statePropertyType === 'number' && isNumberString;

                if (isSameType || isNumberTypeMismatch) {
                    const updatedValue = isNumberTypeMismatch ? parseFloat(value) : value;
                    formStateHandler({ formStateKey, value: updatedValue, formStateCopy });

                    return this.configManager.updateConfigValue(configPropKey, updatedValue);
                }

                formStateHandler({ formStateKey, value: '', formStateCopy });
                console.warn('Invalid value type for provided key skipping state updates', {
                    value,
                    valueType: typeof value,
                    statePropertyType,
                    configPropKey,
                    formStateKey,
                });
            },
        },
        remove: {
            formState: (params) => {
                params.formStateCopy[params.formStateKey] = false;
            },

            flow: (params) => {
                const { formStateCopy, formStateKey, configPropKey } = params;
                formStateCopy[formStateKey] = false;

                this.configManager.removeConfigValue(configPropKey);
            },

            defaultRemove: (params) => {
                const formStateHandler = this.handlers.update.formState;
                const { formStateKey, configPropKey, value, formStateCopy } = params;

                const updatedValue = value ? value : this.configManager.getCachedFormState()?.[formStateKey];
                formStateHandler({
                    formStateKey,
                    value: updatedValue,
                    formStateCopy,
                });

                return this.configManager.removeConfigValue(configPropKey);
            },
        },
        update: {
            formState: (params) => {
                const { formStateKey, value, formStateCopy } = params;
                if (formStateCopy[formStateKey] === value) return;

                formStateCopy[formStateKey] = value;
            },
        },
    } as const;

    /**
     * Retrieves the flow parameters for the specified flow key.
     *
     * @param flowKey - The key of the flow to retrieve the parameters for.
     * @returns The flow parameters for the specified flow key.
     */
    private getFlowParams(flowKey: keyof MutableFlowMapType): FlowMapType[keyof FlowMapType] {
        return this.flowMap.get(flowKey);
    }

    /**
     * Adds a new value to the form state based on the provided configuration property keys.
     *
     * @param params - An object containing the form state copy, the value to add, the configuration property keys, and a function to update the form state.
     * @param params.formStateCopy - A copy of the current form state.
     * @param params.value - The value to add to the form state.
     * @param params.configPropKeys - An array of configuration property keys to update in the form state.
     * @param params.setFormState - A function to update the form state with the new values.
     * @returns The updated form state.
     */
    private add<T extends AdditionalDetailsState>(params: AddParams<T>) {
        const { formStateCopy, value, configPropKeys, setFormState } = params;
        configPropKeys.forEach((configPropKey) => {
            const [rootKey, formStateKey] = splitConfigPropKey(configPropKey);

            const handlers = this.handlers.add;

            const cachedValue = this.configManager.getCachedConfigValue(configPropKey);
            if (cachedValue || cachedValue === 0)
                return handlers.cachedValue({ formStateCopy, formStateKey, value: cachedValue, configPropKey });

            const handler = handlers[rootKey] || handlers.defaultAdd;
            return handler({ formStateKey, configPropKey, value, formStateCopy });
        });

        return this.updateFormState({
            setFormState,
            updatedFormState: formStateCopy,
        });
    }

    /**
     * Modifies the form state and updates the corresponding configuration values.
     *
     * @param params - An object containing the parameters for modifying the form state.
     * @param params.value - The new value to be set.
     * @param params.configPropKeys - An array of configuration property keys to update.
     * @param params.formStateCopy - A copy of the current form state.
     * @param params.setFormState - A function to update the form state.
     */
    private modify<T extends AdditionalDetailsState>(params: ModifyParams<T>) {
        const formStateHandler = this.handlers.update.formState;
        const { value, configPropKeys, formStateCopy, setFormState } = params;

        configPropKeys.forEach((configPropKey) => {
            const [rootKey, formStateKey] = splitConfigPropKey(configPropKey);
            formStateHandler({
                formStateCopy,
                formStateKey,
                value,
            });

            if (rootKey === 'formState') return;
            this.configManager.updateConfigValue(configPropKey, value);
        });

        this.updateFormState({
            setFormState,
            updatedFormState: formStateCopy,
        });
    }

    /**
     * Removes a configuration property from the form state.
     *
     * @param params - An object containing the necessary parameters for removing a configuration property.
     * @param params.configPropKeys - An array of configuration property keys to remove.
     * @param params.formStateCopy - A copy of the current form state.
     * @param params.setFormState - A function to update the form state.
     * @param params.value - The value to remove.
     * @returns The updated form state.
     */
    private remove<T>(params: RemoveParams<T>) {
        const { configPropKeys, formStateCopy, setFormState, value } = params;
        const handlers = this.handlers.remove;

        configPropKeys.forEach((configPropKey) => {
            const [rootKey, formStateKey] = splitConfigPropKey(configPropKey);
            const handler = handlers[rootKey] || handlers.defaultRemove;

            const params = {
                formStateCopy,
                formStateKey,
                configPropKey,
                value,
            };

            return handler(params);
        });

        return this.updateFormState({
            setFormState,
            updatedFormState: formStateCopy,
        });
    }

    /**
     * Modifies the checkers in the additional details state.
     *
     * @param params - An object containing the following properties:
     *   - `configPropKey`: The key of the configuration property to update.
     *   - `formStateCopy`: A copy of the form state.
     *   - `setFormState`: A function to update the form state.
     *   - `checkerLambdas`: An array of checker lambdas to modify.
     *   - `action`: The action to perform ('add' or 'delete').
     */
    private modifyCheckers<T extends AdditionalDetailsState>(params: CheckerParams<T>) {
        const { configPropKey, formStateCopy, setFormState, checkerLambdas, action } = params;

        checkerLambdas.forEach((checkerLambda) => {
            if (action === 'add') return formStateCopy.checkerLambdas.add(checkerLambda);

            formStateCopy.checkerLambdas.delete(checkerLambda);
        });

        this.updateFormState({
            setFormState,
            updatedFormState: formStateCopy,
        });

        if (!formStateCopy.checkerLambdas.size) {
            return this.configManager.removeConfigValue(configPropKey, 'checkerLambdas');
        }

        this.configManager.updateConfigValue(configPropKey, Array.from(formStateCopy.checkerLambdas));
    }

    /**
     * Handles updates to the minimum age state in the form.
     *
     * @param props - An object containing the form state, a function to update the form state, and an array of state updates to apply.
     * @param props.formStateCopy - A copy of the current form state.
     * @param props.setFormState - A function to update the form state.
     * @param props.stateUpdates - An array of state updates to apply, each with an action type and a value.
     */
    private handleMinAgeUpdates<T extends AdditionalDetailsState>(props: {
        formStateCopy: T;
        setFormState: Dispatch<SetStateAction<T>>;
        stateUpdates: MinAgeStateUpdate[];
    }) {
        const { formStateCopy, setFormState, stateUpdates } = props;

        stateUpdates.forEach((stateUpdate) => {
            switch (stateUpdate.actionType) {
                case 'add':
                    return this.add({
                        formStateCopy,
                        setFormState,
                        value: stateUpdate.value,
                        configPropKeys: [stateUpdate.configPropPath],
                    });
                case 'remove':
                    return this.remove({
                        formStateCopy,
                        setFormState,
                        value: stateUpdate.value,
                        configPropKeys: [stateUpdate.configPropPath],
                    });
                case 'modify-checkers':
                    return this.modifyCheckers({
                        formStateCopy,
                        setFormState,
                        configPropKey: stateUpdate.configPropPath,
                        checkerLambdas: stateUpdate.value,
                        action: stateUpdate.action,
                    });
                default:
                    console.error('Invalid action type', { props });
            }
        });
    }

    /**
     * Handles state updates for the AdditionalDetails form based on the provided action type.
     *
     * @param props - An object containing the necessary properties to handle the state update.
     * @param props.actionType - The type of action to perform on the form state.
     * @param props.formState - The current state of the form.
     * @param props.setFormState - A function to update the form state.
     * @param props.stateUpdates - Additional state updates to apply.
     * @param props.action - The action to perform on the checkers.
     * @param props.value - The value to use for the state update.
     * @param props.configPropPath - The path to the configuration property to update.
     */
    handleStateUpdates<T extends AdditionalDetailsState>(props: HandleStateUpdates<T>) {
        const { actionType, formState } = props;
        const formStateCopy = _cloneDeep(formState);

        switch (actionType) {
            case 'minAge': {
                return this.handleMinAgeUpdates({
                    formStateCopy,
                    setFormState: props.setFormState,
                    stateUpdates: props.stateUpdates,
                });
            }
            case 'modify-checkers':
                return this.modifyCheckers({
                    formStateCopy,
                    setFormState: props.setFormState,
                    action: props.action,
                    checkerLambdas: props.value,
                    configPropKey: props.configPropPath,
                });
            case 'add':
                return this.add({
                    formStateCopy,
                    value: props.value,
                    setFormState: props.setFormState,
                    configPropKeys: splitConfigPropPath(props.configPropPath),
                });
            case 'remove':
                return this.remove({
                    formStateCopy,
                    setFormState: props.setFormState,
                    configPropKeys: splitConfigPropPath(props.configPropPath),
                });
            case 'modify':
                return this.modify({
                    formStateCopy,
                    value: props.value,
                    setFormState: props.setFormState,
                    configPropKeys: splitConfigPropPath(props.configPropPath),
                });
            default:
                console.error('Invalid action type', { props });
        }
    }

    /**
     * Updates the form state with the provided updatedFormState.
     *
     * @param params - An object containing the setFormState function and the updated form state.
     * @param params.setFormState - The function to update the form state.
     * @param params.updatedFormState - The updated form state to apply.
     * @returns The updated form state.
     */
    updateFormState<T>(params: { setFormState: Dispatch<SetStateAction<T>>; updatedFormState: T }) {
        const { setFormState, updatedFormState } = params;

        return setFormState((prevState) => {
            const newState = { ...prevState, ...updatedFormState };
            if (!_isEqual(newState, prevState)) return newState;

            return prevState;
        });
    }
}
