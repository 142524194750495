import { Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    getCurrencyCreationFailedMessage,
    getCurrencyUpdateFailedMessage,
    INPUT_NAME_LIMIT,
    CreateCurrencySummaryProps,
    TextElements,
} from "../../constants/currency-constants";

import {
    createCurrency,
    updateCurrency,
} from "../../pages/Currencies/services/api-calls";
import {
    CurrencyConfiguratorViewProps,
    CurrencyTableItem,
} from "../../types/componentTypes/CurrencyTypes";
import CurrencyForm from "../../pages/Currencies/CurrencyForm";
import FileUploadWrapper from "../../common/FileUploadWrapper/FileUploadWrapper";
import {
    extractFileName,
    getErrorMessageFromError,
} from "../../pages/Currencies/helpers/currency-helpers";
import SummaryView from "../../common/SummaryView/SummaryView";
import TitleComponent from "../titleComponent/TitleComponent";

const CurrencyConfiguratorComponent = ({
    props,
}: CurrencyConfiguratorViewProps) => {
    const {
        actions: {
            setSaveButtonDisabled,
            setCancelButtonDisabled,
            setPageHasBeenModified,
            setFileLocation,
            setCurrencyCreated,
            setNotificationState,
            setCurrencyId,
        },
        handlers: {
            handleDeleteIcon,
            handleAddButtonCallBack,
            handleCancelAction,
        },
        variables: {
            isEdit,
            showSummaryScreen,
            saveButtonDisabled,
            previousFileHasBeenDeleted,
            cancelButtonDisabled,
            pageHasBeenModified,
            fileLocation,
            currencyId,
            groupedCurrencyNames,
        },
    } = props;
    const location = useLocation();

    // #region - state slices
    const [inputName, setInputName] = useState("");
    const [dropdownItem, setSelectedDropdownItem] = useState<string | null>("");
    const [fileName, setFileName] = useState("");
    const [showFileUpload, setShowFileUpload] = useState(true);
    const [pageHasError, setPageHasError] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [currencyForEdit, setCurrForEdit] = useState<CurrencyTableItem>();

    const currency = location.state?.currency
        ? (location.state.currency as CurrencyTableItem)
        : null;
    // #endregion

    useEffect(() => {
        if (!currency) return;

        setCurrForEdit(currency);
        setInputName(currency.name || "");
        setSelectedDropdownItem(currency.country || "");
        setCurrencyId(currency.currency_id);

        const fileName = extractFileName(false, currency.icon_url);
        setFileName(fileName);
        setFileLocation(currency.icon_url || "");
        setShowFileUpload(!currency.icon_url ? true : false);
    }, [currency, setCurrencyId, setFileLocation]);

    useEffect(() => {
        if (pageHasError) return setSaveButtonDisabled(true);
        if (!inputName || !dropdownItem) return setSaveButtonDisabled(true);
        if (inputName && dropdownItem && pageHasBeenModified)
            return setSaveButtonDisabled(false);
    }, [
        inputName,
        dropdownItem,
        pageHasError,
        pageHasBeenModified,
        setSaveButtonDisabled,
    ]);

    // #endregion

    // #region - helper functions

    const disabledForm = () => {
        setSaveButtonDisabled(true);
        setCancelButtonDisabled(true);
        setDisableFields(true);
    };

    const resetPage = () => {
        setInputName("");
        setSelectedDropdownItem("");
        setCurrencyCreated(true);
        setCancelButtonDisabled(false);
        setDisableFields(false);
        setFileLocation("");
    };

    // #endregion

    // #region - handler functions

    const handleCurrencyCreation = async () => {
        try {
            disabledForm();
            await createCurrency({
                currency_id: currencyId,
                name: inputName.trim(),
                country: dropdownItem!,
                ...(fileLocation ? { icon_url: fileLocation } : {}),
                entry_date: new Date().toISOString(),
                is_active: true,
            });
        } catch (error: any) {
            console.error(error);
            setNotificationState(
                getCurrencyCreationFailedMessage(
                    getErrorMessageFromError(error)
                )
            );
        } finally {
            resetPage();
        }
    };

    const handleCurrencyUpdate = async () => {
        try {
            console.log(fileLocation);
            disabledForm();
            await updateCurrency({
                currency_id: currencyId,
                name: inputName.trim(),
                country: dropdownItem!,
                ...(fileLocation ? { icon_url: fileLocation } : {}),
                is_active: true,
            });
        } catch (error) {
            console.error(error);
            setNotificationState(
                getCurrencyUpdateFailedMessage(getErrorMessageFromError(error))
            );
        } finally {
            resetPage();
        }
    };

    const handleDeleteIconFromS3 = () => {
        handleDeleteIcon(currencyForEdit);
    };

    // #endregion

    return (
        <Grid
            container
            alignItems="center"
            justifyContent={"center"}
            sx={{ marginTop: "2vh" }}
        >
            {!showSummaryScreen && (
                <Grid item>
                    {/* Title and description */}
                    <TitleComponent
                        titleText={
                            isEdit
                                ? TextElements.EditCurr
                                : TextElements.CreateCurr
                        }
                        subTitleText={TextElements.SubTitleText}
                    />

                    {/* Form elements */}
                    <CurrencyForm
                        props={{
                            inputNameLimit: INPUT_NAME_LIMIT,
                            inputName,
                            defaultDropdownItem: dropdownItem!,
                            setPageHasBeenModified,
                            setInputName,
                            setSelectedDropdownItem,
                            setPageHasError,
                            groupedCurrencyNames,
                            dropdownItem,
                            pageHasBeenModified,
                            disableFields,
                        }}
                    />

                    <FileUploadWrapper
                        props={{
                            currencyId,
                            fileName,
                            fileDeleteButtonDisabled:
                                saveButtonDisabled && disableFields,
                            showFileUpload:
                                showFileUpload || previousFileHasBeenDeleted,
                            handleDeleteIconFromS3,
                            setFileLocation,
                            setPageHasBeenModified,
                            setNotificationState,
                        }}
                    />

                    {/* Buttons */}
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: "4vh" }}
                    >
                        <Grid item>
                            <Button
                                disabled={cancelButtonDisabled}
                                className="stepperButton"
                                variant="outlined"
                                size="medium"
                                onClick={() =>
                                    handleCancelAction(currencyForEdit)
                                }
                            >
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                disabled={
                                    saveButtonDisabled || !groupedCurrencyNames
                                }
                                className="stepperButton"
                                variant="contained"
                                size="medium"
                                onClick={
                                    isEdit
                                        ? handleCurrencyUpdate
                                        : handleCurrencyCreation
                                }
                            >
                                {isEdit ? "Save" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {showSummaryScreen && (
                <SummaryView
                    props={{
                        ...CreateCurrencySummaryProps({
                            isEdit,
                            currencyId,
                            addButtonCallBack: handleAddButtonCallBack,
                        }),
                    }}
                />
            )}
        </Grid>
    );
};

export default CurrencyConfiguratorComponent;
