import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Box, Grid, Typography } from '@mui/material'
import { getPromotionsTable } from '../../graphql/queries';
import { updatePromotionsTable } from '../../graphql/mutations';
import { NavBar } from '../../common/NavBar/NavBar'
import { StatusBox } from '../../common/StatusBox/StatusBox'
import { CopyInput } from '../../common/CopyInput/CopyInput'
import { SubNav } from '../../common/SubNav/SubNav'
import { GeneralCampaignInfo } from '../../components/GeneralCampaignInfo/GeneralCampaignInfo';
import  {AssignedPromotionsInfo} from '../AssignedPromotions/AssignedPromotionsInfo';
import { useParams } from "react-router-dom";
import ProgressBar from './../../common/ProgressBar/ProgressBar'
import { ThemeProvider } from '@emotion/react';
import GridSkeleton from './../../common/Skeletons/GridSkeleton';
import { createTheme } from '@mui/material/styles';
import MUItheme from './../../MuiTheme';
import { DialogBox } from '../../common/Dialog/DialogBox'
import { Notification } from '../../common/Notification/Notification'
import { defaultNotifState } from '../../constants/currency-constants';
import './EditCampaignPage.css';

const theme = createTheme(MUItheme);

const tabLabels = [
    'Assigned promotions',
    'General info',
]

const archiveDialogBoxContent = {
    firstDialogContent: "This will archive the campaign and all of the promotions assigned to it. Are you sure you want to proceed?",
    secondaryDialogContent: "This action is not reversible!!! Are you sure you want to continue?"
}

const archiveDialogActionButtonLabels = [
    "CANCEL",
    "ARCHIVE"
]

const emptyCampaign = {
    configurations: [],
    promotion_id: '',
    promotion_name: '',
    promotion_fullName: '',
    promotion_author: '',
    promotion_owner: '',
    digital_experience: [],
    promotion_market: [''],
    promotion_brand: [''],
    promotion_start_utc: (new Date()).getTime(),
    promotion_end_utc: (new Date()).getTime(),
    last_modified: undefined,
    archived: false
}

function EditCampaignPage() {
    const [campaignData, setCampaigndata] = useState(emptyCampaign);
    const linksMapper = [{name: "Campaigns", href: "/listCampaigns"}, {name:campaignData.promotion_name}]
    const [loading, setLoading] = useState(true);
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [archiveButtonClicked, setArchiveButtonClicked] = useState(false)
    const [archiveConfirmationClicked, setArchiveConfirmationClicked] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [archiveOperationResult, setArchiveOperationResult] = useState('')
    let urlParams = useParams();

    const updateValuesForArchive = {
        archived: true,
        promotion_id: campaignData.promotion_id,
    }


    const updatePromotionTableMutation = async (updateValues: any) => {
        try {
            await API.graphql(graphqlOperation(updatePromotionsTable, { input: updateValues }));
            setArchiveOperationResult("success");
            setNotificationState({
                open: true,
                title: 'Success!',
                content: 'The campaign is now archived!',
                level: 'success'
            })
        } catch (e: any) {
            console.error("Archiving failed with: ", e.errors[0].message);
            setArchiveOperationResult("error");
            setNotificationState({
                open: true,
                title: 'Error!',
                content: 'The archival operation has failed',
                level: 'error'
            })
        }
    }

    const handleDialogueClose = () => {
        setArchiveButtonClicked(false)
        setArchiveConfirmationClicked(false)
        setButtonDisabled(false)
        setArchiveOperationResult('')

    }

    const handleArchiveButton = () => {
        if (!archiveButtonClicked) {
            setArchiveButtonClicked(true)
            return
        }
        if (archiveButtonClicked && !archiveConfirmationClicked) {
            setArchiveConfirmationClicked(true)
            setButtonDisabled(true)
            return
        }
        if (archiveConfirmationClicked) {
            updatePromotionTableMutation(updateValuesForArchive);
            setArchiveButtonClicked(false)
            handleDialogueClose();
        }
    }

    const actionButtonHandlers = [handleDialogueClose, handleArchiveButton]
    useEffect(() => {
        const retrieveCampaignData = async () => {
            const queryResult: any = await API.graphql({ query: getPromotionsTable, variables: { promotion_id: urlParams.campaignId } });
            const campaignData = queryResult.data.getPromotionsTable;
            return campaignData;
        }

        retrieveCampaignData()
            .then(data => {
                setCampaigndata(data);
            }).then(()=> {
                setLoading(false);
            });

    }, [urlParams])

    if(loading){
        return (
            <ThemeProvider theme={theme}>
                <ProgressBar />
                <GridSkeleton items={4} />
            </ThemeProvider>
        )
    }

    return (
            <div className="pageWrap">
                <Box sx={{ marginBottom: 2 }}>
                    <SubNav linksMapper={linksMapper} textVariant='overline' linkClass='subnav-promo-name'/>
                </Box>
                {(archiveOperationResult) &&
                <Notification notificationState={notificationState} setNotificationState={setNotificationState}/> }
                <Grid container spacing={2} alignItems="center">
                    <Grid item md={6}>
                        <Typography variant="subheader">
                            {campaignData.promotion_name}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <StatusBox archived={!!(archiveOperationResult === "success" || campaignData.archived)} numOfConfigs={campaignData.configurations ? campaignData.configurations.length : 0} text="Status :" align="right" />
                    </Grid>
                </Grid>
                <Grid container spacing={0} sx={{ marginTop: 2 }}>
                    <Grid item md={8} >
                        <CopyInput inputLabel="Campaign Id" inputName="campaignID" inputValue={campaignData.promotion_id} />
                    </Grid>
                    <Grid item md={4}>
                        {!campaignData.archived && <Button onClick={() => { handleArchiveButton() }} variant="outlined" className="archiveCampaignButton">Archive Campaign</Button>}
                            {(archiveButtonClicked || archiveConfirmationClicked) &&
                            <DialogBox
                                isOpen={true}
                                buttonDisabled={buttonDisabled}
                                actionButtonLabels={archiveDialogActionButtonLabels}
                                actionButtonHandlers={actionButtonHandlers}
                                dialogCloseHandler={handleDialogueClose}
                                dialogContent={archiveConfirmationClicked ? archiveDialogBoxContent.secondaryDialogContent : archiveDialogBoxContent.firstDialogContent}
                            />}
                    </Grid>
                </Grid>
                <div className='tabs'>
                     <NavBar tabLabels={tabLabels} components={[<AssignedPromotionsInfo campaignData={campaignData}/>,<GeneralCampaignInfo campaignData={campaignData}/>]}/>
                </div>
            </div>
    )
}

export { EditCampaignPage };
