import type {Blocker, Transition} from 'history';
import {useCallback, useEffect} from 'react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 * Modified - added "history" object instead of "navigator", because the "block" method was deprecated in react-router-dom v6.4.0 
 */
export function useBlocker(blocker: Blocker, when = true) {
    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = history.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(message: string | ((
    location: Transition['location'],
    action: Transition['action'],
) => string), when = true) {
    const blocker = useCallback((tx: Transition) => {
        let response;
        if (typeof message === 'function') {
            response = message(tx.location, tx.action);
            if (typeof response === 'string') {
                response = window.confirm(response);
            }
        } else {
            response = window.confirm(message);
        }
        if (response) {
            tx.retry();
        }
    }, [message]);
    return useBlocker(blocker, when);
}