import Checkbox from '@mui/material/Checkbox';
import { GeneralCheckBoxComponentProps } from '../types/AdditionalDetailsTypes';
import { FormControlLabel, Grid } from '@mui/material';

function GeneralCheckBoxComponent(props: GeneralCheckBoxComponentProps) {
    const {
        fieldName = '',
        labelElement,
        disabledCondition,
        checkBoxHandler,
        componentConfigPropKeys,
        isChecked = false,
        dataTestId,
    } = props;

    function handleOnClick(event: React.ChangeEvent<HTMLInputElement>) {
        checkBoxHandler({ event, componentConfigPropKeys });
    }

    return (
        <Grid item data-testid={`${dataTestId}-container`}>
            <FormControlLabel
                data-testid={`${dataTestId}-checkbox-label`}
                control={
                    <Checkbox
                        className='checkbox-class'
                        data-testid={dataTestId}
                        id={fieldName}
                        name={componentConfigPropKeys}
                        checked={isChecked}
                        disabled={disabledCondition}
                        onChange={handleOnClick}
                    />
                }
                label={labelElement}
            />
        </Grid>
    );
}

export default GeneralCheckBoxComponent;
