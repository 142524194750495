import { useEffect, useState } from 'react';
import { getGeneralCurrencyErrorMessage } from '../../../constants/currency-constants';
// import { CurrencyTableItem } from '../../../types/componentTypes/CurrencyTypes';
import { notificationType } from '../../../types/notifications';
import { getErrorMessageFromError } from '../helpers/currency-helpers';
import { getCurrencies } from '../services/api-calls';
import { CurrencyTableItem } from '../../../types/componentTypes/CurrencyTypes';

const useFetchCurrencies = ({
    setNotifState,
    setCurrencies,
}: {
    setNotifState: React.Dispatch<React.SetStateAction<notificationType>>;
    setCurrencies: React.Dispatch<React.SetStateAction<CurrencyTableItem[]>>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataRefresh, setDataRefresh] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        (async () => {
            if (initialLoad || dataRefresh) {
                setInitialLoad(false);
                await getCurrenciesList();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRefresh]);

    async function getCurrenciesList() {
        if (isLoading) return;

        try {
            setIsLoading(true);
            const currencyData = await getCurrencies();
            if (!currencyData.length) throw new Error('No Currencies Found');

            setCurrencies(currencyData);
        } catch (error) {
            console.error(error);
            setNotifState(getGeneralCurrencyErrorMessage(getErrorMessageFromError(error)));
        } finally {
            setIsLoading(false);
            setDataRefresh(false);
        }
    }

    return {
        fetchingCurrencies: isLoading,
        setDataRefresh,
    };
};

export default useFetchCurrencies;
