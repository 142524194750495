import { Box } from "@mui/material";
import NavBar from "../../components/homeNavigationComponent/Navigation";
import './HomePage.css';


const tabLabels = [
    '1. SETUP CAMPAIGN >',
    '2. SETUP PROMOTION >',
    '3. ADD PRIZES >',
    '4. PREPARE FOR LAUNCH',
]

type headersTypes = {
    [key: number]: string
}

const headers: headersTypes = {
    0: 'Setup a campaign or select an existing one',
    1: 'Setup a promotion and assign it to a campaign',
    2: 'Add prizes',
    3: 'Perpare for launch',
}

const subHeaders: headersTypes = {
    0: 'Campaign is an entity used to group promotions by the digital marketing initiative.Setup a new campaign by going through a multi-step wizard. Or, you can list the existing campaigns to change their settings and assign promotions',
    1: 'Specify a market, timeline, promotional mechanic, MixCodes details, currency details and participation limits by going through a multi-step wizard',
    2: 'Add or upload prizes per promotion',
    3: 'Upload vouchers, generate winning moments, specify participation and winning limits per promotion, create currency allocation rules and more',
}

function HomePage() {
    return (
    <Box id="home" className='home-box'>
            <NavBar tabs={tabLabels} headers={headers} subHeaders={subHeaders} />
    </Box>
    )
}

export { HomePage };
