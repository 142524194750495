import { flowState } from '../../pages/AddPrize/AddPrizePage';
import { Box, Typography } from '@mui/material';
import { TooltipText } from "../../components/inputComponents/TooltipText";
import { RadioButton } from "../../components/inputComponents/RadioButton";
import { AllocateDates } from '../../components/selectComponents/AllocateDates';

function PrizeState({ formik }: flowState) {
    return (
        <Box className='prize-state-box'>
            <Typography variant='h4' gutterBottom>Prize State & Timeline</Typography>
            <TooltipText text='Select the prize state. Active means the prize is available for distribution' textVariant='body1' tooltip="Prize start and end date are optional and shouldn't be mistaken with the campaign's or promotion's start and end dates.
                        If there is a need to activate the prize in the middle of the promotion, select the 2nd option. If the prize is inactive, it won't be shown in the prize catalog." />
            <Box className='radio-group-form'>
            <RadioButton formik={formik} values={[
                { value: 'active', label: 'Prize timeline is the same as the promotion duration. Prize is active' },
                {
                    value: 'custom', label: 'Prize timeline is different than the promotion duration', useComponent: true,
                    component: <AllocateDates description={'Date Range'} datetime={true} formikStartDate={`tempParams.start_date`} formikEndDate={`tempParams.end_date`} formikTimezoneValue={'prize_activity_timezone'}/>
                },
                { value: 'inactive', label: 'Prize is inactive upon creation' }
            ]}
                radioGroupName={'tempParams.prizeStatus'}
            />
            </Box>
        </Box>
    )
}

export { PrizeState }
