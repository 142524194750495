import { Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { CheckboxComponent } from '../../inputComponents/CheckboxComponent';
import { FormikContextType, getIn } from 'formik';
import { InputComponent } from '../../inputComponents/InputComponent';
import { ICreatePromotionState } from '../../../types/componentTypes/assignPromo';

const CaptchaCheckerComponent = ({ formik }: { formik: FormikContextType<ICreatePromotionState> }) => {
    const defaultValue = getIn(formik.values, 'tempParams.addCaptcha') || false;

    const [captchaEnabled, setCaptchaEnabled] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setCaptchaEnabled(checked);

        if (!checked) {
            formik.setFieldValue('configurationParameters.captchaSecret', '');
        }

        formik.setFieldValue('tempParams.addCaptcha', checked);
    };

    return (
        <>
            <CheckboxComponent
                fieldName={'tempParams.addCaptcha'}
                label={<Typography variant='body1'>Add captcha secret (Optional)</Typography>}
                customOnChangeHandler={handleChange}
                customBoxClass='captcha-checkbox'
                testingId={'captcha-checkbox'}
            />
            {captchaEnabled && (
                <InputComponent
                    formik={formik}
                    inputValue={getIn(formik.values, 'configurationParameters.captchaSecret')}
                    formikLabel={'configurationParameters.captchaSecret'}
                    label='Captcha secret'
                    inputClassName='captcha-box'
                    shouldFocusOnElement={true}
                />
            )}
        </>
    );
};

export default CaptchaCheckerComponent;
