import { Box, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { MixCodesDetails } from '../../../components/assignPromotionFlowComponent/MixCodesDetails';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface mixCodesProps {
    handleTabChange: Dispatch<SetStateAction<boolean>>
}
const MixCodesForm = ({handleTabChange}: mixCodesProps) => {
    const formik = useFormikContext();
    const [changesApplied, setChangesApplied] = useState(false);

    useEffect(() => {
        setChangesApplied(formik.dirty)
        handleTabChange(formik.dirty && formik.submitCount === 0);
    }, [formik.dirty, formik.submitCount, handleTabChange]);

    return (
        <>
            <MixCodesDetails formik={formik} isEdit={true} mainBoxClass={'mix-codes-details'}/>
            <Box className='mixcodesChangesButtons'>
                {changesApplied && <Button variant='outlined' onClick={() => formik.resetForm({ values: formik.initialValues })}>Discard</Button>}
                <Button variant="contained" color="primary" type="submit">Save</Button>
            </Box>
        </>
    )
}

export { MixCodesForm }
