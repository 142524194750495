import { Box, Typography, Grid, FormHelperText } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { FormikContextType, getIn } from 'formik';
import { GridItems } from './GridItems';
import DisableSelectedItemsDropdown from '../../common/DisableSelectedItemsDropdown/DisableSelectedItemsDropdown';
import { PrizeRatioType } from '../../types/componentTypes/limits';

import '../../pages/AssignPromotion/AssignPromotionPage.css';
import './GridItems.css';

const PRIZES_RATIOS_ITEMS_MAX = 30;

export interface IPrizesRatiosState {
    alwaysWinLimits: {
        alwaysWinPrizeRatios: PrizeRatioType[];
        ratioWinning: boolean;
        ratiosSum: string;
    };
}

interface PrizesRatiosProps {
    formik: FormikContextType<IPrizesRatiosState>;
}

function PrizesRatios({ formik }: PrizesRatiosProps) {
    const alwaysWinPrizeRatios: PrizeRatioType[] = getIn(formik.values, 'alwaysWinLimits.alwaysWinPrizeRatios');
    const prizeOptions = getIn(formik.values, 'tempParams.alwaysWinLimits.prizeOptions');
    const [selectedPrizes, setSelectedPrizes] = useState<string[]>([]);
    const [maxFields, setMaxFields] = useState<number>(PRIZES_RATIOS_ITEMS_MAX);

    useEffect(() => {
        const defaultSelectedPrizes = alwaysWinPrizeRatios.map((item) => item.prizeId);
        setSelectedPrizes(defaultSelectedPrizes);
        setMaxFields(prizeOptions.length <= PRIZES_RATIOS_ITEMS_MAX ? prizeOptions.length : PRIZES_RATIOS_ITEMS_MAX);
    }, [alwaysWinPrizeRatios, prizeOptions]);

    const addPrizeRatio = () => {
        const newItem: PrizeRatioType = { prizeId: '', ratio: 0 };
        if (alwaysWinPrizeRatios.length < maxFields) {
            formik.setFieldValue('alwaysWinLimits.alwaysWinPrizeRatios', [...alwaysWinPrizeRatios, newItem]);
        }
    };

    const removePrizeRatio = (params: { index: number }) => {
        const newSelectedPrizes = selectedPrizes.filter((i) => i !== alwaysWinPrizeRatios[params.index].prizeId);
        formik.setFieldValue(
            'alwaysWinLimits.alwaysWinPrizeRatios',
            alwaysWinPrizeRatios.filter((_: PrizeRatioType, i: number) => i !== params.index)
        );
        setSelectedPrizes(newSelectedPrizes);
    };

    const calcSum = () =>
        alwaysWinPrizeRatios.reduce((sum: number, item: PrizeRatioType) => sum + (Number(item.ratio) || 0), 0);

    const onSelectPrize = (props: {
        event: React.SyntheticEvent<Element, Event>;
        selectedItem: string;
        currentItemIndex: number;
    }) => {
        const { selectedItem, currentItemIndex } = props;

        if (alwaysWinPrizeRatios[currentItemIndex].prizeId) {
            const selectedPrizesCopy = [...selectedPrizes];
            const currIndex = selectedPrizes.indexOf(alwaysWinPrizeRatios[currentItemIndex].prizeId);
            selectedPrizesCopy[currIndex] = selectedItem;
            setSelectedPrizes(selectedPrizesCopy);
            formik.setFieldValue(`alwaysWinLimits.alwaysWinPrizeRatios[${currentItemIndex}].prizeId`, selectedItem);
            return;
        }

        setSelectedPrizes([...selectedPrizes, selectedItem]);
        formik.setFieldValue(`alwaysWinLimits.alwaysWinPrizeRatios[${currentItemIndex}].prizeId`, selectedItem);
    };

    const renderPrizeRatioComponents = ({ items }) => (
        <Grid container className='grid-prize-ratios'>
            {items.map((_: PrizeRatioType, index: number) => (
                <Fragment key={index}>
                    <GridItems
                        maxFields={maxFields}
                        testId={`prize-ratio-dropdown-menu-${index}`}
                        formik={formik}
                        key={`prize-ratio-grid-item-${index}`}
                        index={index}
                        component={
                            <DisableSelectedItemsDropdown
                                inputComponentProps={{
                                    uniqueKey: `ratio-input-${index}`,
                                    label: 'Ratio (%)',
                                    value: alwaysWinPrizeRatios[index].ratio.toString() || '',
                                    formikLabel: `alwaysWinLimits.alwaysWinPrizeRatios[${index}].ratio`,
                                    errorPath: `alwaysWinLimits.alwaysWinPrizeRatios[${index}].ratio`,
                                    showError: false,
                                    min: 0.01,
                                    max: 100,
                                    type: 'number',
                                }}
                                dropDownProps={{
                                    currentItemIndex: index,
                                    width: 8.5,
                                    fontSize: 14,
                                    label: 'Prize name / ID',
                                    placeholder: 'Select A Prize',
                                    testId: `prize-ratio-dropdown-${index}`,
                                    uniqueKey: `prize-ratio-dropdown-${index}`,
                                    value: alwaysWinPrizeRatios[index].prizeId,
                                    optionsList: prizeOptions,
                                    selectedItems: selectedPrizes,
                                    textFieldStyles: { background: 'white' },
                                    onChangeHandler: onSelectPrize,
                                }}
                                formik={formik}
                            />
                        }
                        addAction={addPrizeRatio}
                        removeAction={removePrizeRatio}
                        formikValueArray='alwaysWinLimits.alwaysWinPrizeRatios'
                        itemClass='prize-ratio-details'
                    />
                    <FormHelperText key={`prize-ratio-helper-${index}`} error={true}>
                        {getIn(formik.touched, 'alwaysWinLimits') &&
                            (getIn(formik.errors, `alwaysWinLimits.alwaysWinPrizeRatios[${index}].ratio`) ||
                                getIn(formik.errors, `alwaysWinLimits.alwaysWinPrizeRatios[${index}].prizeId`))}
                    </FormHelperText>
                </Fragment>
            ))}
        </Grid>
    );
    return (
        <>
            {formik.values.alwaysWinLimits.ratioWinning && (
                <Grid container className='grid-ratio-winning'>
                    <Box className='ratio-sum-box' data-testid={'ratio-sum-box'}>
                        <Typography variant='subtitle1' align='center'>
                            Ratio Winning Limit Percentage
                        </Typography>
                        <Typography variant='h6' align='center' data-testid={'sum-val'}>
                            {calcSum()}%
                        </Typography>
                        <FormHelperText error={true}>
                            {formik.touched?.alwaysWinLimits?.alwaysWinPrizeRatios &&
                                formik.errors?.alwaysWinLimits?.ratiosSum}
                        </FormHelperText>
                    </Box>

                    {renderPrizeRatioComponents({
                        items: formik.values.alwaysWinLimits?.alwaysWinPrizeRatios,
                    })}
                </Grid>
            )}
        </>
    );
}

export { PrizesRatios };
