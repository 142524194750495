// lib
import { Suspense, lazy } from 'react';
import { Grid, Typography } from '@mui/material';

// custom
import { ToolTipProps, componentConfigPropKeys } from '../constants/AdditionalDetailsVars';
import GenericSpinner from '../../../../common/Spinners/GenericSpinner';

// helpers
import {
    handlers,
    getTeaserComponentProps,
    getAddCaptchaCheckBoxProps,
    getMinAgeComponentProps,
    getListPrizesCheckBoxProps,
    getWVComponentProps,
    getInterruptedFlowComponentProps,
    getAdditionalInfoComponentProps,
} from '../utils/MechanicViewsHelpers';

// types
import type { ViewProps, AdditionalDetailsState } from '../types/AdditionalDetailsTypes';
import AdditionalInfoComponent from '../components/AdditionalInfoComponent';

// Lazy loaded components used in the views to reduce bundle size and improve performance
const AddCaptchaCheckBoxComponent = lazy(() => import('../components/AddCaptchaCheckBoxComponent'));
const GeneralCheckBoxComponent = lazy(() => import('../components/GeneralCheckBoxComponent'));
const ListPrizesCheckBoxComponent = lazy(() => import('../components/ListPrizesCheckBoxComponent'));
const MinAgeComponent = lazy(() => import('../components/MinAgeComponent'));
const TagsComponent = lazy(() => import('../components/TagsComponent'));
const TeaserComponent = lazy(() => import('../components/TeaserComponent'));
const TooltipComponent = lazy(() => import('../components/TooltipComponent'));
const WebViewUrlInputComponent = lazy(() => import('../components/WebViewUrlInputComponent'));
const IdentityProviderRadioButton = lazy(() => import('../components/IdentifyProviderRadioButtonComponent'));
const InterruptedFlowComponent = lazy(() => import('../components/InterruptedFlowComponent'));

export const mechanicViews = {
    'Collect & Get'<T extends AdditionalDetailsState>(props: ViewProps<T>): JSX.Element {
        const { formState, setPageErrors, pageErrors, updateStateValues } = props;

        const { checkBoxHandler, radioButtonHandler, inputHandler, visibleFromDateHandler, minAgeCheckBoxHandler } =
            handlers<T>(props);

        const mechanic = 'Collect & Get' as const;
        const toolTipProps = ToolTipProps[mechanic]();
        const formattedMechanic = mechanic.toLowerCase().replace(/\s/g, '-');

        const baseComponentProps = {
            formattedMechanic,
            formState,
            setPageErrors,
            pageErrors,
        };

        return (
            <Suspense fallback={<GenericSpinner showSpinner={true} />}>
                <Grid container columns={24} data-testid={`additional-details-${formattedMechanic}-parent-container`}>
                    {/* left */}
                    <Grid item xs={16}>
                        <Grid container direction='column' rowGap={1}>
                            <Typography>Select Promotion Functionalities:</Typography>

                            {/* Teaser Component */}
                            <TeaserComponent
                                styles={{ width: '300px' }}
                                {...getTeaserComponentProps({
                                    ...baseComponentProps,
                                    checkBoxHandler,
                                    visibleFromDateHandler,
                                    labelElement: <TooltipComponent {...toolTipProps.promoTeaser} />,
                                    componentConfigPropKeys: componentConfigPropKeys.promoTeaser(),
                                })}
                            />

                            {/* Web Integration Component */}
                            <AddCaptchaCheckBoxComponent
                                {...getAddCaptchaCheckBoxProps({
                                    ...baseComponentProps,
                                    checkBoxHandler,
                                    inputHandler,
                                    mechanic,
                                    labelElement: <TooltipComponent {...toolTipProps.captchaSecret} />,
                                    componentConfigPropKeys: componentConfigPropKeys.captchaSecret(),
                                })}
                            />

                            {/* Min Age Component */}
                            <MinAgeComponent
                                {...getMinAgeComponentProps({
                                    ...baseComponentProps,
                                    inputHandler,
                                    setFormState: updateStateValues,
                                    checkBoxHandler: minAgeCheckBoxHandler,
                                    labelElement: <TooltipComponent {...toolTipProps.minAge} />,
                                    componentConfigPropKeys: componentConfigPropKeys.minAge(formState.promoFlow),
                                })}
                            />

                            {/* Optional Promotion Components */}
                            <Typography sx={{ paddingTop: '1rem' }}>Optional Promotion Functionalities:</Typography>

                            {/* Prize Catalogue Component  */}
                            <ListPrizesCheckBoxComponent
                                {...getListPrizesCheckBoxProps({
                                    radioButtonHandler,
                                    checkBoxHandler,
                                    formState,
                                    formattedMechanic,
                                    labelElement: <TooltipComponent {...toolTipProps.listPrizes} />,
                                    componentConfigPropKeys: componentConfigPropKeys.listPrizes(),
                                })}
                            />

                            {/* Enable Redeem Prize CheckBox */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-redeem-prize-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-redeem-prize-checkbox`}
                                labelElement={<TooltipComponent {...toolTipProps.redeemPrize} />}
                                checkBoxHandler={checkBoxHandler}
                                isChecked={formState.redeemPrize}
                                componentConfigPropKeys={componentConfigPropKeys.redeemPrize()}
                            />

                            {/* Enable Add Transaction Checkbox */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-add-transaction-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-add-transaction-checkbox`}
                                labelElement={<TooltipComponent {...toolTipProps.addTransaction} />}
                                checkBoxHandler={checkBoxHandler}
                                isChecked={formState.addTransaction}
                                componentConfigPropKeys={componentConfigPropKeys.addTransaction()}
                            />

                            {/* Enable Query Vouchers Checkbox */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-query-vouchers-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-query-vouchers-checkbox`}
                                labelElement={<TooltipComponent {...toolTipProps.queryVouchers} />}
                                checkBoxHandler={checkBoxHandler}
                                isChecked={formState.queryVouchers}
                                componentConfigPropKeys={componentConfigPropKeys.queryVouchers()}
                            />

                            {/* Enable Redeem Voucher Checkbox */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-redeem-voucher-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-redeem-voucher-checkbox`}
                                labelElement={<TooltipComponent {...toolTipProps.redeemVoucher} />}
                                checkBoxHandler={checkBoxHandler}
                                isChecked={formState.redeemVoucher}
                                styles={{ paddingBottom: '1rem' }}
                                componentConfigPropKeys={componentConfigPropKeys.redeemVoucher()}
                            />

                            {/* Enable Redeem Voucher Checkbox */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-query-wallet-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-query-wallet-checkbox`}
                                labelElement={
                                    <TooltipComponent
                                        dataTestId={`additional-details-${formattedMechanic}-query-wallet-tooltip`}
                                        {...toolTipProps.queryWallet}
                                    />
                                }
                                checkBoxHandler={checkBoxHandler}
                                isChecked={formState.queryWallet}
                                componentConfigPropKeys={componentConfigPropKeys.queryWallet()}
                            />

                            {/* Tags Component */}
                            <TagsComponent {...props} />
                        </Grid>
                    </Grid>

                    {/* right */}
                    <IdentityProviderRadioButton
                        dataTestId={`additional-details-${formattedMechanic}-identity-provider`}
                        componentConfigPropKeys={componentConfigPropKeys.identityProvider()}
                        value={formState.userIdType}
                        handler={radioButtonHandler}
                    />
                </Grid>
            </Suspense>
        );
    },

    'Instant Win'<T extends AdditionalDetailsState>(props: ViewProps<T>): JSX.Element {
        const { formState, setPageErrors, pageErrors, updateStateValues } = props;
        const mechanic = 'Instant Win' as const;
        const toolTipProps = ToolTipProps[mechanic]();
        const formattedMechanic = mechanic.toLowerCase().replace(/\s/g, '-');

        const { checkBoxHandler, radioButtonHandler, inputHandler, visibleFromDateHandler, customInfoHandler } = handlers(props);

        const baseComponentProps = {
            formattedMechanic,
            checkBoxHandler,
            formState,
            setPageErrors,
            pageErrors,
        };

        return (
            <Suspense fallback={<GenericSpinner showSpinner={true} />}>
                <Grid container columns={24} data-testid={`additional-details-${formattedMechanic}-parent-container`}>
                    {/* left */}
                    <Grid item xs={16}>
                        <Grid container direction='column' rowGap={1}>
                            {/* Voucher History */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-query-vouchers-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-query-vouchers-checkbox`}
                                isChecked={!!formState.queryVouchers}
                                labelElement={<TooltipComponent {...toolTipProps.queryVouchers} />}
                                checkBoxHandler={checkBoxHandler}
                                componentConfigPropKeys={componentConfigPropKeys.queryVouchers()}
                            />

                            {/* Prize Catalogue */}
                            <ListPrizesCheckBoxComponent
                                {...getListPrizesCheckBoxProps({
                                    radioButtonHandler,
                                    checkBoxHandler,
                                    formState,
                                    formattedMechanic,
                                    labelElement: <TooltipComponent {...toolTipProps.listPrizes} />,
                                    componentConfigPropKeys: componentConfigPropKeys.listPrizes(),
                                })}
                            />

                            {/* Promotion Teaser */}
                            <TeaserComponent
                                styles={{ width: '300px' }}
                                {...getTeaserComponentProps({
                                    ...baseComponentProps,
                                    visibleFromDateHandler,
                                    formattedMechanic,
                                    labelElement: <TooltipComponent {...toolTipProps.promoTeaser} />,
                                    componentConfigPropKeys: componentConfigPropKeys.promoTeaser(),
                                })}
                            />

                            {/* Add Captcha CheckBox */}
                            <AddCaptchaCheckBoxComponent
                                styles={{ paddingBottom: '1rem' }}
                                {...getAddCaptchaCheckBoxProps({
                                    ...baseComponentProps,
                                    inputHandler,
                                    mechanic: 'Instant Win' as const,
                                    labelElement: <TooltipComponent {...toolTipProps.captchaSecret} />,
                                    componentConfigPropKeys: componentConfigPropKeys.captchaSecret(),
                                })}
                            />

                            {/* Additional Info */}
                            <AdditionalInfoComponent
                                styles={{ paddingBottom: '1rem' }}
                                {...getAdditionalInfoComponentProps({
                                    ...baseComponentProps,
                                    customInfoHandler,
                                    mechanic: 'Instant Win' as const,
                                    labelElement: <TooltipComponent {...toolTipProps.customAdditionalInfo} />,
                                    componentConfigPropKeys: componentConfigPropKeys.customAdditionalInfo(),
                                })}
                            />

                            {/* Web View Url */}
                            <WebViewUrlInputComponent
                                {...getWVComponentProps({
                                    ...baseComponentProps,
                                    wv_url: formState.wv_url,
                                    componentConfigPropKeys: componentConfigPropKeys.wvUrl(),
                                    inputHandler,
                                })}
                            />

                            {/* Tags */}
                            <TagsComponent {...props} />
                        </Grid>
                    </Grid>

                    {/* right */}
                    <Grid item xs>
                        <Grid container>
                            <InterruptedFlowComponent
                                styles={{ paddingBottom: '1rem' }}
                                {...getInterruptedFlowComponentProps({
                                    ...baseComponentProps,
                                    setFormState: updateStateValues,
                                    labelElement: <TooltipComponent {...toolTipProps.interruptedFlow} />,
                                    componentConfigPropKeys: componentConfigPropKeys.interruptedFlow(),
                                })}
                            />

                            <IdentityProviderRadioButton
                                componentConfigPropKeys={componentConfigPropKeys.identityProvider()}
                                dataTestId={`additional-details-${formattedMechanic}-identity-provider`}
                                value={formState.userIdType}
                                handler={radioButtonHandler}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Suspense>
        );
    },

    'Prize Draw (Lottery)'<T extends AdditionalDetailsState>(props: ViewProps<T>): JSX.Element {
        const { formState, setPageErrors, pageErrors } = props;

        const mechanic = 'Prize Draw (Lottery)' as const;
        const formattedMechanic = mechanic.toLowerCase().replace(/\s/g, '-');
        const toolTipProps = ToolTipProps[mechanic]();

        const { checkBoxHandler, inputHandler, radioButtonHandler, customInfoHandler } = handlers(props);

        const baseProps = {
            formattedMechanic,
            inputHandler,
            setPageErrors,
            pageErrors,
        };

        return (
            <Suspense fallback={<GenericSpinner showSpinner={true} />}>
                <Grid container columns={24} data-testid={`additional-details-${formattedMechanic}-parent-container`}>
                    {/* left */}
                    <Grid item xs={16}>
                        <Grid container direction='column' rowGap={1}>
                            {/* Image Entry */}
                            <GeneralCheckBoxComponent
                                fieldName={`additional-details-${formattedMechanic}-image-entry-checkbox-id`}
                                dataTestId={`additional-details-${formattedMechanic}-image-entry-checkbox`}
                                isChecked={formState.imageEntry}
                                labelElement={<TooltipComponent {...toolTipProps.imageEntry} />}
                                checkBoxHandler={checkBoxHandler}
                                componentConfigPropKeys={componentConfigPropKeys.imageEntry()}
                            />

                            {/* Additional Info */}
                            <AdditionalInfoComponent
                                styles={{ paddingBottom: '1rem' }}
                                {...getAdditionalInfoComponentProps({
                                    ...baseProps,
                                    formState,
                                    customInfoHandler,
                                    checkBoxHandler,
                                    mechanic: 'Prize Draw (Lottery)' as const,
                                    labelElement: <TooltipComponent {...toolTipProps.customAdditionalInfo} />,
                                    componentConfigPropKeys: componentConfigPropKeys.customAdditionalInfo(),
                                })}
                            />

                            {/* Add Captcha */}
                            <AddCaptchaCheckBoxComponent
                                styles={{ paddingBottom: '1rem' }}
                                {...getAddCaptchaCheckBoxProps({
                                    ...baseProps,
                                    checkBoxHandler,
                                    formState,
                                    mechanic: 'Prize Draw (Lottery)',
                                    labelElement: <TooltipComponent {...toolTipProps.captchaSecret} />,
                                    componentConfigPropKeys: componentConfigPropKeys.captchaSecret(),
                                })}
                            />

                            {/* Tags and WebURL container */}
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                justifyContent='flex-start'
                                rowGap={3}>
                                {/* Web View Url */}
                                <WebViewUrlInputComponent
                                    {...getWVComponentProps({
                                        ...baseProps,
                                        wv_url: formState.wv_url,
                                        componentConfigPropKeys: componentConfigPropKeys.wvUrl(),
                                    })}
                                />

                                {/* Tags */}
                                <TagsComponent {...props} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* right */}
                    <IdentityProviderRadioButton
                        componentConfigPropKeys={componentConfigPropKeys.identityProvider()}
                        dataTestId={`additional-details-${formattedMechanic}-identity-provider`}
                        value={formState.userIdType}
                        handler={radioButtonHandler}
                    />
                </Grid>
            </Suspense>
        );
    },
} as const;
