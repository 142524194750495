import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';

import { getDraftCampaigns, getActiveCampaigns, getAllCampaigns, getArchivedCampaigns } from '../../../graphql/queries';
import { Campaign } from '../../../types/campaign';

type GetCampaigns = {
    [key: string]: {
        items: Campaign[];
    };
};

export const getDraftCampaignsData = async (filterInput: string) => {
    const { data } = (await API.graphql(
        graphqlOperation(getDraftCampaigns, {
            configArrSize: 0,
            campaignId: filterInput,
            campaignName: filterInput,
        })
    )) as GraphQLResult<GetCampaigns>;

    return data.getDraftCampaigns.items;
};

export const getActiveCampaignsData = async (filterInput: string) => {
    const { data } = (await API.graphql(
        graphqlOperation(getActiveCampaigns, {
            configArrSize: 1,
            campaignId: filterInput,
            campaignName: filterInput,
        })
    )) as GraphQLResult<GetCampaigns>;

    return data.getActiveCampaigns.items;
};

export const getAllCampaignsData = async (filterInput: string) => {
    const { data } = (await API.graphql(
        graphqlOperation(getAllCampaigns, { campaignId: filterInput, campaignName: filterInput })
    )) as GraphQLResult<GetCampaigns>;

    return data.getAllCampaigns.items;
};

export const getArchivedCampaignsData = async (filterInput: string) => {
    const { data } = (await API.graphql(
        graphqlOperation(getArchivedCampaigns, { campaignId: filterInput, campaignName: filterInput })
    )) as GraphQLResult<GetCampaigns>;

    return data.getArchivedCampaigns.items;
};
