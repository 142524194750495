import { Modal, Button, Typography, Box } from '@mui/material'
import {useFormik} from 'formik';
import { ObjectSchema} from 'yup';
import './Modal.css'
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

interface AddToListProps {
    formikState: any,
    formikFieldName:string,
    value: string,
    schema?: ObjectSchema<any>,
    header:string,
    textfieldLabel?: string,
    modalOpenState: boolean,
    listToInsertTo: Array<any>,
    handleClose(modalState:boolean):void,
    customListValues?: Array<string> | undefined
}

function AddToListModal({formikState, formikFieldName, value, schema, header, textfieldLabel, modalOpenState, listToInsertTo, handleClose, customListValues}: AddToListProps) {

    const formik = useFormik({
        initialValues: {
            [value]: ''
        },
        validationSchema: schema,
        onSubmit: async (values) => {
          try {
            if(listToInsertTo.find((exp) => {
                if (!isNaN(exp)) { return exp === values[value] }
                else { return exp.toLowerCase() === values[value].toLowerCase()}
            })){
                formik.setFieldError(value, 'Such option is already present in the list!');
            } else {
                listToInsertTo.push(values[value]);
                customListValues?.push(values[value]);
                formikState.setFieldValue(formikFieldName, [...formikState.values[formikFieldName], values[value]]);
                formik.values[value] = ''
                handleClose(false);
            }
          } catch(e) {
            console.error("Failed to insert data with: ", e);
          }
        },
    });

    return (
        <div>
            <Modal
            open={modalOpenState}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >

                <Box className='modal_main'>
                    <Box className='body'>
                        <Typography id="modal-modal-title" variant="h6">
                            {header}
                        </Typography>
                    </Box>
                    <TextFieldWrapper
                        fullWidth id="add-list-modal-outlined-basic"
                        label={textfieldLabel}
                        name={value}
                        variant="outlined"
                        value={formik.values[value]}
                        margin="dense"
                        className="textField"
                        error={formik.touched[value] && Boolean(formik.errors[value])}
                        helperText={formik.touched[value] && formik.errors[value]}
                        onChange={formik.handleChange}
                    />
                    <Box sx={{mt:5}}>
                        <Button onClick={() => formik.submitForm()} sx={{float:"right"}}>ADD</Button>
                        <Button onClick={() => handleClose(false)} sx={{float:"right"}}>CANCEL</Button>
                    </Box>

                </Box>

            </Modal>

      </div>
    )
}

export { AddToListModal }
