import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { SaveProps } from '../../types/componentTypes/savePrize';
import { CopyInput } from '../../common/CopyInput/CopyInput';

import '../../pages/AssignPromotion/AssignPromotionPage.css';

function SavePrize({ formik, customAction, isEdit }: SaveProps) {
    const { campaignId } = useParams();
    return (
        <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            rowSpacing={2}
        >
            <Grid
                container
                md={12}
                sx={{ mt: 3 }}
                direction='column'
                justifyContent='space-around'
                alignItems='center'
            >
                <CheckCircleOutlineIcon className='save-promo-icon'></CheckCircleOutlineIcon>
            </Grid>
            <Grid container xs={0} direction='column' alignItems='center'>
                <Typography variant='h4' gutterBottom>
                    {isEdit
                        ? 'Prize has been successfully edited'
                        : 'Prize has been successfully created!'}
                </Typography>
            </Grid>
            <Typography variant='body1' noWrap={false} gutterBottom>
                You can find it in Prizes tab under the promotion profile
            </Typography>
            <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                sx={{ mb: 10, mt: 3 }}
            >
                <Button
                    variant='contained'
                    color='primary'
                    endIcon={<ArrowForwardIosIcon />}
                    className='save-promo-buttons'
                    onClick={(e: any) => {
                        customAction(e);
                    }}
                    component={Link}
                    to={`/addPrize/${campaignId}/${formik.values.configuration_id}`}
                >
                    Add Another Prize
                </Button>
                <Button
                    variant='outlined'
                    color='primary'
                    className='save-promo-buttons'
                    component={Link}
                    to={`/editPromotion/${formik.values.configuration_id}`}
                    state={{ tab: 'prizes' }}
                >
                    View Promotion
                </Button>
            </Grid>
            <Grid item rowSpacing={0} md={12} justifyContent='center'>
                <CopyInput
                    inputValue={formik.values.prize_id}
                    inputLabel={'Prize ID'}
                    inputName={'prizeID'}
                />
            </Grid>
        </Grid>
    );
}

export { SavePrize };
