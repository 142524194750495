import { Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import { Dispatch, SetStateAction } from 'react';
import { prizeDataType } from "../../pages/EditPromotion/EditPromotionPage";
import { getFlowTabButtons, flowTabButtonsMap } from "../../constants/button-constants";

type FlowTabButtonsProps = {
    promotionMechanic: string,
    changesDetected,
    handleConfirmationWindow,
    tab: string,
    setTab: Dispatch<SetStateAction<string>>,
    prizes: Array<prizeDataType>,
}

const FlowTabButtons = ({
    promotionMechanic,
    changesDetected,
    handleConfirmationWindow,
    tab,
    setTab,
    prizes,
}: FlowTabButtonsProps) => {
    return <>
        {
            getFlowTabButtons(promotionMechanic)
                .sort((a, b) => Object.keys(flowTabButtonsMap).indexOf(a) - Object.keys(flowTabButtonsMap).indexOf(b))
                .map(buttonName => (
                    <Button
                        key={buttonName}
                        fullWidth={true}
                        className={tab === buttonName ? 'currentOptionSelected' : ''}
                        onClick={() => { changesDetected ? handleConfirmationWindow(buttonName) : setTab(buttonName) }}>
                        {flowTabButtonsMap[buttonName].label}
                        {(buttonName === 'vouchers' || buttonName === 'winningMoments')
                            && <InfoIcon
                                className={tab === buttonName ? "endIcon" : "endIcon-notSelected"}
                                fontSize="medium" />
                        }
                        {(buttonName === 'prizes' && prizes && prizes.length < 1)
                            && <ErrorIcon
                                className={tab === buttonName ? "endIcon" : "endIcon-notSelected"}
                                fontSize="medium" />
                        }
                    </Button>
                ))
        }
    </>
}

export default FlowTabButtons;
