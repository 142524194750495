import { Autocomplete, FormControl, FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import {getIn} from 'formik'
import { supportedLanguages } from '../../constants/lists';
import { languageSelector } from '../../types/componentTypes/languageSelector';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
const uniqid = require('uniqid');


const initialLanguages = Object.keys(supportedLanguages).map((country) => {
    return supportedLanguages[country].map((lang) => {
        return {
            title: 'All languages',
            option: lang
        }
    })
}).flat()

const SelectLanguage = ({ formik, countryValue, formikLanguageField, selectedLanguage, label = "Select Default Language" }: languageSelector) => {
    const [languageOptions, setLanguageOptions] = useState<Array<{ title: string; option: string; }>>([...initialLanguages, { option: '', title: '' }]);
    const [selectedOption, setSelectedOption] = useState<{ option: any; title: string; } | null>(null);
    useEffect(() => {
        const options = Object.keys(supportedLanguages).map((country) => {
            if (countryValue === country) {
                return supportedLanguages[country].map((lang) => {
                    return {
                        title: 'Market specific languages',
                        option: lang
                    }
                })

            }
            return supportedLanguages[country].map((lang) => {
                return {
                    title: 'All languages',
                    option: lang
                }
            })
        }).flat()

        setLanguageOptions(options)
        setSelectedOption(null)
    }, [countryValue])

    return (
        <FormControl className='language-selector'>
            <Autocomplete
                className='input-text'
                disablePortal
                disabled={(!countryValue || countryValue === '') ? true : false}
                id='languages'
                options={
                    languageOptions.sort((a, b) => {
                        if (a.title === b.title) {
                            return a.option < b.option ? -1 : 1
                        } else {
                            return (a.title < b.title) ? 1 : -1
                        }
                    })
                }
                groupBy={(option) => option.title}
                value= {selectedLanguage ? {option: selectedLanguage, title: ''} : selectedOption}
                getOptionLabel={(option) => `${option.option}` || ''}
                isOptionEqualToValue={(option, value) => option.option === value?.option || value?.option === '' || value === null}
                onChange={(event, value) => { formik.setFieldValue(formikLanguageField, !(value?.option) ? '' : value?.option); value!== null && setSelectedOption(value) }}
                renderInput={params => (
                    <TextFieldWrapper
                        {...params}
                        label={label}
                        variant="outlined"
                        fullWidth
                    />
                )}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={uniqid()}>
                            {option.option}
                        </li>
                    );
                }}
            />
            <FormHelperText error={true}>{getIn(formik.touched, formikLanguageField) && getIn(formik.errors, formikLanguageField)}</FormHelperText>
        </FormControl>
    )
}

export { SelectLanguage }
