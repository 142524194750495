import { useEffect } from 'react';

const useLanguageSetup = (setLanguageIndex, language, formValues, setFormValues) => {
    useEffect(() => {
        const stateValues: string[] = ['name', 'desc', 'short_desc', 'redeem_desc'];
        formValues.name.forEach((value: string, index: number) => {
            if (value.hasOwnProperty(language)) {
                setLanguageIndex(index);
            }
        });
        stateValues.forEach((value: string) => {
            let languages: string[] = formValues[value].flatMap((langKeyValuePair) => {
                return Object.keys(langKeyValuePair);
            });
            if (!languages.includes(language)) {
                setFormValues(`${value}[${languages.length}]`, { [language]: '' });
            }
        });
    }, [setLanguageIndex, language, formValues, setFormValues]);

}

export default useLanguageSetup
