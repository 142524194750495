// libs
import { isEqual as _isEqual } from 'lodash';

// custom
import { StateManager } from './StateManager';
import { CacheManager } from './ConfigManager';

// types
import type {
    GetInstanceProps,
    AdditionalDetailsState,
    Mechanic,
    HandleStateUpdates,
} from '../types/AdditionalDetailsTypes';
import { ConfigType, PrizeDrawConfig } from '../../../../types/configuration';

/**
 * The `AdditionalDetailsHelpers` class provides utility methods for managing the state and configuration of the additional details section of a promotion.
 *
 * This class is a singleton, and provides the following functionality:
 * - Retrieves an instance of the class, creating a new instance if necessary.
 * - Retrieves the initial form state for the additional details section, caching it for future use.
 * - Handles state updates for the `AdditionalDetailsState` type.
 * - Retrieves the updated configuration from the `configManager`.
 * - Discards any unsaved changes to the promotion configuration.
 * - Updates the configuration cache.
 *
 * The class uses the `StateManager` and `CacheManager` classes to manage the state and configuration, respectively.
 */
export class AdditionalDetailsHelpers {
    private stateManager: StateManager;
    private cacheManager: CacheManager;
    private promoFlow: string;
    private promoMechanic: Mechanic;

    private constructor(params: GetInstanceProps) {
        this.cacheManager = CacheManager.getInstance(params.config);
        this.stateManager = StateManager.getInstance(this.cacheManager);

        this.promoFlow = params.promoFlow;
        this.promoMechanic = params.mechanic;
    }

    private static instance: AdditionalDetailsHelpers;

    /**
     * Retrieves an instance of the `AdditionalDetailsHelpers` class, creating a new instance if necessary.
     *
     * If an instance already exists and the configuration parameters have not changed, the existing instance is returned.
     * If an instance already exists but the configuration parameters have changed, a new instance is created and returned.
     *
     * @param params - The configuration parameters for the `AdditionalDetailsHelpers` instance.
     * @returns An instance of the `AdditionalDetailsHelpers` class.
     */
    public static getInstance(params: GetInstanceProps): AdditionalDetailsHelpers {
        if (!AdditionalDetailsHelpers.instance) {
            return (AdditionalDetailsHelpers.instance = new AdditionalDetailsHelpers(params));
        }

        const cachedConfig = AdditionalDetailsHelpers.instance.cacheManager.getConfig('init-config');
        if (!_isEqual(params.config, cachedConfig)) {
            return (AdditionalDetailsHelpers.instance = new AdditionalDetailsHelpers(params));
        }

        return AdditionalDetailsHelpers.instance;
    }

    /**
     * Deletes the singleton instance of `AdditionalDetailsHelpers` and its associated state and cache managers.
     * This method should be called when the `AdditionalDetailsHelpers` class is no longer needed, to free up resources.
     */
    public static deleteInstance() {
        if (!AdditionalDetailsHelpers.instance) return;

        AdditionalDetailsHelpers.instance = null;
        StateManager.deleteInstance();
        CacheManager.deleteInstance();
    }

    /**
     * Retrieves the initial form state for the additional details section of a promotion.
     * If a cached form state exists, it is returned. Otherwise, a new initial form state is created
     * based on the current promotion flow, promotion mechanic, and configuration, and cached for future use.
     *
     * @returns {AdditionalDetailsState} The initial form state for the additional details section.
     */
    getInitialFormState(): AdditionalDetailsState {
        const cachedState = this.cacheManager.getCachedFormState();
        if (cachedState) return cachedState;

        const config = this.cacheManager.getConfig('init-config');
        const initialState = this.cacheManager.getInitialFormState({
            promoFlow: this.promoFlow,
            promoMechanic: this.promoMechanic,
            config,
        });

        this.cacheManager.setFormStateCache(initialState);
        return initialState;
    }

    /**
     * Handles state updates for the AdditionalDetailsState type.
     * @param props - An object containing the necessary properties to update the state.
     * @returns The updated state.
     */
    handleStateUpdates<T extends AdditionalDetailsState>(props: HandleStateUpdates<T>) {
        return this.stateManager.handleStateUpdates(props);
    }

    /**
     * Returns the updated configuration from the `configManager`.
     * @returns The updated configuration.
     */
    getUpdatedConfig(): ConfigType | PrizeDrawConfig {
        return this.cacheManager.getUpdatedConfig();
    }

    /**
     * Discards any unsaved changes to the promotion configuration.
     */
    discardChanges() {
        this.cacheManager.discardChanges();
    }

    /**
     * Updates the configuration cache.
     */
    syncConfigUpdates() {
        this.cacheManager.updateConfigCache();
    }
}
