import { Grid, Typography } from "@mui/material";

const TitleComponent = ({
    titleText,
    subTitleText,
}: {
    titleText: string;
    subTitleText?: string;
}) => {
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={6}>
                <Typography variant="h4">{titleText}</Typography>
            </Grid>

            {subTitleText && (
                <Grid item xs={6}>
                    {subTitleText}
                </Grid>
            )}
        </Grid>
    );
};

export default TitleComponent;
