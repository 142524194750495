import { Box, Button, Typography } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { useFormikContext, getIn } from 'formik';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { InputComponent } from '../../components/inputComponents/InputComponent';
import { RadioButton } from '../../components/inputComponents/RadioButton';
import { Link } from 'react-router-dom';
import { TooltipText } from '../../components/inputComponents/TooltipText';
import { BaseSyntheticEvent } from 'react';
import { allocationRulesFormProps } from '../../types/componentTypes/allocationRules';
import { SaveRule } from '../../components/addAllocationRulesComponent/SaveRulePage';
import { SelectOption } from '../../components/selectComponents/SelectOption';
import { CurrencyPaths } from '../../constants/currency-constants';

const stepFields: { [key: number]: string[] } = {
    0: ['program_id', 'lot_id', 'campaign_id'],
    1: ['currency_id', 'amount', 'validity'],
};
function AddAllocationRulesForm({
    updateStep,
    step,
    setShowAllocationRulePage,
    setReset,
    isEdit,
}: allocationRulesFormProps) {
    const formik: any = useFormikContext();

    const handleBackstep = () => {
        updateStep(-1);
    };

    const validateAndMove = async () => {
        stepFields[step].forEach((fieldName) => {
            formik.setFieldTouched(fieldName, true);
        });
        const validation = await formik.validateForm();
        let result = true;
        stepFields[step].forEach((fieldName) => {
            if (getIn(validation, fieldName)) {
                result = false;
            }
        });
        if (result) {
            updateStep(1);
        }
    };

    const handleOptionChange = (e: BaseSyntheticEvent) => {
        if (e.target.value === 'lotId' && formik.values.campaign_id !== null) {
            formik.setFieldValue('campaign_id', null);
            formik.setFieldTouched('campaign_id', false);
        } else if (e.target.value === 'campaignId' && formik.values.lot_id !== null) {
            formik.setFieldValue('lot_id', null);
            formik.setFieldTouched('lot_id', false);
        } else if (e.target.value === 'all') {
            formik.setFieldValue('campaign_id', null);
            formik.setFieldValue('lot_id', null);

            formik.setFieldTouched('lot_id', false);
            formik.setFieldTouched('campaign_id', false);
        }
    };

    const onAddNewRuleClick = () => {
        formik.resetForm();
        setReset(undefined);
        updateStep(-2);
    };

    const adjustCodeTypeValues = () => {
        if (formik.values.tempParams.ruleFor === 'all') {
            formik.setFieldValue('lot_id', '*');
        }
    };

    const handleSubmitChanges = () => {
        adjustCodeTypeValues();
        formik.submitForm();
    };

    return (
        <>
            {step === 0 && (
                <Box className='step-box-content'>
                    <InputComponent
                        formik={formik}
                        inputValue={getIn(formik.values, 'program_id')}
                        formikLabel={'program_id'}
                        title='Program ID'
                        titleVariant='body1'
                        label=''
                        disabled={isEdit}
                    />
                    <Box className='rules-radio-group'>
                        <Typography variant='body1' gutterBottom>
                            Code Type
                        </Typography>
                        <RadioButton
                            formik={formik}
                            values={[
                                {
                                    value: 'lotId',
                                    label: 'Lot ID',
                                    useComponent: true,
                                    component: (
                                        <InputComponent
                                            formik={formik}
                                            inputValue={getIn(formik.values, 'lot_id')}
                                            formikLabel={'lot_id'}
                                            disabled={isEdit}
                                        />
                                    ),
                                },
                                {
                                    value: 'campaignId',
                                    label: 'Campaign ID',
                                    useComponent: true,
                                    component: (
                                        <InputComponent
                                            formik={formik}
                                            inputValue={getIn(formik.values, 'campaign_id')}
                                            formikLabel={'campaign_id'}
                                            disabled={isEdit}
                                        />
                                    ),
                                },
                                { value: 'all', label: 'All Lots and Campaigns under the Program' },
                            ]}
                            radioGroupName={'tempParams.ruleFor'}
                            customAction={handleOptionChange}
                            disabled={isEdit}
                        />
                    </Box>
                </Box>
            )}
            {step === 1 && (
                <Box className='step-box-content'>
                    <SelectOption
                        formik={formik}
                        formikValue={'currency_id'}
                        title='Currency ID'
                        titleVariant='body1'
                        optionsList={formik.values.tempParams.marketCurrencies}
                        disabled={isEdit}
                    />
                    {formik.values.tempParams.marketCurrencies.length === 0 && (
                        <Typography variant='body1' className='functionality-items'>
                            Can't find the right currency?&nbsp;
                            <MuiLink href={CurrencyPaths.CreateCurrency}>Create a new currency</MuiLink>
                        </Typography>
                    )}
                    <InputComponent
                        formik={formik}
                        inputValue={getIn(formik.values, 'amount')}
                        formikLabel={'amount'}
                        title='Amount'
                        titleVariant='body1'
                        label=''
                    />
                    <TooltipText
                        text='Validity (Optional)'
                        textVariant='body1'
                        tooltip='Period in days during which the allocated currency amount is valid'
                        styleName='validity-tooltip-text'
                    />
                    <InputComponent
                        formik={formik}
                        inputValue={getIn(formik.values, 'validity')}
                        formikLabel={'validity'}
                        label=''
                    />
                </Box>
            )}
            {step === 2 && (
                <SaveRule
                    formik={formik}
                    onAddNewRuleClick={onAddNewRuleClick}
                    onGoBackClick={setShowAllocationRulePage}
                />
            )}
            <Box className='buttons' sx={{ mb: 5 }}>
                {step <= 0 && (
                    <Button
                        component={Link}
                        to={`/editPromotion/${formik.values.configuration_id}`}
                        state={{ tab: 'allocationRules' }}
                        onClick={() => {
                            setShowAllocationRulePage({ showScreen: false });
                        }}>
                        Cancel
                    </Button>
                )}
                {step > 0 && step <= 1 && (
                    <Button
                        color='secondary'
                        variant='contained'
                        startIcon={<ArrowBackIosIcon />}
                        onClick={handleBackstep}>
                        Previous
                    </Button>
                )}
                {step === 1 && (
                    <Button
                        variant='contained'
                        onClick={() => {
                            handleSubmitChanges();
                        }}>
                        Add
                    </Button>
                )}
                {step < 1 && (
                    <Button
                        variant='contained'
                        name='Next'
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={() => validateAndMove()}>
                        Next
                    </Button>
                )}
            </Box>
        </>
    );
}

export { AddAllocationRulesForm };
