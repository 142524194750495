import { Grid, Link } from '@mui/material'
import { getIn } from 'formik';
import '../../pages/AssignPromotion/AssignPromotionPage.css';
import './GridItems.css';
interface GridRowProps {
    formik: any,
    component: JSX.Element,
    index: number,
    addAction: any,
    removeAction: any,
    formikValueArray: string,
    customParam?: any,
    itemClass?: string,
    containerClass?: string,
    maxFields?: number
    testId?: string
}

function GridItems({ formik, component, index, addAction, removeAction, formikValueArray, customParam, itemClass, maxFields, testId }: GridRowProps) {
    return (
        <Grid container spacing={1} className='gridMainContainer' data-testid={testId || null}>
            <Grid item xs={9} className={itemClass}>
                <> {component} </>
            </Grid>
            <Grid item xs={3} className='gridSubContainer'>
                <Grid item>
                    {index !== 0 && <Link className='row-link' onClick={() => removeAction(customParam = { ...customParam, index })} >-Remove</Link>}
                </Grid>

                {(!maxFields || maxFields > getIn(formik.values, formikValueArray)?.length ) &&
                    <Grid item >
                        {index === getIn(formik.values, formikValueArray)?.length - 1 && <Link className='row-link' onClick={() => addAction(customParam = { ...customParam, index })} >+Add New</Link>}
                    </Grid>
                }

            </Grid>
        </Grid>
    )
}

export { GridItems }
