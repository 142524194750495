import * as Yup from 'yup';

const generateWinningMomentsValidationSchema = Yup.object().shape({
    showWinningMomentExpiration: Yup.boolean(),
    winningMomentExpiration: Yup.number()
        .when('showWinningMomentExpiration', {
            is: true,
            then: Yup.number()
                .typeError('Value must be a number')
                .positive("Value must be a positive number!")
                .integer("Value must be a number!")
        })
});

export { generateWinningMomentsValidationSchema }
