import { Storage, API } from 'aws-amplify';
import { getPrizesForConfig } from '../../../graphql/queries';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect, useState, useRef } from 'react';
import moment from 'moment-timezone';
import { flowLabelMap } from '../../../constants/lists';
import {
  configurationData,
  configWithPrizes,
  AssignedPromosCustomHookType,
  listAssignedPromosPropsType
} from '../../../types/componentTypes/assignedPromotions';
import { ConfigType } from '../../../types/configuration';
import { GetPrizesTableResult } from '../../../types/componentTypes/PrizeTypes';

Storage.configure({
  customPrefix: {
    public: '',
    protected: '',
    private: '',
  },
});


const useIsInitialQuery = () => {
  const initialQuery = useRef(true);

  useEffect(() => {
    initialQuery.current = false;
  }, []);

  return initialQuery.current;
}

export const useGetPrizesInfo = ({ listAssignedPromosProps, setNotificationState }: AssignedPromosCustomHookType) => {
  const initialQuery = useIsInitialQuery();

  const [configPrizes, setConfigPrizes] = useState<configWithPrizes[]>([
    { configurationId: '', prizesArray: [] },
  ]);

  const [prizeLoading, setPrizeLoading] = useState(true);

  useEffect(() => {
    const getPrizes = async (configurationInput: string) => {
      const queryResult = await API.graphql({
        query: getPrizesForConfig,
        variables: { configuration_id: configurationInput },
      }) as GraphQLResult<GetPrizesTableResult>;
      return queryResult?.data?.getPrizesForConfig?.items;
    };

    const getPrizesForConfiguration = async (configurationList: string[]) => {
      const configPrizesObjectArray: configWithPrizes[] = [];
      for (const configuration of configurationList) {
        try {
          getPrizes(configuration).then((result) => {
            const configPrizeObject = {
              configurationId: configuration,
              prizesArray: result,
            };
            configPrizesObjectArray.push(configPrizeObject);
          });
        } catch (e) {
          setNotificationState({
            open: true,
            title: 'Loading prizes request failed!',
            content: '',
            level: 'error',
          });
          console.error('Loading prizes failed with: ', e);
        }
      }
      setConfigPrizes(configPrizesObjectArray);
    };


    if (
      listAssignedPromosProps.data.configurations &&
      listAssignedPromosProps.data.configurations.length > 0 &&
      initialQuery
    ) {
      getPrizesForConfiguration(listAssignedPromosProps.data.configurations).then(() => {
        setPrizeLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAssignedPromosProps, initialQuery])

  return {
    configPrizes, prizeLoading
  }
}


export const useAssignedPromotionsList = ({ listAssignedPromosProps, setNotificationState }: AssignedPromosCustomHookType) => {
  const initialQuery = useIsInitialQuery();
  const [loading, setLoading] = useState(true);
  const [s3PromotionsList, sets3PromotionsList] = useState<
    configurationData[]
  >([]);

  useEffect(() => {
    if (
      listAssignedPromosProps.data.configurations &&
      listAssignedPromosProps.data.configurations.length > 0 &&
      initialQuery
    ) {

      getS3configurations(listAssignedPromosProps.data.configurations).then((data) => {
        sets3PromotionsList(data);
        setLoading(false);
      })
    }
    if (!listAssignedPromosProps.data.configurations || !listAssignedPromosProps.data.configurations.length) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAssignedPromosProps.data.configurations, listAssignedPromosProps.input, initialQuery]);

  const retrieveConfigData = async (configuration: string): Promise<ConfigType> => {
    try {
      const configResult: any = await Storage.get(
        `${configuration}/conf.txt`,
        { download: true, cacheControl: 'no-cache' }
      );
      const result = await configResult.Body.text();
      return JSON.parse(result);
    } catch (err) {
      console.error('Error getting confiugration data', err);
      setNotificationState({
        open: true,
        title: 'Error',
        content: `Config id ${configuration} not found in s3!`,
        level: 'error',
      });
      setLoading(false);
    }
  };

  const getS3configurations = async (configurationList: string[]): Promise<configurationData[]> => {
    const configurationData: configurationData[] = [];
    for (const configuration of configurationList) {
      const parseConfiguration = await retrieveConfigData(configuration);
      const mechanic =
        Object.keys(flowLabelMap)[
        Object.values(flowLabelMap).reduce(
          (accumulator, currentValue, index) => {
            if (JSON.stringify(parseConfiguration).includes(currentValue)) {
              return accumulator + index;
            }
            return accumulator;
          },
          0
        )
        ];
      const configName =
        parseConfiguration?.configurationParameters?.additionalInformation
          ?.name;
      let startTime: string;
      let endTime: string;
      if (parseConfiguration.configurationParameters.configurationStartUtc) {
        const dateTime = moment(
          parseConfiguration.configurationParameters.configurationStartUtc
        ).format('DD.MM.YYYY');
        startTime = dateTime;
      }
      if (parseConfiguration.configurationParameters.configurationEndUtc) {
        const dateTime = moment(
          parseConfiguration.configurationParameters.configurationEndUtc
        ).format('DD.MM.YYYY');
        endTime = dateTime;
      }

      const configurationMetadata = {
        promotionName: configName
          ? parseConfiguration.configurationParameters.additionalInformation
            .name
          : 'Not available',
        promotionConfigurationId: parseConfiguration.configurationId,
        market: parseConfiguration.configurationParameters.country
          ? parseConfiguration.configurationParameters.country
          : 'Not available',
        startDate: parseConfiguration.configurationParameters
          .configurationStartUtc
          ? startTime
          : 'Not available',
        endDate: parseConfiguration.configurationParameters
          .configurationEndUtc
          ? endTime
          : 'Not available',
        mechanic: mechanic,
      };
      configurationData.push(configurationMetadata);
    }
    return configurationData;
  };

  return {
    loading, s3PromotionsList
  }

}

export const useFilteredList = (s3PromotionsList: configurationData[], listAssignedPromosProps: listAssignedPromosPropsType) => {
  const [assignedPromotionsList, setAssignedPromotionsList] = useState<configurationData[]>([]);

  useEffect(() => {
    const filteredData = s3PromotionsList.filter((data: any) => {
      const includesData = listAssignedPromosProps.input.toLowerCase();
      if (listAssignedPromosProps.input === '') {
        return data;
      } else if (
        (data.promotionName || '').toLowerCase().includes(includesData) ||
        (data.promotionConfigurationId || '')
          .toLowerCase()
          .includes(includesData) ||
        (data.market || '').toLowerCase().includes(includesData) ||
        (data.startDate || '').toLowerCase().includes(includesData) ||
        (data.endDate || '').toLowerCase().includes(includesData) ||
        (data.mechanic || '').toLowerCase().includes(includesData)
      ) {
        return data;
      }
      return false;
    });

    setAssignedPromotionsList(filteredData);

  }, [s3PromotionsList, listAssignedPromosProps.input])

  return assignedPromotionsList
}

export const usePaginatedData = (assignedPromotionsList: configurationData[], rowsPerPage: number) => {
  const [paginatedPromoList, setPaginatedPromoList] = useState<configurationData[][]>([[]]);
  useEffect(() => {
    const chunkDataForPages = (
      inputArray: configurationData[],
      lengthOfChunk: number
    ) => {
      const chunks = [];
      for (let i = 0; i < inputArray.length;) {
        chunks.push(inputArray.slice(i, (i += lengthOfChunk)));
      }
      return chunks;
    };

    setPaginatedPromoList(
      chunkDataForPages(assignedPromotionsList, rowsPerPage)
    );
  }, [assignedPromotionsList, rowsPerPage]);

  return paginatedPromoList
}