import Button from '@mui/material/Button';
import { Container, Box, InputAdornment, Typography, Breadcrumbs, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SearchRounded from '@mui/icons-material/SearchRounded';
import { NavBar } from '../../common/NavBar/NavBar';
import { StyledEngineProvider } from '@mui/material/styles';
import { Campaigns } from '../../components/listCampaignsComponent/Campaigns';
import { useState } from 'react';
import './ListCampaigns.css';
import {
    getAllCampaignsData,
    getDraftCampaignsData,
    getActiveCampaignsData,
    getArchivedCampaignsData,
} from './services/api-calls';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

export default function ListCampaignsPage() {
    let timer: any;
    let tabLabels = ['ALL CAMPAIGNS', 'DRAFT', 'ACTIVE', 'ARCHIVED'];
    let dataFetchFunctions = [
        getAllCampaignsData,
        getDraftCampaignsData,
        getActiveCampaignsData,
        getArchivedCampaignsData,
    ];
    const [searchBarValue, setSearchBarValue] = useState('');
    let componentsMap = dataFetchFunctions.map((dataFetchFunction) => (
        <Campaigns searchBarValue={searchBarValue} dataFetchFunction={dataFetchFunction} />
    ));
    const handleSearchBarInput = (event: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSearchBarValue(event.target.value);
        }, 450);
    };

    return (
        <StyledEngineProvider injectFirst>
            <Container maxWidth='lg' margin-top='120' background-color='white'>
                <Box sx={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-around' }}>
                    <div role='presentation'>
                        <Breadcrumbs className='breadCrumbsStyles' aria-label='breadcrumb'>
                            <Link underline='hover' fontSize='15px' color='inherit' href='/listCampaigns'>
                                Campaigns
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <Typography className='campaignsBigLabelStyles'>Campaigns</Typography>
                    <Button
                        component={RouterLink}
                        to='/createCampaign'
                        variant='contained'
                        className='createNewCampaignButtonStyles'>
                        Create New Campaign
                    </Button>
                    <TextFieldWrapper
                        id='search-campaigns-outlined-basic'
                        helperText='Search for Campaigns'
                        label='Search by name or ID'
                        variant='filled'
                        size={'small'}
                        className='searchBarStyles'
                        InputProps={{
                            style: {
                                backgroundColor: 'white',
                            },
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(event) => {
                            handleSearchBarInput(event);
                        }}
                    />
                </Box>
                <NavBar tabLabels={tabLabels} components={componentsMap} />
            </Container>
        </StyledEngineProvider>
    );
}
export { ListCampaignsPage };
