import {
  CurrencyTableItem,
} from "../../../types/componentTypes/CurrencyTypes";
import uniqid from "uniqid";
import { LIST_CURR_ITEMS_PER_PAGE_LIMIT } from "../../../constants/currency-constants";

export const makePaginatedData = (
  filteredCurrencies: CurrencyTableItem[]
) => {
  const dataToProcess = [...filteredCurrencies];
  const chunkedData: { [key: string]: CurrencyTableItem[] } = {};

  let page = 1;
  while (dataToProcess.length) {
    const pageNumber = page.toString();
    chunkedData[pageNumber] = dataToProcess.splice(
      0,
      LIST_CURR_ITEMS_PER_PAGE_LIMIT
    );

    if (dataToProcess.length) {
      page++;
    }
  }

  return { pages: page.toString(), chunkedData };
};

export const filterItemsBySearchValue = (
  searchValue: string,
  itemsToSearch: CurrencyTableItem[]
) => {
  return itemsToSearch.filter(
    (item) =>
      item.name.toLowerCase().includes(searchValue) ||
      item.currency_id.toLowerCase().includes(searchValue)
  );
};

export const filterItemsByMarket = (
  market: string,
  itemsToSearch: CurrencyTableItem[]
) => {
  if (market === "All") {
    return itemsToSearch;
  }

  return itemsToSearch.filter((currency) => currency.country === market);
};

export const filterCurrencyItems = ({
  currencies,
  searchBarValue,
  selectedMarket,
}: {
  currencies: CurrencyTableItem[];
  searchBarValue?: string;
  selectedMarket?: string;
}) => {
  if (!searchBarValue && !selectedMarket) return makePaginatedData(currencies);

  if (searchBarValue && selectedMarket) {
    const matchedSearchBarItems = filterItemsBySearchValue(
      searchBarValue,
      currencies
    );
    const matchedMarketItems = filterItemsByMarket(
      selectedMarket,
      matchedSearchBarItems
    );

    return makePaginatedData(matchedMarketItems);
  }

  if (searchBarValue) {
    const matchedSearchBarItems = filterItemsBySearchValue(
      searchBarValue,
      currencies
    );
    return makePaginatedData(matchedSearchBarItems);
  }

  if (selectedMarket) {
    const matchedMarketItems = filterItemsByMarket(selectedMarket, currencies);

    return makePaginatedData(matchedMarketItems);
  }

  return { pages: "0", chunkedData: {} };
};

export const removeFalsyValuesFromCurrency = (currency: CurrencyTableItem) =>
  Object.keys(currency).reduce((acc, currKey) => {
    const value = currency[currKey as keyof CurrencyTableItem];
    if (!value) return acc;

    return { ...acc, [currKey]: value };
  }, {} as CurrencyTableItem);

export const isCurrencyOlderThanSixHours = (currencyCreatedDate: string) => {
  const sixHoursInMs = 21600000;
  const nowAsMs = Date.now();
  const currencyDateAsMsPlusSixHr =
    new Date(currencyCreatedDate).getTime() + sixHoursInMs;

  return currencyDateAsMsPlusSixHr < nowAsMs;
};

export const extractFileName = (
  includeFilePath = true,
  fileUrl?: string | null
) => {
  if (!fileUrl) return "";

  if (!includeFilePath) {
    const values = fileUrl.split("/");
    return values[values.length - 1];
  }

  const [, , , ...rest] = fileUrl.split("/");
  return rest.join("/");
};

export const makeUniqID = () => uniqid.time();

export type GQLError = {
  errors: [{ message: string }];
};

export const getErrorMessageFromError = (error: unknown) => {
  if (typeof error === "object" && error !== null && "errors" in error) {
    const err = error as GQLError;
    return err.errors[0].message;
  }

  if (error instanceof Error) return error.message;

  return "error could not be determined";
};
