import { FormControl, Grid, Typography, Tooltip, Icon, IconButton } from '@mui/material';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUpload } from '../../components/inputComponents/FileUpload/FileUpload.component';
import { AllowedTextAlignments } from '../../constants/currency-constants';
import { FileUploadWrapperProps } from '../../types/componentTypes/CurrencyTypes';
import TextFieldWrapper from '../TextFieldWrapper/TextFieldWrapper';

const FileUploadWrapper = ({ props }: FileUploadWrapperProps) => {
    const {
        currencyId,
        fileName,
        fileDeleteButtonDisabled,
        handleDeleteIconFromS3,
        showFileUpload,
        setFileLocation,
        setPageHasBeenModified,
        setNotificationState
    } = props;

    return (
        <FormControl sx={{ m: 1, width: 400 }}>
            <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                <Grid item>
                    <Typography>Currency Icon (Optional)</Typography>
                </Grid>

                <Grid item>
                    <Tooltip
                        placement={AllowedTextAlignments.Right}
                        sx={{ marginTop: '-2px' }}
                        title='Icon resolution: 16x16px. Allowed formats: PNG, SVG'>
                        <Icon className='icon-style' fontSize='large'>
                            <HelpOutlineIcon />
                        </Icon>
                    </Tooltip>
                </Grid>

                {!showFileUpload && (
                    <>
                        <Grid item xs={11}>
                            <TextFieldWrapper
                                className='file-input-field'
                                disabled
                                sx={{ background: 'white' }}
                                fullWidth
                                id='currency-input-name'
                                variant='outlined'
                                value={fileName}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                disabled={fileDeleteButtonDisabled}
                                size='medium'
                                onClick={handleDeleteIconFromS3}>
                                <DeleteIcon className='delete-icon' />
                            </IconButton>
                        </Grid>
                    </>
                )}

                {showFileUpload && (
                    <FileUpload
                        isEdit={true}
                        uploadCallback={(files: Array<string>) => {
                            setFileLocation(files[0]);
                            setPageHasBeenModified(true);
                        }}
                        maxFiles={1}
                        placeholder={'No file chosen'}
                        bucket='public'
                        filePath={`icons/${currencyId}`}
                        usePublicDomain={true}
                        setNotificationState={setNotificationState}
                    />
                )}
            </Grid>
        </FormControl>
    );
};

export default FileUploadWrapper;
