import { Storage, API } from 'aws-amplify';
import { getPromotionsTable } from '../../graphql/queries';
import {
    updatePromotionsTable,
    createCurrencyAllocationRulesTable,
    updateCurrencyCurrencyTable,
} from '../../graphql/mutations';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment-timezone';
import { Box, Typography } from '@mui/material';
import { Notification } from '../../common/Notification/Notification';
import { assignPromotionValidationSchema } from '../AssignPromotion/AssignPromotionValidationSchema';
import { AssignPromotionForm } from './AssignPromotionForm';
import { NotFoundPage } from '../NotFound/NotFoundPage';
import { ICreatePromotionState, ITempParams, campaignData } from '../../types/componentTypes/assignPromo';
import { CurrencyTableItem } from '../../types/componentTypes/CurrencyTypes';
import { removeFalsyValuesFromCurrency } from '../Currencies/helpers/currency-helpers';
import { newCurrencyAssignment } from '../EditPromotion/CurrenciesTab/services/api-calls';
import { defaultNotifState } from '../../constants/currency-constants';
import { getCurrenciesData } from '../../utils/currenciesUtils';

import './AssignPromotionPage.css';
import '../../App.css';

Storage.configure({
    customPrefix: {
        public: '',
        protected: '',
        private: '',
    },
});

const startDateTimestamp = moment().add(5, 'minutes');
const validationSchema = assignPromotionValidationSchema();

const initialValues: ICreatePromotionState = {
    promotionId: '',
    tempParams: {
        codeType: [''],
        currencyNames: [],
        flowOption: '',
        useCurrency: false,
        availableCurrencies: [{ country: '', name: '', currency_id: '' }],
        burnPincodes: false,
        pincodeOriginValitidy: false,
        listPrizes: false,
        redeemPrize: false,
        addTransaction: false,
        queryVouchers: false,
        redeemVoucher: false,
        queryWallet: false,
        autoRedeemCnG: false,
        currencyValidity: false,
        addRules: false,
        addCaptcha: false,
        validityDays: 1,
        startDateTime: moment().format('YYYY-MM-DD HH:mm'),
        endDateTime: moment().add(14, 'days').format('YYYY-MM-DD HH:mm'),
        limits: {
            minAge: false,
            calendarDay: false,
            rolling: false,
            lifetime: false,
            useRollingCustomDuration: false,
        },
    },
    configurationParameters: {
        language: '',
        country: '',
        currencies: [],
        configurationStartUtc: moment().format('YYYY-MM-DD HH:mm'),
        configurationEndUtc: moment().add(14, 'days').format('YYYY-MM-DD HH:mm'),
        configurationDatesTimezone: '',
        configurationSubMechanic: '',
        startEndDateCheck: true,
        validity: {},
        additionalInformation: { name: '' },
        captchaSecret: '',
        userIdType: 'cds'
    },
    configurationId: '',
    flow: '',
    params: {
        algorithm: 'winningMoments',
        alwaysWin: '',
        reduceAmount: [{ currencyId: '', amount: '' }],
        lotIds: [''],
        campaignIds: [''],
        imageEntry: false
    },
    secrets: {
        mixCodesParameters: [{ programId: '', secret: '', uri: '' }],
    },
    checkerLambdas: [],
    flowLambdas: [],
    participationLimits: {
        participationLimit: 1,
        participationLimitTime: 1,
        participationLimitTimeZone: '',
        participationLimitCalendarDatesRange: {
            startDate: parseInt(moment().format('x')),
            endDate: parseInt(moment().add(1, 'days').format('x')),
        },
        participationLimitMultipleChecks: false,
        participationLifetimeLimit: 1,
        participationLimitStartEndDatesRange: {
            startDate: parseInt(moment().format('x')),
            endDate: parseInt(moment().add(1, 'days').format('x')),
        },
        participationLimitCalendarDatesLimit: 1,
        participationLimitCalendarDatesTimeZone: '',
        minAge: 12,
    },
    allocationRules: [
        {
            programId: '',
            lotId: '',
            currencyId: '',
            currencyName: '',
            amount: null,
            validityCheck: false,
            validity: null,
        },
    ],
    additionalDetails: {},
    priorityOrder: 'ASC',
    maxParticipationIds: 10,
    useStatusReserved: false
};

const initialCampaign: campaignData = {
    configurations: [],
    promotion_id: '',
    promotion_name: '',
    promotion_author: '',
    promotion_owner: '',
    digital_experience: [],
    promotion_market: [],
    promotion_brand: [],
    promotion_start_utc: new Date(),
    promotion_end_utc: new Date(),
    last_modified: undefined,
};

export type IUpdateCurrenciesWithPromotionData = {
    campId: string;
    promoId: string;
    availableCurrencies: CurrencyTableItem[];
    selectedCurrencyIds: string[];
};

export const updateCurrenciesWithPromotionData = async ({
    campId,
    promoId,
    selectedCurrencyIds,
    availableCurrencies,
}: IUpdateCurrenciesWithPromotionData) => {
    if (!selectedCurrencyIds) return;

    return availableCurrencies.reduce(async (acc: Promise<{}>, currValue: CurrencyTableItem) => {
        const { currency_id, currency_assignment } = currValue;

        if (!selectedCurrencyIds.includes(currency_id)) return acc;

        if (!currency_assignment) {
            await newCurrencyAssignment({ promoId, campId, currValue });
            return acc;
        }

        const parsedCurrAssignment: Record<string, string[]> = JSON.parse(currency_assignment);
        const itemsForUpdating = (parsedCurrAssignment && parsedCurrAssignment[campId]) ? [...parsedCurrAssignment[campId]] : [];

        const currIndex = selectedCurrencyIds.indexOf(currency_id);
        if (!itemsForUpdating.includes(selectedCurrencyIds[currIndex])) itemsForUpdating.push(promoId);

        const updatedCurrency = {
            ...removeFalsyValuesFromCurrency(currValue),
            currency_assignment: JSON.stringify({
                ...parsedCurrAssignment,
                [campId]: itemsForUpdating,
            }),
        };

        await API.graphql({ query: updateCurrencyCurrencyTable, variables: { input: updatedCurrency } });

        return acc;
    }, Promise.resolve({}));
};

export const ensureOrderOfFlowLambdas = (inputFlowLambdas: string[]) => {
    const orderOfLambdas = [ "burnPincodes", "currencyReducer","promoEntryLambda","instantWin", "pincodeToCurrency","transactionLambda", "autoRedeemPrizeLambda"]
    let newFlowLambdas: string[] = []
    inputFlowLambdas.forEach(value => {
        newFlowLambdas[orderOfLambdas.indexOf(value)] = value
    })
    return newFlowLambdas.filter(value => value)
}

function AssignPromotionPage() {
    const { promotionId } = useParams();
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [initialState, setInitialState] = useState(initialValues);
    const [campaignData, setCampaignData] = useState(initialCampaign);
    const [campaignMarkets, setCampaignMarkets] = useState([]);
    const [createPromoDisabled, setCreatePromoDisabled] = useState(false);
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const updateStep = (num: number) => {
        const newStep = step + num;
        setStep(newStep);
    };

    useEffect(() => {
        let updatedState: ICreatePromotionState;
        const retrieveCampaignData = async () => {
            try {
                const queryResult: any = await API.graphql({
                    query: getPromotionsTable,
                    variables: { promotion_id: promotionId },
                });
                const campaignData = queryResult.data.getPromotionsTable;
                return campaignData;
            } catch (err) {
                console.error('Failed to fetch campaign data:', err);
            }
        };
        if (!promotionId) {
            setDisabled(true);
        } else {
            retrieveCampaignData()
                .then((data) => {
                    const campaignHasStarted: boolean = moment(data.promotion_start_utc) < moment()
                    const campaignIsInactive: boolean = moment(data.promotion_end_utc) < moment() || data.archived
                    if (campaignIsInactive) {
                        setDisabled(true);
                        setCreatePromoDisabled(true)
                        return;
                    }
                    const startDate = (campaignHasStarted ? startDateTimestamp : moment(data.promotion_start_utc)).format('YYYY-MM-DD HH:mm');
                    const endDate = moment(data.promotion_end_utc).format('YYYY-MM-DD HH:mm');

                    updatedState = {
                        ...initialValues,
                        promotionId,
                        tempParams: {
                            ...initialValues.tempParams,
                            startDateTime: startDate,
                            endDateTime: endDate,
                        },
                        configurationParameters: {
                            ...initialValues.configurationParameters,
                            configurationStartUtc: startDate,
                            configurationEndUtc: endDate,
                        },
                        participationLimits: {
                            ...initialValues.participationLimits,
                            participationLimitCalendarDatesRange: {
                                startDate,
                                endDate,
                            },
                            participationLimitStartEndDatesRange: {
                                startDate,
                                endDate,
                            },
                        },
                    };
                    setCampaignData(data);
                    setCampaignMarkets(data.promotion_market);
                })
                .then(() => {
                    getCurrenciesData()
                        .then((data) => {
                            const activeCurrencies = data.filter((item) => item.is_active === null || item.is_active );
                            updatedState = {
                                ...updatedState,
                                tempParams: {
                                    ...updatedState.tempParams,
                                    availableCurrencies: activeCurrencies || [{ country: '', name: '', currency_id: '' }],
                                },
                            };
                            setInitialState(updatedState);
                        })
                        .catch((e) => {
                            console.error('Failed to fetch currencies:', e);
                        });
                })
                .catch((e) => {
                    setNotificationState({
                        open: true,
                        title: 'Campaign not found',
                        content: 'Supplied campaign id does not exist! Please go back to campaigns list.',
                        level: 'error',
                    });
                    console.error('Retrieval of campaign metadata failed with:', e);
                    setDisabled(true);
                });
        }
    }, [promotionId]);

    const clearObject = (values: { [key: string]: any }) => {
        Object.keys(values).forEach((key) => {
            (values[key] && typeof values[key] === 'object' && clearObject(values[key])) ||
                ((values[key] === '' || values[key] === null) && delete values[key]);
        });
        return values;
    };

    const validateFlowLambdas = ({ tempParams, flowLambdas }: { tempParams: ITempParams; flowLambdas: string[] }) => {
        const uniqueFlowLambdas = new Set(flowLambdas);
        const { burnPincodes, flowOption } = tempParams;
        if (!burnPincodes && flowOption !== 'Collect & Get' && uniqueFlowLambdas.has('burnPincodes'))
            uniqueFlowLambdas.delete('burnPincodes');
        return ensureOrderOfFlowLambdas([...uniqueFlowLambdas]);
    };

    const restructureValues = (values: ICreatePromotionState) => {
        const {
            params,
            participationLimits,
            secrets,
            flowLambdas,
            checkerLambdas,
            flow,
            tempParams,
            additionalDetails,
            allocationRules,
            ...rest
        } = values;
        let validityValues;
        if (!values.tempParams.pincodeOriginValitidy) {
            delete params.lotIds;
            delete params.campaignIds;
        }

        if (!values.tempParams.useCurrency && flow !== 'redeemPincodeForCurrencies') {
            delete params.reduceAmount;
            delete rest.configurationParameters.currencies;
        }

        if (values.tempParams.useCurrency && flow === 'promoEntry') {
            delete params.algorithm;
        }

        const temp = {
            flow: {
                [flow]: {
                    ...(((flow === 'instantWin' || flow === 'promoEntry') && {
                        params: { ...clearObject(params), ...clearObject(participationLimits) }

                    }) ||
                        ((flow === 'redeemPincodeForCurrencies' || flow === 'autoRedeemCnG') &&
                            (params?.lotIds || params?.campaignIds) && {
                                params: { lotIds: params?.lotIds, campaignIds: params?.campaignIds },
                            })),
                    ...(values.tempParams.burnPincodes && { secrets }),
                    flowLambdas: validateFlowLambdas({ tempParams, flowLambdas }),
                    ...(checkerLambdas.length > 0 &&
                        { checkerLambdas: [...new Set(checkerLambdas)] }),
                },
                ...additionalDetails,
            },
        };

        if (tempParams.currencyValidity) {
            validityValues = rest.configurationParameters.currencies.reduce((acc: {[key:string]: number}, currency: string) => {
                acc[currency] = tempParams.validityDays;
                return acc;
            }, {});
        } else {
            delete rest.configurationParameters.validity;
        }

        const configData = {
            ...rest,
            configurationParameters: {
                ...rest.configurationParameters,
                configurationStartUtc: tempParams.startDateTime,
                configurationEndUtc: tempParams.endDateTime,
                ...(validityValues && { validity: validityValues }),
            },
            ...temp,
        };
    if (values.useStatusReserved === true) {
        configData.flow.instantWin.params.useStatusReserved = true;
        configData.flow.acceptReservedVoucher.params.maxParticipationIds = values.maxParticipationIds
    }

        delete configData.priorityOrder
        delete configData.maxParticipationIds
        delete configData.useStatusReserved

        return clearObject(configData);
    };

    const concatenateColumnValues = (...column: any) => `${column.join('|')}`;

    const createAllocationRule = async (configurationId: string, rule: { [key: string]: any }, ruleId: string) => {
        const insertParams = {
            amount: rule.amount,
            configuration_id: configurationId,
            currency_id: rule.currencyId,
            lot_id: rule.lotId,
            program_id: rule.programId,
            validity: rule.validity,
            rule_id: ruleId,
            rule_active: true,
        };

        await API.graphql({ query: createCurrencyAllocationRulesTable, variables: { input: insertParams } });
    };

    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            {disabled && <NotFoundPage titleText={createPromoDisabled && 'Promotions can be added only to active campaigns'}/>}
            {!disabled && (
                <Box className='assign-promotion-wrap'>
                    <Typography variant='h4' gutterBottom className='main'>
                        Add promotion
                    </Typography>
                    <Box>
                        <Formik
                            initialValues={initialState}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={async (values, actions) => {
                                try {
                                    if (values.tempParams.addRules) {
                                        for (const rule of values.allocationRules) {
                                            const ruleId = concatenateColumnValues(
                                                rule.programId,
                                                rule.lotId,
                                                rule.currencyId
                                            );
                                            await createAllocationRule(values.configurationId, rule, ruleId);
                                        }
                                    }
                                    const result = restructureValues(values);
                                    const fileName = `${result.configurationId}/conf.txt`;
                                    await Storage.put(fileName, JSON.stringify(result), { contentType: 'text/plain' });
                                    const updatedCampaignData = {
                                        ...campaignData,
                                        configurations:
                                            campaignData.configurations !== null
                                                ? [...campaignData.configurations, result.configurationId]
                                                : [result.configurationId],
                                        last_modified: moment().toDate().getTime(),
                                    };
                                    await API.graphql({
                                        query: updatePromotionsTable,
                                        variables: { input: updatedCampaignData },
                                    });

                                    await updateCurrenciesWithPromotionData({
                                        campId: values.promotionId,
                                        promoId: values.configurationId,
                                        availableCurrencies: values.tempParams.availableCurrencies,
                                        selectedCurrencyIds: result?.configurationParameters?.currencies,
                                    });

                                    updateStep(1);
                                } catch (e) {
                                    console.error(`Save configuration failed with error:${e}`);
                                    actions.resetForm();
                                    setStep(0);
                                }
                            }}>
                            {(formik) => (
                                <form onSubmit={formik.submitForm}>
                                    <AssignPromotionForm
                                        step={step}
                                        updateStep={updateStep}
                                        campaignMarkets={campaignMarkets}
                                    />
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            )}
        </>
    );
}

export { AssignPromotionPage };
