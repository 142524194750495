import * as yup from 'yup';

interface TestContextExtended {
    [key: string]: any;
}

const alphaNumericRegex = /^[a-zA-Z0-9]+$/

function mixcodesValidationSchema() {
    return yup.object({
        secrets: yup.object().shape({
            mixCodesParameters: yup
                .mixed()
                .test(
                    'mixcodesTest',
                    'If functionality is applied, mixcodes details are required',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended;
                        let result = true;
                        value &&
                            value.forEach((element: { programId: string; secret: string; uri: string }) => {
                                if (
                                    (element.programId === undefined ||
                                        element.secret === undefined ||
                                        element.uri === undefined) &&
                                    from[1].value.tempParams.burnPincodes === true
                                ) {
                                    result = false;
                                }
                            });
                        return result;
                    }
                )
                .test('programIdTest', 'Program Id must contain alphanumeric characters with a length of 26', (value, testContext) => {
                    const { from } = testContext as yup.TestContext & TestContextExtended;
                    let result = true;
                    value &&
                        value.forEach((element: { programId: string; secret: string; uri: string }) => {
                            if (
                                from[1].value.tempParams.burnPincodes === true &&
                                element.programId !== undefined &&
                                (!alphaNumericRegex.test(element.programId) || element.programId.length !== 26)
                            ) {
                                result = false;
                            }
                        });
                    return result;
                }),
        }),
    });
}

export { mixcodesValidationSchema };
