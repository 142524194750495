import moment from "moment";
import { AGE_LIMIT_LAMBDA, IW_LIMIT_PARAMS, IW_PRIZE_LIMITS_LAMBDA, limitsStateValues, PARTICIPATION_LIMIT_LAMBDA, PARTICIPATION_LIMIT_PARAMS } from "../../../../types/componentTypes/limits";
import { ConfigType } from "../../../../types/configuration";
import {
    participationLimitsProps,
    limits
} from '../../../../types/componentTypes/limits';

export const getIWLimitsForConfig = (config: limitsStateValues | ConfigType) => {
    const {
        instantWinLimitPerTimeInterval,
        instantWinLimitPerConfiguration,
        instantWinLimitPerTimeIntervalValue,
    } =
        'tempParams' in config ? config.tempParams : config.flow.instantWin?.params;

    return {
        ...(instantWinLimitPerConfiguration
            ? { instantWinLimitPerConfiguration }
            : {}),
        ...(instantWinLimitPerTimeInterval
            ? { instantWinLimitPerTimeInterval }
            : {}),
        ...(instantWinLimitPerTimeIntervalValue
            ? { instantWinLimitPerTimeIntervalValue }
            : {}),
    };
};

export const reducePartLimitValues = (inputObj: participationLimitsProps) => {
    return (Object.keys(inputObj).flatMap(key => {
        if (typeof (inputObj[key]) === 'object') {
            return Object.values(inputObj[key])
        }
        if (typeof (inputObj[key]) === 'number') {
            return inputObj[key]
        }
        return undefined
    })).filter(value => value).reduce(
        (accum, currentValue) => accum + currentValue
    );
}

export const shouldDeleteBlockEntry = (initialObj: participationLimitsProps, updatedObj: participationLimitsProps, initialLimits: limits, updatedLimits: limits) => {
    if (JSON.stringify(Object.values(initialLimits)) !== JSON.stringify(Object.values(updatedLimits))) {
        return true
    }
    return reducePartLimitValues(initialObj) < reducePartLimitValues(updatedObj) ? true : false;
}

export const validateCheckerLambdas = (config: ConfigType | limitsStateValues, flowName: string) => {
    const checkerLambdas = config.flow[flowName]?.checkerLambdas || [];
    const { params } = config.flow[flowName]

    if (!params) return [];

    const {
        instantWinLimitPerTimeIntervalValue,
        instantWinLimitPerTimeInterval,
        winningLimitsPerTier,
    } = params;

    if (winningLimitsPerTier && Object.keys(winningLimitsPerTier).length)
        return [...checkerLambdas, IW_PRIZE_LIMITS_LAMBDA];

    if (instantWinLimitPerTimeIntervalValue || instantWinLimitPerTimeInterval)
        return [...checkerLambdas, IW_PRIZE_LIMITS_LAMBDA];

    return checkerLambdas;
};

export const convertDatesToTimestamp = (values: limitsStateValues, dateFields: Array<string>) => {
    dateFields.forEach((field) => {
        const date =
            values.participationLimits.participationLimitStartEndDatesRange?.[
            field
            ];
        const calendarDate =
            values.participationLimits.participationLimitCalendarDatesRange?.[
            field
            ];
        if (date && !Number.isInteger(date)) {
            values.participationLimits.participationLimitStartEndDatesRange[field] =
                moment(date).valueOf();
        }

        if (calendarDate && !Number.isInteger(calendarDate)) {
            values.participationLimits.participationLimitCalendarDatesRange[field] =
                moment(calendarDate).valueOf();
        }
    });
    return values;
};

export const clearParticipationLimits = (configValues: limitsStateValues, flowName: string) => {
    let values = JSON.parse(JSON.stringify(configValues)) as limitsStateValues;

    const { minAge, calendarDay, rolling, lifetime } = values.tempParams.limits;

    const { instantWinLimitPerConfiguration, instantWinLimitPerTimeInterval } =
        values.tempParams;

    if (!minAge) {
        delete values.participationLimits?.minAge;
        delete values.flow[flowName]?.params?.minAge;
    }

    if (!calendarDay) {
        delete values.participationLimits.participationLimitCalendarDatesRange;
        delete values.participationLimits.participationLimitCalendarDatesLimit;
        delete values.participationLimits.participationLimitCalendarDatesTimeZone;

        delete values.flow[flowName]?.params
            ?.participationLimitCalendarDatesRange;
        delete values.flow[flowName]?.params
            ?.participationLimitCalendarDatesLimit;
        delete values.flow[flowName]?.params
            ?.participationLimitCalendarDatesTimeZone;
    }

    if (!rolling) {
        delete values.participationLimits.participationLimit;
        delete values.participationLimits.participationLimitTime;
        delete values.participationLimits.participationLimitTimeZone;
        delete values.participationLimits.participationLimitStartEndDatesRange;

        delete values.flow[flowName]?.params?.participationLimit;
        delete values.flow[flowName]?.params?.participationLimitTime;
        delete values.flow[flowName]?.params?.participationLimitTimeZone;
        delete values.flow[flowName]?.params
            ?.participationLimitStartEndDatesRange;
    }

    if (!lifetime) {
        delete values.participationLimits.participationLifetimeLimit;
        delete values.flow[flowName]?.params?.participationLifetimeLimit;
    }

    if (
        (lifetime || rolling || calendarDay) &&
        !values.flow[flowName]?.checkerLambdas?.includes('participationLimit')
    ) {
        values.flow[flowName]?.checkerLambdas?.push('participationLimit');
    } else if (
        !(lifetime && rolling && calendarDay) &&
        values.flow[flowName]?.checkerLambdas?.includes('participationLimit')
    ) {
        const checkers = values.flow[flowName]?.checkerLambdas?.filter(
            (value: string) => value !== 'participationLimit'
        );
        values.flow[flowName].checkerLambdas = [...checkers];
    }

    if (!instantWinLimitPerConfiguration) {
        delete values.tempParams.instantWinLimitPerConfiguration;
        delete values.flow[flowName]?.params?.instantWinLimitPerConfiguration;
    }

    if (!instantWinLimitPerTimeInterval) {
        delete values.tempParams.instantWinLimitPerTimeInterval;
        delete values.tempParams.instantWinLimitPerTimeIntervalValue;

        delete values.flow[flowName]?.params?.instantWinLimitPerTimeInterval;
        delete values.flow[flowName]?.params
            ?.instantWinLimitPerTimeIntervalValue;
    }

    if (!instantWinLimitPerConfiguration && !instantWinLimitPerTimeInterval && values.flow[flowName]?.checkerLambdas) {
        values.flow[flowName].checkerLambdas =
            values.flow[flowName]?.checkerLambdas.filter(
                (lambda: string) => lambda !== IW_PRIZE_LIMITS_LAMBDA
            );
    }

    const useDatesLimit = Object.keys(values.tempParams.limits).filter(
        (key) => {
            if (
                key !== 'minAge' &&
                values.tempParams.limits[key]
            ) {
                return true;
            }
            return false;
        }
    ).length;

    if (useDatesLimit || values.flow[flowName]?.params?.participationLimitMultipleChecks) {

        values.participationLimits.participationLimitMultipleChecks = true;
        values.flow[flowName].params.participationLimitMultipleChecks = true;
        values = convertDatesToTimestamp(values, ['startDate', 'endDate']);
    } else {
        delete values.participationLimits.participationLimitMultipleChecks;
        delete values.flow[flowName]?.params?.participationLimitMultipleChecks;
    }

    return values;
};

export const clearAlwaysWinLimits = (configValues: limitsStateValues, flowName: string) => {
    let values = JSON.parse(JSON.stringify(configValues)) as limitsStateValues;

    values.flow[flowName].params.ratioWinning = values.alwaysWinLimits?.ratioWinning;

    // remove winning ratios from the config as they are will be saved in the DB instead
    if (values.alwaysWinLimits.alwaysWinPrizeRatios) {
        delete values.alwaysWinLimits.alwaysWinPrizeRatios;
        delete values.flow[flowName]?.params['alwaysWinPrizeRatios'];
    }

    return values;
}

export const getCheckerLambdasFromTempParams = (updatedLimitsValues: limitsStateValues, checkerLambdasAlreadyPresent: string[]): string[] => {
    const checkerLambdas = new Set<string>(checkerLambdasAlreadyPresent?.includes('pincodeOriginValidityCheckerLambda') ? ["pincodeOriginValidityCheckerLambda"] : []);

    // check temp params
    Object.entries(updatedLimitsValues.tempParams).forEach(([key, value]) => {
        if (key in IW_LIMIT_PARAMS && value) checkerLambdas.add(IW_PRIZE_LIMITS_LAMBDA);
    });

    // check limit params
    Object.entries(updatedLimitsValues.tempParams.limits).forEach(([key, value]) => {
        if (key in PARTICIPATION_LIMIT_PARAMS && value) {
            checkerLambdas.add(
                key === PARTICIPATION_LIMIT_PARAMS.minAge ? AGE_LIMIT_LAMBDA : PARTICIPATION_LIMIT_LAMBDA
            );
        }
    });

    return [...checkerLambdas];
};
